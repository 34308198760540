// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { globalSettings } from 'GlobalSettings';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

// Components and blocks
import PageTitleActivity from 'components/pages/navigation/PageTitleActivity';
import SectionHeadingToolbar from 'components/_common/SectionHeadingToolbar';
import WordBox from 'components/pages/languages/_WordsOverview/WordBox';
import DashboardFooter from 'components/pages/navigation/DashboardFooter';
import TableComponent from 'components/_common/TableComponent';
import LoadingAnimation from 'components/_common/LoadingAnimation';

const default_settings = {
  activityTitle: 'AWS Backend Logging',
  headers: {
    lambdaError: ['functionName', 'username', 'error', 'createdAt'],
    login: ['range', 'userIdentity', 'createdAt'],
    lambdaLog: ['username', 'functionName', 'task', 'age', 'createdAt']
  },
  leftButtons: [
    {
      title: 'Back',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/dashboard?pk=DASHBOARD',
      visibleIfAuthenticated: true,
      visibleIfNotAutheYnticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
    },
  ],
  rightButtons: [
    {
      title: 'Logout',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/logout',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
    }
  ]
}

const allowedKeys = ["lambdaError", "lambdaLog", "login"];


export function Logging({ settings = default_settings }) {

  // Read clipboard
  const { clipboard } = useTopBarCLipboardContext();
  const unitId = clipboard.unitId.value;

  // Read backend
  const [isLoading, setIsLoading] = useState(true);
  const [backendData, setBackendData] = useState({}); // note: dictionary not list
  useEffect(() => {
    const requestData = {
      task: 'maintenance-logging-logging_endpoint',
      range: 'singleRequest',
    };
    const fetchData = async () => {
      const data = await fetchBackendData(requestData);
      if (data) { setBackendData(data); }
    };
    fetchData();
  }, [unitId]);
  useEffect(() => {
    if (Object.keys(backendData).length > 0) {
      setIsLoading(false)
    }
  }, [backendData]);

  // Set-up top bar
  useNavigationConfig(null, null, settings.leftButtons, settings.rightButtons);

  // Use the useLayoutEffect hook to scroll to the top after the component is rendered
  useScrollToTop();
  // useDebugClipboard(clipboard);

  // Responsiveness
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= globalSettings.smallScreenWidth;

  // Control the hovering effect
  const [isHovered, setIsHovered] = useState(false);

  // Styles
  const styles = componentStyles(isSmallScreen, isHovered);

  // Handle settings
  const [userInput, setUserInput] = useState('');

  return (

    <div className="app">

      <PageTitleActivity
        title={default_settings.activityTitle}
        subtitle='Errors, Activity Log, Login' />

      <main>
        <div style={virtualFullWidth}>
          <div style={availableWidth}>

            <SectionHeadingToolbar
              title='Logging'
              setUserInput={setUserInput}
              infoText='infoText'
            />

            {isLoading && <LoadingAnimation />}



            <div style={styles.mainDivStyle}>
              
              {
                backendData['loggedUsersDay'] && backendData['loggedUsersDay'].length > 0 ? (
                  <>
                    <h2>Recent unique 'users~day'</h2>
                    <p>{backendData['loggedUsersDay'].join(', ')}, count: {backendData['loggedUsersDay'].length}</p>
                  </>
                ) : null
              }

              {Object.entries(backendData).map(([key, valueList], index) => {

                // Check if the key is one of the allowed keys
                if (!allowedKeys.includes(key)) return null; // Skip if the key is not allowed

                // Assuming valueList is an array of objects
                if (!valueList.length) return null; // Skip if the list is empty

                // Aliases
                // const headers = Object.keys(valueList[0]); // shows all columns
                const headers = default_settings.headers[key];

                return (
                  <div key={index}>
                    <h2>{key}</h2> {/* Optional: Displaying the key as a section title */}
                    <TableComponent headers={headers} rows={valueList} />
                  </div>
                );

              })}

            </div>

          </div>
        </div>
      </main>

      <DashboardFooter />

    </div >
  );
}