import { shuffleArray } from 'components/legacy/_common/shuffleArray'


export async function pickQuestion(
  allQuestionsBuffer,
  setCorrectAnswer,
  setShuffledAnswers,
  setExplanation,
  setQuestion,
  setQuestionId,
  setTranslation,
  setIsLoading,
  setStartTime,
) {

  // Block other components while querying...
  setIsLoading(true)

  // Randomize list
  const randomIndex = Math.floor(Math.random() * allQuestionsBuffer.length)
  const selectedQuestion = allQuestionsBuffer[randomIndex]
  const answers = shuffleArray(
    selectedQuestion.correctAnswer,
    selectedQuestion.wrongAnswers,
    4 // number of options in the multiple choice question
  )

  // console.log('selectedQuestion:', selectedQuestion)
  // console.log('answers:', answers)

  // Make the answers available to render
  setShuffledAnswers(answers)
  setCorrectAnswer(selectedQuestion.correctAnswer)
  setQuestion(selectedQuestion)
  setExplanation(selectedQuestion.explanation)
  setTranslation(selectedQuestion.question_cz)
  setQuestionId(selectedQuestion.id + '~' + selectedQuestion.range)

  // Unblock other components
  setIsLoading(false)

  // Set the start time
  setStartTime(Date.now())
}
