import '@aws-amplify/ui-react/styles.css'
import 'components/styles/App.css'
import 'components/styles/fonts/fonts.css'

import { routes } from 'routes/routesConfig'

import { Authenticator } from '@aws-amplify/ui-react'
import { RequireAuth } from './RequireAuth'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { TopBarCLipboardProvider } from 'components/pages/navigation/TopBarCLipboardContext';
import { Layout } from 'components/pages/navigation/TopBarLayout'

// ErrorBoundary imports
import React from 'react';
import ErrorBoundary from 'components/pages/navigation/ErrorBoundary';

// Reads 'routes/routesConfig' and declares routes
function ApplicationRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          {routes.map(({ path, component: Component, public: isPublic }) => (
            <Route
              key={path}
              path={path}
              element={
                isPublic ? (
                  <Component />
                ) : (
                  <RequireAuth>
                    <Component />
                  </RequireAuth>
                )
              }
            />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

// Standard App.js
function App() {
  return (
    <div className="disable-text-selection">
      <Authenticator.Provider>

        <TopBarCLipboardProvider>
          <ErrorBoundary>

            <ApplicationRoutes />

          </ErrorBoundary>
        </TopBarCLipboardProvider>

      </Authenticator.Provider>
    </div>
  );
}

export default App
