import React, { useState } from 'react';

import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

import useAudioPlayer from 'components/_common/AudioPlayer';

const icon_down = require('img/icons/symbols/black/icon_chevron_S_double_down.png');
const icon_up = require('img/icons/symbols/black/icon_chevron_S_double_up.png');

const SnackBarSingleFormatted = (props) => {
    const { title, definition, synonyms, sentence, sentences, color } = props;

    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Set default color to lightgray if not specified
    const containerStyle = {
        backgroundColor: color || 'lightgreen',
        margin: 10,
        padding: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20,
    };

    const iconStyle = {
        margin: 10,
        height: 24,
        width: 24,
        cursor: 'pointer',
    };

    const contentStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 60,
    };

    const titleStyle = {
        fontSize: '120%',
        fontWeight: 700,
        textAlign: 'left',
        marginLeft: 5,
        marginRight: 10,
        marginTop: 5,
        marginBottom: 10,
    };

    const definitionStyle = {
        fontWeight: 500,
        textAlign: 'left',
        marginLeft: 5,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 10,
    };
    const sentenceStyle = {
        fontWeight: 500,
        textAlign: 'left',
        marginLeft: 10,
        marginTop: 25,
        marginBottom: 5,
    };
    const synonymsStyle = {
        fontWeight: 500,
        textAlign: 'left',
        marginLeft: 10,
        marginTop: 15,
        marginBottom: 5,
    };

    const iconAreaStyle = {
        marginTop: 0,
        position: 'absolute',
        left: '50%', // Center horizontally
        transform: 'translateX(-50%)', // Adjust to center the content horizontally
    };

    const additionalContentHeadingStyle = {
        fontSize: '110%',
        marginTop: 40,
        fontWeight: 700,
        textAlign: 'left',
        marginLeft: 20,
    }
    const additionalContentStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 0,
        marginTop: 0,
        textAlign: 'left',
        marginLeft: 0,
        marginRight: 25,
    };

    // Handle if content is clicked
    const [isContentClicked, setContentClicked] = useState(false);
    const handleContentClick = () => {
        setContentClicked(!isContentClicked);
    };

    // Audio
    const { playAudio } = useAudioPlayer();

    return (


        <div style={containerStyle}>
            <div style={contentStyle} >
                <div>
                    <div style={titleStyle} onClick={() => { playAudio(title) }}>
                        {title}
                    </div>
                    <div style={definitionStyle}>
                        {definition}
                    </div>
                    <div style={sentenceStyle} onClick={() => { playAudio(`${title}-sentence`) }}>
                        <i>{sentence}</i>
                    </div>
                    <div style={synonymsStyle}>
                        Synonyms: {synonyms}
                    </div>
                    <div style={iconAreaStyle} onClick={handleContentClick}>
                        {!isContentClicked && (
                            <img src={icon_down} alt='down' style={iconStyle} />
                        )}
                    </div>
                    {isContentClicked && (
                        <div>
                            <div style={additionalContentHeadingStyle}>
                                Additional sentences:
                            </div>
                            <div style={additionalContentStyle}>
                                <ul>
                                    {sentences.map((sentence, index) => (
                                        <li key={index} style={{ marginBottom: 10 }}>{sentence}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    <div style={iconAreaStyle} onClick={handleContentClick}>
                        {isContentClicked && (
                            <img src={icon_up} alt='up' style={iconStyle} />
                        )}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default SnackBarSingleFormatted;
