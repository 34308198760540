// Colors, styles etc.
import { colors } from 'components/styles/colors';

const icons = {

    // Icon set basic navigation
    icon_go_back: require('img/icons/go_back_component.png'),

    icon_help: require('./icons/symbols/white/icon_w_question-circle.png'),
    icon_email: require('./icons/symbols/white/icon_w_envelope.png'),

    icon_logout: require('img/icons/Icon_logout.svg'),
    icon_avatar: require('img/icons/Icon_avatar.svg'),
    icon_search: require('img/icons/Icon_search.svg'),

    // Icon set spelling test
    icon_correct: require('img/icons/symbols/colour/icon_correct_green.png'),
    icon_incorrect: require('img/icons/symbols/colour/icon_incorrect_red.png'),
    icon_unknown: require('img/icons/symbols/colour/icon_unknown_black.png'),

    // Icon set wordBox accordion
    icon_down: require('img/icons/symbols/black/icon_chevron_S_double_down.png'),
    icon_up: require('img/icons/symbols/black/icon_chevron_S_double_up.png'),

    // Icon set flags circle
    icon_flag_circle_Portugal: require('img/icons/country_flags/circles/098-portugal.png'),

    // Icon set flags rectangular
    icon_flag_rectangular_Portugal: require('img/icons/country_flags/rectangular/097-portugal.png'),

};

export { icons };

