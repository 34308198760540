import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import TagList from 'components/pages/navigation/TagList';

import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

const CardLink = ({
    index,
    to,
    title,
    description,
    isUnitRoot,
    backgroundColor,
    tagList,
    isDisabled,
    currentNavigationPath,
}) => {
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;
    const components = componentStyles(isSmallScreen, isDisabled);
    const { clipboard, setClipboard } = useTopBarCLipboardContext();
    const savedCardsData = clipboard.activitiesData;

    const handleClick = () => {
        index = index || 0;
        const EOB = savedCardsData.value[index]?.endOfBranch || false;
        if (!EOB) {
            setClipboard((prevClipboard) => ({
                ...prevClipboard,
                activityRef: {
                    value: savedCardsData.value[index],
                },
            }));
        }
        if (isUnitRoot) {
            setClipboard((prevClipboard) => ({
                ...prevClipboard,
                unitId: {
                    value: savedCardsData.value[index].range,
                },
                userClassroom: {
                    value: savedCardsData.value[index].classroom,
                },
                returnNavigationPath: {
                    value: currentNavigationPath,
                },
            }));
        }
    };

    const handleMouseEnter = (e) => {
        if (!isDisabled && !isSmallScreen) {
            e.currentTarget.style.transform = 'scale(1.05)';
        }
    };

    const handleMouseLeave = (e) => {
        if (!isDisabled) {
            e.currentTarget.style.transform = 'scale(1)';
        }
    };

    const descriptionWithNewLines = description.split('~').map((line, index) => (
        <span key={index} style={{ display: 'block', lineHeight: 1.2 }}>{line}</span>
    ));

    const LinkContent = () => (
        <>
            <TagList tagList={tagList} />
            <div style={components.tileStyle}>
                <div style={components.tileTitleStyle}>
                    {title}
                </div>
                <div style={components.tileSubtitleStyle}>
                    {descriptionWithNewLines}
                </div>
            </div>
        </>
    );

    return isDisabled ? (
        <div
            style={{
                ...components.squareStyle,
                backgroundColor: backgroundColor,
                cursor: 'default',
                opacity: 0.5,
            }}
        >
            <LinkContent />
        </div>
    ) : (
        <RouterLink
            to={to}
            style={{
                ...components.squareStyle,
                backgroundColor: backgroundColor,
                cursor: 'pointer',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            <LinkContent />
        </RouterLink>
    );
};

export default CardLink;
