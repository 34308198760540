// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { textStyles } from 'components/styles/text_styles';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import useAudioPlayer from 'components/_common/AudioPlayer';

const WordBox = (props) => {

    const { title, data, setUserInput } = props;

    // Extract data for each word
    const sentence = data.spokenSentence
    const synonyms = data.synonyms
    const definition = data.definition
    const keyword = data.keyword

    // Image definitions
    const icon_down = require('img/icons/symbols/black/icon_chevron_S_double_down.png');
    const icon_up = require('img/icons/symbols/black/icon_chevron_S_double_up.png');

    // Audio
    const { playAudio } = useAudioPlayer();

    // Control the hovering effect
    const [isHovered, setIsHovered] = useState(false);

    // Control clicking on the bar
    const [isInfoVisible, setIsInfoVisible] = useState(false);

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const styles = componentStyles(isSmallScreen, isHovered);

    return (
        <div
            style={styles.wordBoxContainerStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >

            <div style={styles.wordBoxMainBlockStyle}>
                <div style={{ ...styles.wordBoxTextWordStyle, cursor: 'pointer' }}
                    onClick={() => { playAudio(title); }}>{keyword}
                </div>

                <div style={styles.workBoxIconAreaStyle}>
                    <img
                        src={isInfoVisible ? icon_up : icon_down}
                        alt="INFO"
                        style={styles.wordBoxIconStyle}
                        onClick={() => setIsInfoVisible(!isInfoVisible)} />
                </div>
            </div>

            {isInfoVisible && (
                <div style={styles.wordBoxDetailTextStyle} >
                    <div style={{
                        ...styles.wordBoxDetailTextStyleLine,
                    }}>
                        Definition: {definition}</div>
                    <div style={{
                        ...styles.wordBoxDetailTextStyleLine,
                    }}>
                        {`Synonyms: ${synonyms}`}</div>
                    <div style={{
                        ...styles.wordBoxDetailTextStyleLine,
                        marginTop: isSmallScreen ? 18 : 24,
                        fontStyle: 'italic',
                        cursor: 'pointer',
                    }}
                        onClick={() => { playAudio(`${title}-sentence`); }}
                    >Sample sentence: {sentence}</div>
                </div>
            )}

        </div>
    );
};

export default WordBox;
