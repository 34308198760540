import { colors } from 'components/styles/colors';
import { textStyles } from 'components/styles/text_styles';
import { paragraphStyles } from 'components/styles/paragraph_styles';

import React, { useState, useEffect } from 'react';

import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';


const WordBox = (props) => {
    const { title, data, setUserInput } = props;

    // Extract data for each issue
    const sentence = data

    // Image definitions
    const icon_down = require('img/icons/symbols/black/icon_chevron_S_double_down.png');
    const icon_up = require('img/icons/symbols/black/icon_chevron_S_double_up.png');

    // Control the hovering effect
    const [isHovered, setIsHovered] = useState(false);

    // Control clicking on the bar
    const [isInfoVisible, setIsInfoVisible] = useState(false);

    // Manage window size
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const containerStyle = {
        position: 'relative',
        marginTop: isSmallScreen ? 0 : 0,
        marginBottom: isSmallScreen ? 4 : 6,
        padding: isSmallScreen ? 12 : 18,
        backgroundColor: isHovered ? colors.gray5 : colors.gray6,
        marginLeft: isSmallScreen ? 12 : 18,
        marginRight: isSmallScreen ? 12 : 18,
        borderRadius: isSmallScreen ? 6 : 9,
    };

    const mainBlockStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }

    const textWordStyle = {
        textAlign: 'left',
        fontSize: isSmallScreen ? 18 : 24,
        fontWeight: 700,
        marginLeft: isSmallScreen ? 9 : 12,
        marginRight: isSmallScreen ? 36 : 50,
        marginTop: isSmallScreen ? 2 : 3,
        marginBottom: isSmallScreen ? 2 : 3,
    };
    const detailTextStyle = {
        textAlign: 'left',
        fontWeight: 400,
        fontSize: isSmallScreen ? 14 : 18,
        margin: 0,
    }
    const detailTextStyleLine = {
        textAlign: 'left',
        fontSize: isSmallScreen ? 14 : 18,
        marginLeft: isSmallScreen ? 9 : 12,
        marginRight: isSmallScreen ? 36 : 50,
        marginTop: isSmallScreen ? 6 : 9,
        marginBottom: isSmallScreen ? 6 : 9,
    }
    const iconAreaStyle = {
        display: 'flex',
        alignItems: 'center',
        marginRight: isSmallScreen ? 9 : 12,
    }
    const iconStyle = {
        cursor: 'pointer',
        marginRight: 0,
        maxWidth: isSmallScreen ? 24 : 32,
        maxHeight: isSmallScreen ? 24 : 32,
    };


    return (
        <div
            style={containerStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >

            <div style={mainBlockStyle}>
                <div style={textWordStyle}>
                    {title}
                </div>
                <div style={iconAreaStyle}>
                    <img
                        src={isInfoVisible ? icon_up : icon_down}
                        alt="INFO"
                        style={iconStyle}
                        onClick={() => setIsInfoVisible(!isInfoVisible)} />
                </div>
            </div>

            {isInfoVisible && (
                <div style={detailTextStyle} >
                    <div style={{
                        ...detailTextStyleLine,
                    }}>
                        {sentence}
                    </div>
                </div>
            )}

        </div>
    );
};

export default WordBox;
