// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // To handle responsiveness

const PageNavigationBarQuiz = ({ goToReview, goToNext, currentIndex, keysLength }) => {

    //Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    //Styles
    const styles = componentStyles(isSmallScreen);

    // Determine if it's the last page
    const isLastPage = currentIndex + 1 === keysLength;
    const isFirstPage = currentIndex === 0;

    // Set the style for the 'Skip' button depending on the page number
    let skipButtonStyle;
    if (isLastPage) {
        skipButtonStyle = {
            ...styles.buttonStyleNavigationBar, backgroundColor: colors.gray4, cursor: 'default'
        };
    } else {
        skipButtonStyle = styles.buttonStyleNavigationBar;
    }

    // Set the style for the 'Review' button depending on the page number
    let reviewButtonStyle;
    if (isFirstPage) {
        reviewButtonStyle = {
            ...styles.buttonStyleNavigationBar, backgroundColor: colors.gray4, cursor: 'default'
        };
    } else {
        reviewButtonStyle = styles.buttonStyleNavigationBar;
    }

    return (

        <div style={styles.navigationBarStyle}>

            <button
                onClick={goToReview}
                style={reviewButtonStyle}
                disabled={isFirstPage}
            >Review</button>

            <div
                style={styles.navigationBarFlexStyle}
            >
                {currentIndex + 1}/{keysLength}
            </div>

            <button
                //onClick={goToNext}
                style={{ ...skipButtonStyle, backgroundColor: colors.white, color: colors.white, cursor: 'default' }}
                //disabled={currentIndex === keysLength - 1}
                disabled={true}
            >Skip</button>

        </div >

    );
};

export default PageNavigationBarQuiz;
