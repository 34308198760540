export const generateQuestions = () => {
    
    const MAX_VALUE = 3999;
    const numNumerals = 200;
    const numeralList = [];

    const romanNumerals = [
        { numeral: 'M', value: 1000 },
        { numeral: 'CM', value: 900 },
        { numeral: 'D', value: 500 },
        { numeral: 'CD', value: 400 },
        { numeral: 'C', value: 100 },
        { numeral: 'XC', value: 90 },
        { numeral: 'L', value: 50 },
        { numeral: 'XL', value: 40 },
        { numeral: 'X', value: 10 },
        { numeral: 'IX', value: 9 },
        { numeral: 'V', value: 5 },
        { numeral: 'IV', value: 4 },
        { numeral: 'I', value: 1 }
    ];

    for (let i = 0; i < numNumerals; i++) {
        let randomValue = Math.floor(Math.random() * MAX_VALUE) + 1;
        let answer = randomValue; // Store the original value
        let resultNumeral = '';

        for (const { numeral, value } of romanNumerals) {
            while (randomValue >= value) {
                resultNumeral += numeral;
                randomValue -= value;
            }
        }
        // console.log('question:', resultNumeral, 'answer:', answer)
        numeralList.push({ question: resultNumeral, answer: answer });
    }

    return numeralList;
};
