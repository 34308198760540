// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { icons } from 'img/icons';
import idioms from 'locales/idioms_static';

// Basic core functions
import React from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';

const logo = require('img/icons/homepage_title_image_right_aligned.png');

function WelcomeBanner() {

    //Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    //Styles
    const styles = componentStyles(isSmallScreen); // requires isSmallScreen loaded

    //Handle page width
    const virtualFullWidthLocal = {
        ...virtualFullWidth,
        backgroundColor: colors.gray2
    }

    return (
        <div style={virtualFullWidthLocal}>
            <div style={availableWidth}>

                <div style={styles.bannerMainFlexStyle}>

                    <div style={styles.bannerLeftFlexStyle}>
                        <div style={styles.bannerTitleStyle}>
                            Learning
                        </div>
                        <div style={styles.bannerSubtitleStyle}>
                            {isSmallScreen ? 'anytime, anywhere' : 'anything, anytime, anywhere'}
                        </div>
                    </div>

                    <div>
                        <img
                            src={logo}
                            alt="Logo"
                            style={styles.bannerImageStyle}
                        />
                    </div>

                </div>

            </div>
        </div >
    );
}

export default WelcomeBanner;
