// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // To handle responsiveness

// Components and blocks
import PageNavigationBarQuiz from 'components/_common/PageNavigationBarQuiz';

const StateQuiz = ({ currentIndex, backendData, setCurrentState, setCurrentIndex, setAnswerList, score, setScore }) => {

    // Navigation controls
    const goToReview = () => {
        setCurrentState('REVIEW');
    };
    const goToNext = () => {
        if (currentIndex < backendData.length - 1) { setCurrentIndex(currentIndex + 1); }
    };

    // Control the hovering effect
    const [isHovered, setIsHovered] = useState(false);

    // Handle words clicked
    const [selectedChoice, setSelectedChoice] = useState(null);
    const isChoiceCorrect = (choice) => {
        return choice === backendData[currentIndex].correctWord;
    };
    const [hasAnswered, setHasAnswered] = useState(false);
    const handleChoiceClick = (choice) => {
        if (!hasAnswered) {
            setSelectedChoice(choice);
            setHasAnswered(true);
            if (choice === backendData[currentIndex].correctWord) {
                setScore(score + 1);
            }
            // Append the answer information to answerList
            setAnswerList(prevAnswers => [
                ...prevAnswers,
                {
                    question: backendData[currentIndex].sentences.join(' '),
                    answer: choice,
                    correctAnswer: backendData[currentIndex].correctWord
                }
            ]);
            // Automatically move to next question after a short delay
            setTimeout(() => {
                if (currentIndex + 1 < backendData.length) {
                    setCurrentIndex(currentIndex + 1);
                }
                else if (currentIndex + 1 === backendData.length) {
                    setCurrentIndex(currentIndex + 1);
                    goToReview();
                }
            }, 1500);
        }
    };
    useEffect(() => {
        // Reset choice and answered state when currentIndex changes
        setSelectedChoice(null);
        setHasAnswered(false);
    }, [currentIndex]);

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const styles = componentStyles(isSmallScreen, isHovered);

    return (
        <>
            {
                backendData[currentIndex] && (
                    <div style={styles.mainDivStyle}>

                        <div
                            style={styles.simpleBarStyle}>
                            Score: {score}
                        </div >

                        {/* Question */}
                        <div style={{ textAlign: 'center' }}>
                            {backendData[currentIndex].sentences.map((sentence, index) => (
                                <p key={index}>{sentence.replace('__________', '_____')}</p>
                            ))}
                        </div>

                        {/* Options available */}
                        <div style={{ textAlign: 'center', margin: '20px 0' }}>
                            {backendData[currentIndex].choices.map((choice, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleChoiceClick(choice)}
                                    style={{
                                        ...styles.choiceButtonStyle,
                                        backgroundColor: selectedChoice === choice
                                            ? isChoiceCorrect(choice) ? colors.success : colors.error
                                            : colors.gray6,
                                        color: selectedChoice === choice
                                            ? isChoiceCorrect(choice) ? colors.light : colors.light
                                            : colors.dark,
                                        border: selectedChoice === choice
                                            ? isChoiceCorrect(choice) ? colors.success : colors.error
                                            : colors.gray3,
                                    }}
                                >
                                    {choice}
                                </button>
                            ))}

                        </div>

                        <div style={styles.dividerStyle}></div>

                        <PageNavigationBarQuiz goToReview={goToReview} goToNext={goToNext} currentIndex={currentIndex} keysLength={backendData.length} />

                    </div>
                )
            }
        </>
    );
};

export default StateQuiz;
