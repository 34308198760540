// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import useAudioPlayer from 'components/_common/AudioPlayer';
//import { hover } from '@testing-library/user-event/dist/hover';

const StateRunning = ({ words, setSequenceState, waitInterval = 3000, repetition = 2 }) => {

    // Audio driver
    const { playAudio } = useAudioPlayer();

    // Audio flow
    const [wordIndex, setWordIndex] = useState(0);
    const [currentRepetition, setCurrentRepetition] = useState(1);
    const [isReadingAloud, setIsReadingAloud] = useState(false);
    useEffect(() => {
        let interval;
        if (isReadingAloud) {
            interval = setInterval(() => {
                const word = words[wordIndex];
                playAudio(word);
                if (currentRepetition < repetition) {
                    // Increment repetition for the current word
                    setCurrentRepetition(currentRepetition + 1);
                } else {
                    // Move to the next word and reset repetition
                    setWordIndex(wordIndex + 1);
                    setCurrentRepetition(1);
                }
            }, waitInterval * 1000);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isReadingAloud, wordIndex, words, currentRepetition, repetition]);
    useEffect(() => {
        setIsReadingAloud(true);
    }, []);
    useEffect(() => {
        let timeoutId;
        if (wordIndex >= words.length) {
            timeoutId = setTimeout(() => {
                setIsReadingAloud(false);
                setSequenceState('FINISHED');
            }, waitInterval * 1000 - 1); //  must be less than waitingInterval
        }
        // return () => { // Cleanup function
        //     if (timeoutId) {
        //         clearTimeout(timeoutId);
        //     }
        // };
    }, [wordIndex, words.length, setSequenceState]);

    // Function to stop dictation
    const stopDictation = () => {
        setIsReadingAloud(false);
        setSequenceState('FINISHED');
    };

    // Control the hovering effect
    const [isHovered, setIsHovered] = useState(false);

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const styles = componentStyles(isSmallScreen, isHovered);

    return (
        <div style={styles.mainDivStyle} >

            <div
                style={{ ...styles.captionLabelStyle, fontWeight: 400 }}>
                Dictation in progress...
            </div >

            <div
                style={styles.simpleTextStyle}>
                {wordIndex}/{words.length}
            </div >

            {/* Button to stop dictation */}
            <button style={styles.buttonStyleInactive}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={stopDictation} >
                Stop dictation
            </button >

        </div >

    );
};

export default StateRunning;
