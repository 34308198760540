// This file handles static imagery content that is loaded dynamically across the app

const staticImages = {
    'img/icons/symbols/white/icon_w_box-arrow-in-right.png': require(
        'img/icons/symbols/white/icon_w_box-arrow-in-right.png'),

    'img/icons/symbols/white/icon_w_question-circle.png': require(
        'img/icons/symbols/white/icon_w_question-circle.png'),

    'img/icons/symbols/white/icon_w_person-circle-fill.png': require(
        'img/icons/symbols/white/icon_w_person-circle-fill.png'),

    'img/icons/discito_logo_small_yellow.png': require(
        'img/icons/discito_logo_small_yellow.png'),

    'img/icons/symbols/white/icon_w_chevron_S_double_left.png': require(
        'img/icons/symbols/white/icon_w_chevron_S_double_left.png'),

};

export default staticImages;