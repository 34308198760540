// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { componentStyles } from 'components/styles/component_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { staticImages } from 'img/static_images';

import React, { useState } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'

import { useNavigate } from 'react-router-dom';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';

import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';

import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

import WelcomeBanner from 'components/pages/navigation/WelcomeBanner';
import WelcomeFooter from 'components/pages/navigation/WelcomeFooter';
import SnackBarSingle from 'components/pages/navigation/SnackBarSingle';
import SnackBarArray from 'components/pages/navigation/SnackBarArray';
import CardsCoverPage from 'components/pages/navigation/CardsCoverPage';
import TableComponent from './TableComponent';
import MessageBox from './MsgBoxComponent';
import CoverPageTile from './CoverPageTileComponent';

import TextInputComponent from './TextInputComponent';
//import staticImages from 'img/staticImageAssets';

const logo = require('img/home/home.jpg');

export function Test02() {
    const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);

    // Read clipboard
    const { clipboard, setClipboard } = useTopBarCLipboardContext();
    // const notifications = clipboard.notifications;

    // To navigate to other pages (button Dashboard requires this)
    const navigate = useNavigate();

    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    const inputTextStyle = {}

    //Styles
    const styles = componentStyles(isSmallScreen);
    const paragraphs = paragraphStyles(isSmallScreen);
    //Images
    //const images = staticImages;

    //MsgBox definitions
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [messageBoxType, setMessageBoxType] = useState("msgBoxOK");
    const handleOK = () => { alert('OK!'); setShowMessageBox(false) };
    const handleCancel = () => { alert('Cancel!'); setShowMessageBox(false) };
    const handleYes = () => { alert('Yes!'); setShowMessageBox(false) };
    const handleNo = () => { alert('No!'); setShowMessageBox(false) };
    const openMessageBox = (messageBoxType) => { setMessageBoxType(messageBoxType); setShowMessageBox(true) };

    // Example usage
    const Component1 = ({ text, isSmallScreen }) => (
        <div style={{
            backgroundColor: colors.orange1,
            height: 'auto',
            flex: isSmallScreen ? '1 0 100%' : '1 0 50%', // 40% of the space on larger screens
            width: isSmallScreen ? '100%' : 'auto', // Ensure full width on small screens
            maxWidth: isSmallScreen ? '100%' : '70%',
            position: 'relative',
            alignContent: 'center'
        }}>
            {/* {text} */}
            {/* Need to define new style... */}
            <div style={{ ...styles.simpleBarStyle, alignItems: 'center' }} >


                <img
                    src={staticImages.discito_image_001_large}
                    alt="discito_image_001_large"
                    style={{
                        maxWidth: '100%', // Makes sure the image is not bigger than its container
                        height: 'auto', // Keeps the aspect ratio of the image
                        alignItems: 'center',
                        // Adjust as necessary to control the size of the image relative to the screen width
                    }}
                />
            </div>
        </div >
    );

    const Component2 = ({ text, isSmallScreen }) => (
        <div style={{
            backgroundColor: colors.purple1,
            height: 'auto',
            flex: isSmallScreen ? '1 0 100%' : '1 0 50%', // 60% of the space on larger screens
            width: isSmallScreen ? '100%' : 'auto', // Ensure full width on small screens
            position: 'relative',
        }}>
            {text}
            <div style={{ ...styles.simpleBarStyle, alignItems: 'center' }} >

            </div>
        </div>

    );

    return (
        <div className="app" style={{ backgroundcolor: colors.gray2 }}>

            <WelcomeBanner />

            <main>
                <div style={virtualFullWidth}>
                    <div style={availableWidth}>
                        <div style={styles.simpleBarStyle}>

                            <button
                                style={styles.buttonStyleCore}
                                onClick={() => openMessageBox('msgBoxOK')}
                            >
                                {'Open MsgBoxOK'}

                            </button>

                            <button
                                style={styles.buttonStyleCore}
                                onClick={() => openMessageBox('msgBoxOKCancel')}
                            >
                                {'Open MsgBoxOKCancel'}
                            </button>

                            <button
                                style={styles.buttonStyleCore}
                                onClick={() => openMessageBox('msgBoxYesNo')}
                            >
                                {'Open msgBoxYesNo'}

                            </button>

                            <button
                                style={styles.buttonStyleCore}
                                onClick={() => openMessageBox('msgBoxYesNoCancel')}
                            >
                                {'Open msgBoxYesNoCancel'}

                            </button>

                            <MessageBox
                                msgBoxType={messageBoxType}
                                isVisible={showMessageBox}
                                title={`Title: ${messageBoxType}`}
                                message={`Text: Sample text for ${messageBoxType}`}
                                onOK={handleOK}
                                onCancel={handleCancel}
                                onYes={handleYes}
                                onNo={handleNo}
                            />
                        </div>

                        <div style={styles.dividerStyle}></div>

                        <button
                            style={styles.buttonStyleCore}
                        //onClick={() => openMessageBox('msgBoxYesNoCancel')}
                        >
                            {'Go to Consent_new'}

                        </button>

                        <div style={styles.dividerStyle}></div>

                        <button
                            style={styles.buttonStyleCore}
                        //onClick={() => openMessageBox('msgBoxYesNoCancel')}
                        >
                            {'Go to ResetPassword_new'}

                        </button>

                        <div style={styles.dividerStyle}></div>


                        <button
                            style={styles.buttonStyleCore}
                        //onClick={() => openMessageBox('msgBoxYesNoCancel')}
                        >
                            {'Go to AddWord_new'}

                        </button>

                        <div style={styles.dividerStyle}></div>

                        <div style={styles.simpleBarStyle}>

                            {/* Overlay image */}
                            {/* <div style={{
                                position: 'absolute',
                                top: '20%', // Adjust based on where you want the overlay
                                left: '50%', // Center the overlay. Adjust as necessary.
                                transform: 'translateX(-50%)', // Ensures the overlay is centered at the specified left value
                                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
                                color: 'white', // Text color
                                padding: '20px', // Space inside the overlay
                                width: '50%', // Adjust overlay width as necessary
                                textAlign: 'center', // Centers the text inside the overlay
                            }}>
                                <h2>Overlay Title</h2>
                                <p>Some description text that goes over the image.</p>
                                <button style={{
                                    padding: '10px 20px', // Adjust button padding as necessary
                                    fontSize: '16px', // Adjust button text size as necessary
                                    cursor: 'pointer', // Changes the cursor on hover
                                }}>Click Me</button>
                            </div> */}
                        </div>

                    </div>
                </div>
            </main >

            <WelcomeFooter />

        </div >
    )
}
