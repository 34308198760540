// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Initial commit

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

const PageNavigationBar = ({ goToPrevious, goToNext, currentIndex, keysLength }) => {

    // Control the hovering effect
    const [isHovered, setIsHovered] = useState(false);

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const styles = componentStyles(isSmallScreen, isHovered);

    return (

        <div style={styles.mainDivStyle}>
            <div style={styles.navigationBarStyle}>

                <button
                    onClick={goToPrevious}
                    disabled={currentIndex === 0}
                    style={{
                        ...styles.buttonStyleNavigationBar,
                        cursor: currentIndex === 0 ? 'default' : 'pointer',
                        color: currentIndex === 0 ? colors.gray4 : colors.light,
                        backgroundColor: currentIndex === 0 ? colors.gray5 : colors.primary,
                    }}
                >Previous</button>

                <div
                    style={styles.navigationBarFlexStyle}
                >
                    {currentIndex + 1}/{keysLength}
                </div>

                <button
                    onClick={goToNext}
                    disabled={currentIndex === keysLength - 1}
                    style={{
                        ...styles.buttonStyleNavigationBar,
                        cursor: currentIndex === keysLength - 1 ? 'default' : 'pointer',
                        color: currentIndex === keysLength - 1 ? colors.gray4 : colors.light,
                        backgroundColor: currentIndex === keysLength - 1 ? colors.gray5 : colors.primary,
                    }}
                >Next</button>

            </div >
        </div >
    );
};

export default PageNavigationBar;
