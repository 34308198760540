import { colors } from 'components/styles/colors';

import React from 'react';

import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

import WordBox from 'components/pages/help/_Help/WordBox';


const HelpMemo = (props) => {
    const { issueKey, issue } = props;

    // Styles
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    const dividerStyle = {
        width: '100%',
        height: '1px',
        backgroundColor: colors.gray6,
        margin: isSmallScreen ? '12px 0' : '16px 0', // Adjust the margins as needed
    }

    // Content
    const textBody = issue.body.split('~').map((line, index) => (
        <p key={index}>
            {line}
        </p>
    ));

    return (
        <div>

            <div key={issueKey}>
                <WordBox key={issueKey} title={issue.title} data={textBody} />
            </div>

            <div style={dividerStyle} />

        </div >
    );
}

export default HelpMemo;
