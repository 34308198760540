// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // To handle responsiveness

const PageNavigationBarQuiz = ({ goToReview, keysLength, assignedWordsCount }) => {

    //Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    //Styles
    const styles = componentStyles(isSmallScreen);

    // Determine if all sentences were handles
    const isAllAssigned = keysLength === assignedWordsCount;

    // Set the style for the 'Review' button depending on the page number
    let reviewButtonStyle;
    if (isAllAssigned === false) {
        reviewButtonStyle = { ...styles.buttonStyleNavigationBar, backgroundColor: colors.gray4, cursor: 'default' };
    } else {
        reviewButtonStyle = styles.buttonStyleNavigationBar;
    }

    return (
        <div style={{ ...styles.navigationBarStyle, alignContent: 'center', justifyContent: 'center' }}>

            <button
                onClick={goToReview}
                style={reviewButtonStyle}
                disabled={isAllAssigned === false ? true : false}
            >Review</button>

        </div>

    );
};

export default PageNavigationBarQuiz;
