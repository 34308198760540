// Colors, styles etc.
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // To handle responsiveness
import { useNavigate } from 'react-router-dom'; // To handle navigation
import { shuffleArray } from 'components/_common/functions/shuffleArray';

// Components and blocks
import PageNavigationBarReview from 'components/pages/languages/_VocabularyExamLong/PageNavigationBarReview';
import InteractiveTableComponent from 'components/_common/InteractiveTableComponent';

const StateReview = ({ answerList, setAnswerList, setCurrentState, score, setScore, currentIndex, setCurrentIndex, backendData, setBackendData }) => {

    // Navigation
    const navigate = useNavigate();

    // Navigation controls
    const goExit = () => {
        navigate('/dashboard?pk=ACTIVITY&sk=ENG~');
    };
    const goRepeat = () => {
        setScore(0);
        setCurrentIndex(0);
        setAnswerList([]);

        // Shuffle the backendData array
        const shuffledData = shuffleArray([...backendData]);
        setBackendData(shuffledData);

        setCurrentState('QUIZ');
    };
    // const goContinue = () => {
    //     // setScore(0);
    //     setCurrentIndex(currentIndex + 1);
    //     setCurrentState('QUIZ');
    // };

    // Convert answerList to the format expected by TableComponent
    const headers_alt = ['Your Answer', 'Correct Answer', 'Result'];
    const rows_alt = answerList.map(answer => ({
        'Your Answer': answer.answer,
        'Correct Answer': answer.correctAnswer,
        'Result': answer.answer === answer.correctAnswer ? 'Correct' : 'Incorrect',
    }));
    const tooltips_alt = answerList.map(answer => [answer.question]);

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const styles = componentStyles(isSmallScreen);

    return (
        <div style={styles.mainDivStyle}>

            <div>
                <InteractiveTableComponent headers={headers_alt} rows={rows_alt} tooltips={tooltips_alt} />
            </div>

            < div style={styles.simpleBarStyle} >
                Score: {score} / {answerList.length}
            </div >

            <div style={styles.dividerStyle}></div>

            <PageNavigationBarReview goExit={goExit} goRepeat={goRepeat} />

        </div >

    );
};

export default StateReview;
