// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // To handle responsiveness

// Components and blocks
import PageNavigationBarQuiz from 'components/pages/languages/_VocabularyExam/PageNavigationBarQuiz';

const StateQuiz = ({ currentIndex, setCurrentIndex, backendData, setCurrentState, setAnswerList, score, setScore }) => {

    // Navigation controls
    const [isWaitingState, setIsWaitingState] = useState(false);
    const goToReview = () => {
        setCurrentState('REVIEW');
    };
    const goToNext = () => {

        setIsWaitingState(true);

        // Check if all answers are correct and adjust the delay accordingly
        const allAnswersCorrect = Object.keys(assignedWords).every((index) => {
            const choice = assignedWords[index];
            return isChoiceCorrect(choice, parseInt(index));
        });
        const delay = allAnswersCorrect ? 500 : 2500;

        // Automatically move to next question after a short delay
        setTimeout(() => {
            setIsWaitingState(false);
            if (currentIndex < backendData.length - 1) {
                setCurrentIndex(currentIndex + 1);
                setSelectedSentence(0);
                setAssignedWords({});
            }
        }, delay);

    };

    // Determine words available
    const [choicesAvailable, setChoicesAvailable] = useState([]);
    const shuffleArray = (array) => {
        let currentIndex = array.length, randomIndex;
        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
        return array;
    }
    useEffect(() => {
        if (backendData && backendData.length > currentIndex) {
            const currentItems = backendData[currentIndex];
            if (currentItems && Array.isArray(currentItems)) {
                let answers = currentItems.map(item => item.answer).filter(answer => answer !== undefined);
                answers = shuffleArray(answers); // Shuffle the answers
                setChoicesAvailable(answers);
            } else {
                setChoicesAvailable([]);
            }
        }
    }, [currentIndex, backendData]);

    // Handle sentences clicked
    const [selectedSentence, setSelectedSentence] = useState(0);
    const [assignedWords, setAssignedWords] = useState({});
    const handleSentenceClick = (index) => {
        setSelectedSentence(index);
    };

    // Handle words clicked
    const handleChoiceClick = (choice) => {
        if (selectedSentence !== null) {
            setAssignedWords({ ...assignedWords, [selectedSentence]: choice });

            // Update answerList and compute the score based on the latest values
            setAnswerList(prevAnswers => {
                const existingAnswerIndex = prevAnswers.findIndex(
                    answer => answer.question === backendData[currentIndex][selectedSentence].question
                );

                const newAnswer = {
                    question: backendData[currentIndex][selectedSentence].question,
                    answer: choice,
                    correctAnswer: backendData[currentIndex][selectedSentence].answer
                };

                let updatedAnswers;
                if (existingAnswerIndex !== -1) {
                    // Update existing answer
                    updatedAnswers = [...prevAnswers];
                    updatedAnswers[existingAnswerIndex] = newAnswer;
                } else {
                    // Add new answer
                    updatedAnswers = [...prevAnswers, newAnswer];
                }

                // Compute the score based on updatedAnswers
                const newScore = updatedAnswers.reduce((score, answer) => {
                    return score + (answer.answer === answer.correctAnswer ? 1 : 0);
                }, 0);

                setScore(newScore);
                return updatedAnswers;
            });

            // Advance to the next sentence or handle the end of sentences
            if (selectedSentence < backendData[currentIndex].length - 1) {
                setSelectedSentence(selectedSentence + 1);
            }
        }
    };

    const isChoiceCorrect = (choice, sentenceIndex) => {
        return choice === backendData[currentIndex][sentenceIndex].answer;
    };

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const styles = componentStyles(isSmallScreen);

    return (
        <>
            {
                backendData[currentIndex] && (

                    <div style={styles.mainDivStyle}>

                        {/* Display sentences */}
                        <div style={styles.unselectedSentenceStyle}>
                            {backendData[currentIndex].map((item, index) => {
                                let sentenceWithChoice;
                                if (assignedWords[index]) {
                                    // Start with a prededfined style for sentences. If isWaitingState true then update it
                                    const isCorrect = isChoiceCorrect(assignedWords[index], index);
                                    let underscoreReplacementStyle = {
                                        ...styles.underscoreReplacementStyle,

                                    };
                                    if (isWaitingState) {
                                        underscoreReplacementStyle = {
                                            ...underscoreReplacementStyle,
                                            color: isCorrect ? colors.success : colors.error,
                                        };
                                    }

                                    // Replace the gap with the formatted word
                                    sentenceWithChoice = (
                                        <>
                                            {item.question.split('__________')[0]}
                                            <span style={underscoreReplacementStyle}>
                                                __{assignedWords[index]}__
                                            </span>
                                            {item.question.split('__________')[1]}
                                        </>
                                    );
                                } else {
                                    sentenceWithChoice = item.question;
                                }

                                return (
                                    <div
                                        key={index}
                                        onClick={() => handleSentenceClick(index)}
                                        style={selectedSentence === index ? styles.selectedSentenceStyle : styles.unselectedSentenceStyle}
                                    >
                                        {sentenceWithChoice}
                                    </div>
                                );
                            })}
                        </div>

                        {/* Display buttons */}
                        <div style={{ textAlign: 'center', margin: '20px 0' }}>
                            {choicesAvailable.map((choice, index) => {
                                // Determine if this choice has been assigned to any sentence
                                const sentenceIndex = Object.keys(assignedWords).find(key => assignedWords[key] === choice);

                                // Start with a style for buttons and modify it if button has been used and isWaitingState
                                let buttonStyle = {
                                    ...styles.choiceButtonStyle,
                                    backgroundColor: colors.gray6,
                                    color: colors.dark,
                                    border: colors.gray3,
                                };

                                // If assigned and isWaitingState, check if it's correct and update style accordingly
                                if (sentenceIndex !== undefined && isWaitingState) {
                                    const isCorrect = isChoiceCorrect(choice, sentenceIndex);
                                    buttonStyle = {
                                        ...buttonStyle,
                                        backgroundColor: isCorrect ? colors.success : colors.error,
                                        color: isCorrect ? colors.light : colors.light,
                                        border: isCorrect ? colors.success : colors.error,
                                    };
                                }

                                return (
                                    <button
                                        key={index}
                                        onClick={() => handleChoiceClick(choice)}
                                        style={buttonStyle}
                                    >
                                        {choice}
                                    </button>
                                );
                            })}
                        </div>

                        <div style={styles.dividerStyle}></div>

                        <PageNavigationBarQuiz goToReview={goToReview} goToNext={goToNext} currentIndex={currentIndex} keysLength={backendData.length} assignedWordsCount={Object.keys(assignedWords).length}
                        />

                    </div>
                )
            }
        </>
    );
};

export default StateQuiz;