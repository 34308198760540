// Colors, styles etc.
import { componentStyles } from 'components/styles/component_styles';
import { tableStyles } from 'components/styles/table_styles';

// Basic core functions
import React, { useState } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // To handle responsiveness

const InteractiveTableComponent = ({ headers, rows, tooltips, showGrid = true }) => {

    // Icon image file definitions
    const icon_correct = require('img/icons/symbols/colour/icon_correct_green.png');
    const icon_incorrect = require('img/icons/symbols/colour/icon_incorrect_red.png');
    const icon_unknown = require('img/icons/symbols/colour/icon_unknown_black.png');

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Parmaeters
    const isGridVisible = showGrid = true;

    // Handle row selection
    const [selectedRow, setSelectedRow] = useState(null);
    const handleRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    };
    const closeTooltip = () => {
        setSelectedRow(null);
    };

    //Styles
    const styles = componentStyles(isSmallScreen);
    const tables = tableStyles(isSmallScreen, isGridVisible);

    return (
        <div>
            <table style={tables.tableStyleBasic}>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th style={tables.tableHeaderStyleBasic} key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}
                            onClick={() => handleRowClick(rowIndex)}
                            style={tables.tableCellStyleBasic}
                        >
                            {headers.map((header, colIndex) => (
                                <td key={colIndex}
                                    style={selectedRow === rowIndex ? tables.tableCellStyleHighlighted : tables.tableCellStyleBasic}
                                >
                                    {row[header] === 'Correct' && (
                                        <img
                                            src={icon_correct}
                                            alt="Correct answer"
                                            style={tables.tableCellIconStyleBasic}
                                        />
                                    )}
                                    {row[header] === 'Incorrect' && (
                                        <img
                                            src={icon_incorrect}
                                            alt="Inorrect answer"
                                            style={tables.tableCellIconStyleBasic}
                                        />
                                    )}
                                    {row[header] === 'Skipped' && (
                                        <img
                                            src={icon_unknown}
                                            alt="Skipped answer"
                                            style={tables.tableCellIconStyleBasic}
                                        />
                                    )}
                                    {row[header]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            {selectedRow !== null && (
                <div className="tooltip">
                    <p> {tooltips[selectedRow]} </p>
                    <button
                        onClick={closeTooltip}
                        style={{ ...styles.choiceButtonStyle, marginTop: 0 }}
                    >
                        Close
                    </button>

                    <div style={styles.dividerStyle}></div>

                </div>
            )}

        </div>
    );
};

export default InteractiveTableComponent;