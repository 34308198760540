import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Log the error to the console or send it to a logging service
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Simple fallback UI with plain text
            return <div style={{ padding: '20px', fontSize: '18px' }}>
                Please refresh the page.
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
