// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // To handle responsiveness

const PageNavigationBarReview = ({ goExit, goContinue, goRepeat, currentIndex, keysLength }) => {

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    //Styles
    const styles = componentStyles(isSmallScreen);

    // Determine if it's the last page
    const isLastPage = currentIndex === keysLength;

    // Set the style for the 'Continue' button depending on the page number
    let continueButtonStyle;
    if (isLastPage) {
        continueButtonStyle = {
            ...styles.buttonStyleNavigationBar, backgroundColor: colors.gray4, cursor: 'default'
        };
    } else {
        continueButtonStyle = styles.buttonStyleNavigationBar;
    }

    return (
        <div style={styles.navigationBarStyle}>

            <button
                onClick={goExit}
                style={styles.buttonStyleNavigationBar}
            >Exit</button>

            <button
                onClick={goContinue}
                style={continueButtonStyle}
                disabled={isLastPage}
            >Continue</button>

            <button
                onClick={goRepeat}
                style={styles.buttonStyleNavigationBar}
            >Repeat</button>

        </div >
    );
};

export default PageNavigationBarReview;
