// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { icons } from 'img/icons';
import idioms from 'locales/idioms_static';

// Basic core functions
import React from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { globalSettings } from 'GlobalSettings';

const PageTitle = (props) => {
    // Parameters
    const { title, subtitle } = props;

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const paragraphs = paragraphStyles(isSmallScreen);
    const styles = componentStyles(isSmallScreen);

    // Handle page width
    const virtualFullWidthLocal = {
        ...virtualFullWidth,
        backgroundColor: colors.gray5
    }

    return (
        <div style={virtualFullWidthLocal}>

            <div style={availableWidth}>
                <div
                    style={styles.pageTitleFlexStyle1}
                >
                    <div // Left side for text
                        style={styles.pageTitleFlexStyle2}
                        direction="column"
                    >
                        <div
                            style={paragraphs.pageTitleTitle}
                        >
                            {title}
                        </div>
                        <div
                            style={paragraphs.pageTitleSubtitle}
                        >
                            {subtitle}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}


export default PageTitle;
