/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_appsync_graphqlEndpoint": "https://zreqn4lch5gbxclqi774j6didq.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-jhxxiwcapvcydiwv7mj53qnwji",
    "aws_cognito_identity_pool_id": "eu-north-1:eea2d143-fff3-44fa-a503-e14857c9cab5",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_MIZYcZBjX",
    "aws_user_pools_web_client_id": "74o2eh29hkmmr29386mm6ub4ii",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "greystorage182651-dev",
    "aws_user_files_s3_bucket_region": "eu-north-1"
};


export default awsmobile;
