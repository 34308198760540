// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import idioms from 'locales/idioms_static';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { globalSettings } from 'GlobalSettings';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

// Components and blocks
import SectionHeadingToolbar from 'components/_common/SectionHeadingToolbar';
import PageTitleActivity from 'components/pages/navigation/PageTitleActivity';
import DashboardFooter from 'components/pages/navigation/DashboardFooter';
import StateIdle from 'components/pages/languages/_SpellingTest/StateIdle';
import StateRunning from 'components/pages/languages/_SpellingTest/StateRunning';
import StateFinished from 'components/pages/languages/_SpellingTest/StateFinished';

const default_settings = {
  activityTitle: 'Spelling Test',
  leftButtons: [
    {
      title: 'Back',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/dashboard?pk=ACTIVITY&sk=ENG~',
      visibleIfAuthenticated: true,
      visibleIfNotAutheYnticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
    },
  ],
  rightButtons: [
    {
      title: 'Logout',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/logout',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
    }
  ]
}

export function SpellingTest({ settings = default_settings }) {
  // Language selection for static texts
  const [activeLanguage, setActiveLanguage] = useState('en');
  const currentTexts = idioms[activeLanguage];
  // Static texts aliases
  const instructions = currentTexts.language_instructions.spelling_test;

  // Read clipboard
  const { clipboard } = useTopBarCLipboardContext();
  const userClassroom = clipboard.userClassroom.value;
  const unitId = clipboard.unitId.value;

  // Read backend
  const [backendData, setBackendData] = useState([]);
  const [backendDataCache, setBackendDataCache] = useState([]);
  const [backendDefaultLanguage, setBackendDefaultLanguage] = useState('en');
  const [backendMotherTongue, setBackendMotherTongue] = useState('cz');
  useEffect(() => {
    const requestData = {
      task: 'languageOverviewWords',
      range: userClassroom + '~' + unitId + '~',
      language: backendDefaultLanguage,
      motherTongue: backendMotherTongue,
    };
    const fetchData = async () => {
      const data = await fetchBackendData(requestData);
      if (data) { setBackendDataCache(data); }
      if (data) { setBackendData(data[backendDefaultLanguage]); }
    };
    fetchData();
  }, [userClassroom, unitId, backendDefaultLanguage, backendMotherTongue]);

  // Set-up top bar
  useNavigationConfig(null, null, settings.leftButtons, settings.rightButtons);

  // Use the useLayoutEffect hook to scroll to the top after the component is rendered
  useScrollToTop();
  // useDebugClipboard(clipboard);

  // Responsiveness
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= globalSettings.smallScreenWidth;

  // Control the hovering effect
  const [isHovered, setIsHovered] = useState(false);

  // Styles
  const styles = componentStyles(isSmallScreen, isHovered);

  // Handle settings
  const [userInput, setUserInput] = useState('');
  const [waitInterval, setWaitInterval] = useState(4); // Default 4000ms
  const [repetition, setRepetition] = useState(2); // Default 2 repetitions

  // Obtain list of words and shuffle it
  const [words, setWords] = useState([]);
  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    const shuffledWords = shuffleArray(Object.keys(backendData));
    setWords(shuffledWords);
  }, [backendData]);

  // Handle state
  const [sequenceState, setSequenceState] = useState('IDLE');
  useEffect(() => {
    // console.log('sequenceState:', sequenceState)
  }, [sequenceState]);


  return (

    <div className="app">

      <PageTitleActivity
        title={default_settings.activityTitle + " | " + globalSettings.courseNameEN}
        subtitle={globalSettings.clientName + " | " + userClassroom.split('-')[0]} />

      <main>
        <div style={virtualFullWidth}>
          <div style={availableWidth}>

            <SectionHeadingToolbar
              title={settings.activityTitle}
              setUserInput={setUserInput}
              infoText={instructions}
              activeLanguage={activeLanguage}
              setActiveLanguage={setActiveLanguage}
            />

            {sequenceState === 'IDLE' && <StateIdle setSequenceState={setSequenceState} waitInterval={waitInterval} repetition={repetition} setWaitInterval={setWaitInterval} setRepetition={setRepetition} />}

            {sequenceState === 'RUNNING' && <StateRunning words={words} setSequenceState={setSequenceState} waitInterval={waitInterval} repetition={repetition} />}

            {sequenceState === 'FINISHED' && <StateFinished words={words} setSequenceState={setSequenceState} />}

          </div>
        </div>
      </main >

      <DashboardFooter />

    </div >
  );
}