// Colors, styles etc.
import { colors } from 'components/styles/colors';

const staticImages = {

    // Image sources

    // Image set logo and label
    discito_label_new_dark: require('./pictures/Discito_label_new_dark.png'),
    discito_label_new_light: require('./pictures/Discito_label_new_light.png'),
    discito_label_new_white: require('./pictures/Discito_label_new_white.png'),

    discito_logo_label_new_dark_bw: require('./pictures/Discito_logo_label_new_dark_bw.png'),
    discito_logo_label_new_dark_color: require('./pictures/Discito_logo_label_new_dark_color.png'),
    discito_logo_label_new_light_bw: require('./pictures/Discito_logo_label_new_light_bw.png'),
    discito_logo_label_new_light_color: require('./pictures/Discito_logo_label_new_light_color.png'),
    discito_logo_label_new_white: require('./pictures/Discito_logo_label_new_white.png'),

    discito_logo_new_large_bw: require('./pictures/Discito_logo_new_large_bw.png'),
    discito_logo_new_large_color: require('./pictures/Discito_logo_new_large_color.png'),
    discito_logo_new_small_bw: require('./pictures/Discito_logo_new_small_bw.png'),
    discito_logo_new_small_color: require('./pictures/Discito_logo_new_small_color.png'),
    discito_logo_new: require('./pictures/Discito_logo_new.png'),

    // Image set pictures
    discito_image_001_large: require('./pictures/Discito_image_001_large.png'),
    discito_image_001_small: require('./pictures/Discito_image_001_small.png'),
    discito_image_002_large: require('./pictures/Discito_image_002_large.png'),
    discito_image_002_small: require('./pictures/Discito_image_002_small.png'),
    discito_image_003_large: require('./pictures/Discito_image_003_large.png'),
    discito_image_003_small: require('./pictures/Discito_image_003_small.png'),
    discito_image_header_large: require('./pictures/Discito_image_header_large.png'),
    discito_image_header_small: require('./pictures/Discito_image_header_small.png'),
};

export { staticImages };
