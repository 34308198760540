import { colors } from 'components/styles/colors'
import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react'
import {
  View,
  Heading,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button
} from '@aws-amplify/ui-react'
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues'
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext'
import { useNavigate } from 'react-router-dom'

import { fetchBackendData } from 'components/_common/amplify/fetchBackendData'

import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'
import {
  getSentenceQuery
} from '../../../queries/queries'

export function Review() {

  // Read clipboard
  const { clipboard } = useTopBarCLipboardContext();
  const quizResponse = clipboard.quizResponse;
  const returnPath = clipboard.returnPath.value;

  const navigate = useNavigate();

  // Set-up navigation buttons
  const leftButtons = useMemo(() => [
    {
      title: 'Back',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: `/dashboard?pk=ACTIVITY&sk=CERMAT~MAT~`,
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
    },
  ], []);
  const rightButtons = useMemo(() => [
    {
      title: 'Logout',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/logout',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
    }
  ], []);
  useNavigationConfig(null, null, leftButtons, rightButtons);

  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= 768;

  const [detailText, setDetailText] = useState('')

  // Log the array to the console
  useEffect(() => {
    // console.log('quizResponse:', quizResponse);
  }, [quizResponse]);

  function splitCompositeKey(inputString) {
    const parts = inputString.split('~');
    const id = parts[0];
    const range = parts.slice(1).join('~');
    return { id, range };
  }

  async function handleRowClick(rowId) {

    const isGraphQL = true; const isGateway = false  // must be passed as default is true in fetchBackendData.js

    const clickedRow = quizResponseTable[rowId];
    const { id, range } = splitCompositeKey(clickedRow.questionId);

    const response = await fetchBackendData({
      query: getSentenceQuery,
      variables: {
        id: id,
        range: range,
      },
    }, isGateway, isGraphQL)

    const question_parsed = response.data.getWords.question
    const definition_parsed = response.data.getWords.definition
    if (definition_parsed === null) {
      setDetailText(question_parsed);
    } else {
      setDetailText(definition_parsed);
    }

  }

  // Parse quizResponse.value as JSON
  let quizResponseTable;
  try {
    quizResponseTable = JSON.parse(quizResponse.value);
  } catch (error) {
    console.error('Failed to parse quizResponseTable:', error);
    quizResponseTable = [];
  }

  // Deal with accuracy report
  const totalQuestions = quizResponseTable.length;
  const correctAnswers = quizResponseTable.filter(entry => entry.correct).length;
  const totalTimeElapsed = quizResponseTable.reduce((total, entry) => total + entry.elapsed, 0);
  const accuracy = totalQuestions > 0 ? ((correctAnswers / totalQuestions) * 100).toFixed(0) : 0;
  const totalMinutes = Math.floor(totalTimeElapsed / 60);
  const totalSeconds = Math.floor(totalTimeElapsed % 60);
  const scoreText = `You've got ${correctAnswers}/${totalQuestions} score`;
  const additionalInfo = `Accuracy ${accuracy}% in ${totalMinutes > 0 ? `${totalMinutes} minute${totalMinutes >= 2 ? 's' : ''}` : `${totalSeconds} second${totalSeconds >= 2 ? 's' : ''}`}`;

  // Use the useLayoutEffect hook to scroll to the top after the component is rendered
  useLayoutEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0); // Scroll to the top instantly
    };
    scrollToTop(); // Call the function after the component is rendered
  }, []);

  // Render the page
  return (
    <View style={{ marginTop: '20px' }} margin={isSmallScreen ? '3rem 0' : '3rem 20%'}>
      <div>
        <Heading level={2} className="dashboard-heading">
          Summary
        </Heading>
        <View>
          <View>
            <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2em' }}>{scoreText}</p>
            <p style={{ textAlign: 'center', fontSize: '1.0em' }}>{additionalInfo}</p>
          </View>

          {detailText !== '' && (
            <View style={{
              fontSize: '100%',
              backgroundColor: 'lightyellow',
              padding: '10px',
              border: '1px solid #ccc',
            }}>
              <p style={{ textAlign: 'center', fontSize: '0.8em' }}>{detailText}</p>
            </View>
          )}

          <Table style={{ marginTop: '20px' }} highlightOnHover={true}>
            <TableHead>
              <TableRow>
                <TableCell
                  as='th'
                  style={{ width: '30%', backgroundColor: 'lightblue' }}
                >
                  Elapsed
                </TableCell>
                <TableCell
                  as='th'
                  style={{ width: '30%', backgroundColor: 'lightblue' }}
                >
                  Expected
                </TableCell>
                <TableCell
                  as='th'
                  style={{ width: '30%', backgroundColor: 'lightblue' }}
                >
                  Answered
                </TableCell>
                <TableCell
                  as='th'
                  style={{ width: '10%', backgroundColor: 'lightblue' }}
                >
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quizResponseTable.map((entry, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(index)}
                  style={{ cursor: 'pointer' }} // Change cursor to indicate it's clickable
                >
                  <TableCell style={{ width: '20%' }}>{entry.elapsed}</TableCell>
                  <TableCell style={{ width: '35%' }}>{entry.question}</TableCell>
                  <TableCell style={{ width: '35%', color: entry.correct ? 'black' : 'red' }}>{entry.answer}</TableCell>
                  <TableCell style={{ width: '10%', color: entry.correct ? 'black' : 'red' }}>
                    {entry.correct ? '✓' : '✗'}
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </View>
      </div>

      {/* Add a button to navigate to /dashboard */}
      <View style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button
          onClick={() => {
            navigate(returnPath);
          }}
          style={{ width: '10rem' }}
          type='submit'
          variation='primary'
        >
          Back to Unit
        </Button>
      </View>
    </View>
  );
}
