export function shuffleArray(correctWord, words, arrayLength = 3) {

  // Remove value from 'words' array
  const filteredWords = words.filter((word) => word !== correctWord)

  // Shuffle the combined array
  const shuffledArray = filteredWords.sort(() => Math.random() - 0.5)

  // Concatenate 'correctWord' and 'shuffledArray'
  const combinedArray = [correctWord, ...shuffledArray]

  // Keep only the first 'arrayLength' elements
  const validArray = combinedArray.slice(0, arrayLength)

  // Shuffle the combined array
  const finalArray = validArray.sort(() => Math.random() - 0.5)

  return finalArray
}
