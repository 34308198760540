import { colors } from 'components/styles/colors';
import { textStyles } from 'components/styles/text_styles';
import { paragraphStyles } from 'components/styles/paragraph_styles';

import React, { useState } from 'react';

import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { globalSettings } from 'GlobalSettings';

import SnackBarSingle from 'components/pages/navigation/SnackBarSingle';

const SectionHeadingToolbar = (props) => {
    const { title, infoText, setUserInput, activeLanguage, setActiveLanguage } = props;

    // Image definitions
    const icon_gear = require('img/icons/symbols/black/icon_gear.png');
    const icon_info = require('img/icons/symbols/black/icon_info-circle.png');

    // Event handler to update filterSelection when TextField changes
    const [languageSelection, setLanguageSelection] = useState('');
    const [showInfoSnackBar, setShowInfoSnackBar] = useState(false);
    const [showSettingsSnackBar, setShowSettingsSnackBar] = useState(false);

    const clickEventHandler = (event, imageClicked) => {
        setLanguageSelection(imageClicked);
        setUserInput(imageClicked); // Report the image clicked

        // Toggle the corresponding Snackbar visibility
        if (imageClicked === 'INFO') {
            setShowInfoSnackBar((prevShowInfoSnackBar) => !prevShowInfoSnackBar);
        }
        //else if (imageClicked === 'SETTINGS') {
        //  setShowSettingsSnackBar((prevShowSettingsSnackBar) => !prevShowSettingsSnackBar);
        //}
    };

    // Get Global settings
    const settings = globalSettings;

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const paragraphs = paragraphStyles(isSmallScreen);

    // Styles common to all devices
    const containerStyle = { position: 'relative', marginTop: isSmallScreen ? 14 : 18, marginBottom: isSmallScreen ? 14 : 18 };
    const mainTitleBlock = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: "0px"
    }
    const iconStyle = { cursor: 'pointer', width: isSmallScreen ? 28 : 32, height: isSmallScreen ? 28 : 32, maxWidth: 32, maxHeight: 32, marginRight: 0 };
    const iconLabelStyle = { marginTop: 2, marginLeft: 8, marginRight: 12 };
    const iconTextSetBlock = { display: 'flex', flexDirection: 'row' }
    const iconTextBlock = { cursor: 'pointer', display: 'flex', alignItems: 'center', marginRight: 18 }

    return (
        <div style={containerStyle}>
            <div style={mainTitleBlock}>

                <div style={paragraphs.sectionHeadingTitle}>{title}</div>

                <div style={iconTextSetBlock}>

                    <div
                        style={iconTextBlock}
                        onClick={(event) => clickEventHandler(event, 'INFO')}
                    >
                        <img
                            src={icon_info}
                            alt="INFO"
                            //onClick={(event) => clickEventHandler(event, 'INFO')}
                            style={iconStyle}
                        />
                        {isSmallScreen ? null : (
                            <div style={iconLabelStyle}>
                                Info
                            </div>
                        )}
                    </div>

                    {/* <div
                        style={iconTextBlock}
                        onClick={(event) => clickEventHandler(event, 'SETTINGS')}
                    >
                        <img
                            src={icon_gear}
                            alt="SETTINGS"
                            //onClick={(event) => clickEventHandler(event, 'SETTINGS')}
                            style={iconStyle}
                        />
                        {isSmallScreen ? null : (
                            <Text style={iconLabelStyle}>
                                Settings
                            </Text>
                        )}
                    </div> */}

                </div>
            </div>

            {/* Dropdown snack bars (when clicking the image) */}

            {showInfoSnackBar && (
                <SnackBarSingle
                    title={settings.showInfoSnackBarText}
                    subtitle={infoText}
                    color={colors.info_a02}
                    symbol='info'
                    canBeClosed={true}
                    activeLanguage={activeLanguage}
                    setActiveLanguage={setActiveLanguage}
                />
            )}

            {showSettingsSnackBar && (
                <SnackBarSingle
                    title={settings.showSettingsSnackBarText}
                    subtitle='There are no settings available in this page'
                    color={colors.green2_a02}
                    symbol='gear'
                    canBeClosed={true}
                />
            )}

        </div>
    );
}

export default SectionHeadingToolbar;
