export const generateQuestions = () => {

    const MAX_VALUE = 15;
    const MAX_RESULT = 20;
    const numQuestions = 200; // You can change this as needed.
    const questionList = [];

    for (let i = 0; i < numQuestions; i++) {
        
        let isAddition = Math.random() < 0.5;
        let operand1 = Math.floor(Math.random() * MAX_VALUE);
        let operand2 = Math.floor(Math.random() * MAX_VALUE);

        // Ensure valid subtraction question (operand1 >= operand2)
        if (!isAddition && operand1 < operand2) {
            const temp = operand1;
            operand1 = operand2;
            operand2 = temp;
        }

        let answer;
        let question;
        if (isAddition) {
            answer = operand1 + operand2;
            question = `${operand1} + ${operand2} = _`;
        } else {
            // Ensure the result is not larger than MAX_RESULT
            while (operand1 - operand2 > MAX_RESULT) {
                operand1 = Math.floor(Math.random() * MAX_VALUE);
                operand2 = Math.floor(Math.random() * MAX_VALUE);
            }
            answer = operand1 - operand2;
            question = `${operand1} - ${operand2} = _`;
        }
        // console.log(question, '|', answer)
        questionList.push({ question, answer });
    }

    return questionList;

};
