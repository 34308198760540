// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import idioms from 'locales/idioms_static';

// Basic core functions
import React from 'react';

// Support functions
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

const logo = require('img/icons/homepage_title_image_right_aligned.png');

function DashboardBanner() {

    //Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    //Styles
    const styles = componentStyles(isSmallScreen); // requires isSmallScreen loaded

    const virtualFullWidthLocal = {
        ...virtualFullWidth,
        backgroundColor: colors.gray2
    }

    return (
        <div style={virtualFullWidthLocal}>
            <div style={availableWidth}>

                <div style={styles.bannerMainFlexStyle}                >

                    <div style={styles.bannerLeftFlexStyle}                    >
                        <div style={styles.bannerTitleStyle}                        >
                            {isSmallScreen ? 'Welcome' : 'Welcome to Discito!'}
                        </div>
                        <div style={styles.bannerSubtitleStyle}                        >
                            {isSmallScreen ? 'Enjoy learning!' : 'What are you going to learn now?'}
                        </div>
                    </div>

                    <div>
                        <img
                            src={logo}
                            alt="Logo"
                            style={styles.bannerImageStyle}
                        />
                    </div>

                </div>

            </div>
        </div>
    );
}

export default DashboardBanner;
