export const generateQuestions = () => {

    const MIN_VALUE = 2;
    const MAX_VALUE = 11;
    const numQuestions = 200;
    const questionList = [];

    for (let i = 0; i < numQuestions; i++) {
        let operand1 = Math.floor(Math.random() * (MAX_VALUE - MIN_VALUE)) + MIN_VALUE;
        let operand2 = Math.floor(Math.random() * (MAX_VALUE - MIN_VALUE)) + MIN_VALUE;

        let answer = operand1 * operand2;
        let question = `${operand1} x ${operand2} = _`;

        questionList.push({ question, answer });
    }

    return questionList;

};
