import React, { useState } from 'react';
import {
    Flex,
    Button,
    TextField
} from '@aws-amplify/ui-react';

function NumericKeypad({ setUserInput, nextButtonText = 'Next' }) {
    const [inputValue, setInputValue] = useState('');

    const addToInput = (value) => {
        if (value === '-') {
            setInputValue(inputValue === '-' ? '' : '-');
        } else {
            setInputValue(inputValue + value);
        }
    };

    const clearInput = () => {
        setInputValue('');
    };

    const processInput = () => {
        setUserInput(inputValue);
        setInputValue('');
    };

    const buttonStyle = {
        width: '65px',
        height: '65px',
        fontSize: '1.25rem'
    };

    return (
        <div>
            <Flex
                display="grid"
                gridTemplateColumns="repeat(3, 1fr)"
                padding="8px"
                width="175px"
                height="210px"
                alignItems="center"
                justifyContent="center"
            >
                <div style={{ gridColumn: 'span 3' }}>
                    <TextField
                        value={inputValue}
                        size="large"
                        variation="quiet"
                        readOnly
                    />
                </div>
                <Button variation="primary" style={buttonStyle} onClick={() => addToInput('1')}>1</Button>
                <Button variation="primary" style={buttonStyle} onClick={() => addToInput('2')}>2</Button>
                <Button variation="primary" style={buttonStyle} onClick={() => addToInput('3')}>3</Button>
                <Button variation="primary" style={buttonStyle} onClick={() => addToInput('4')}>4</Button>
                <Button variation="primary" style={buttonStyle} onClick={() => addToInput('5')}>5</Button>
                <Button variation="primary" style={buttonStyle} onClick={() => addToInput('6')}>6</Button>
                <Button variation="primary" style={buttonStyle} onClick={() => addToInput('7')}>7</Button>
                <Button variation="primary" style={buttonStyle} onClick={() => addToInput('8')}>8</Button>
                <Button variation="primary" style={buttonStyle} onClick={() => addToInput('9')}>9</Button>
                <Button variation="primary" style={buttonStyle} onClick={clearInput}>⌫</Button>
                <Button variation="primary" style={buttonStyle} onClick={() => addToInput('0')}>0</Button>
                <Button variation="primary" style={buttonStyle} onClick={() => addToInput('-')}>-</Button>
                <Button variation="primary" style={{ height: 55, gridColumn: 'span 3', fontSize: '1.25rem', marginLeft: 14 }} onClick={processInput}>{nextButtonText}</Button>
            </Flex>
        </div>
    );
}

export default NumericKeypad;
