// Colors, styles etc.
import { componentStyles } from 'components/styles/component_styles';
import { tableStyles } from 'components/styles/table_styles';

// Basic core functions
import React, { useState } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // To handle responsiveness

// Alternative with hasACtionButton that would automatically display the "..." button... 
const SimpleTableComponent = ({ headers, rows, showGrid = true }) => {

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Parmaeters
    const isGridVisible = showGrid = true;

    //Styles
    const styles = componentStyles(isSmallScreen);
    const tables = tableStyles(isSmallScreen, isGridVisible);

    // Exclude empty rows
    const nonEmptyRows = rows.filter(row => Object.values(row).some(value => value !== undefined && value !== ''));

    return (
        <div>
            <table style={tables.tableStyleBasic}>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th style={tables.tableHeaderStyleBasic} key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {nonEmptyRows.map((row, rowIndex) => (
                        <tr key={rowIndex}
                            style={tables.tableCellStyleBasic}
                        >
                            {headers.map((header, colIndex) => (
                                <td key={colIndex}
                                    style={tables.tableCellStyleBasic}
                                >
                                    {row[header]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SimpleTableComponent;