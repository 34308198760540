export function transformSnackBarContent(inputList, maxElements = 2) {
    return inputList.slice(0, maxElements).map(item => {
        return {
            title: item.topic,
            subtitle: item.notification,
            canBeClosed: true,
            symbol: 'bell'
        };
    });
}
