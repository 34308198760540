// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // To handle responsiveness

const PageNavigationBarQuiz = ({ goToReview, goToNext, currentIndex, keysLength, assignedWordsCount }) => {

    //Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    //Styles
    const styles = componentStyles(isSmallScreen);

    // Determine if it's the last page
    const isLastPage = currentIndex === keysLength - 1;
    const isNotFirstPage = currentIndex > 0; // maybe useful

    // Determine whether all words are assigned
    const isNotAllAssigned = assignedWordsCount < 3;

    // Set the style for the 'Next' button depending on the page number
    let nextButtonStyle;
    if (isLastPage || isNotAllAssigned) {
        nextButtonStyle = {
            ...styles.buttonStyleNavigationBar, backgroundColor: colors.gray4, cursor: 'default'
        };
    } else {
        nextButtonStyle = styles.buttonStyleNavigationBar;
    }

    // Set the style for the 'Review' button depending on the page number
    let reviewButtonStyle;
    if (isNotAllAssigned) {
        reviewButtonStyle = { ...styles.buttonStyleNavigationBar, backgroundColor: colors.gray4, cursor: 'default' };
    } else {
        reviewButtonStyle = styles.buttonStyleNavigationBar;
    }

    return (
        <div style={styles.navigationBarStyle}>

            <button
                onClick={goToReview}
                style={reviewButtonStyle}
                disabled={isNotAllAssigned ? true : false}
            >Review</button>

            <div
                style={styles.navigationBarFlexStyle}
            >
                {currentIndex + 1} / {keysLength}
            </div>

            <button
                onClick={goToNext}
                style={nextButtonStyle}
                disabled={isLastPage || isNotAllAssigned ? true : false}
            >Next</button>

        </div >

    );
};

export default PageNavigationBarQuiz;
