import { InterThin } from 'components/styles/fonts/Inter-Thin.ttf'; // fontWeight=100
import { InterExtraLight } from './fonts/Inter-ExtraLight.ttf'; // fontWeight=200
import { InterLight } from './fonts/Inter-Light.ttf'; // fontWeight=300
import { InterRegular } from './fonts/Inter-Regular.ttf'; // fontWeight=400
import { InterMedium } from './fonts/Inter-Medium.ttf'; // fontWeight=500
import { InterSemiBold } from './fonts/Inter-SemiBold.ttf'; // fontWeight=600
import { InterBold } from './fonts/Inter-Bold.ttf'; // fontWeight=700
import { InterExtraBold } from './fonts/Inter-ExtraBold.ttf'; // fontWeight=800
import { InterBlack } from './fonts/Inter-Black.ttf'; // fontWeight=900

import { colors } from 'components/styles/colors';

import { fonts } from 'components/styles/fonts/fonts'

export const textStyles = {

        // Styles desktop

        // Title styles - desktop
        //=============================================================================================================

        title1D:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "48px", // alternative "3rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        title1Dbold:
        {
                //font-related part
                //...fonts.InterThin,
                fontFamily: "Inter",
                fontSize: "48px", // alternative "3rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        title2D:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "42px", // alternative "2.6rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        title2Dbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "42px", // alternative "2.6rem"
                fontWeight: 700, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        title3D:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "36px", // alternative "2.2rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        title3Dbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "36px", // alternative "2.2rem"
                fontWeight: 700, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        // Heading styles - desktop
        //=============================================================================================================

        heading1D:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "32px", // alternative "2.0rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading1Dbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "32px", // alternative "2.0rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading2D:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "28px", // alternative "1.8rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading2Dbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "28px", // alternative "1.8rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading3D:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "24px", // alternative "1.6rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading3Dbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "24px", // alternative "1.6rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading4D:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "22px", // alternative "1.4rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading4Dbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "22px", // alternative "1.4rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading5D:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "20px", // alternative "1.2rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading5Dbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "20px", // alternative "1.2rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        // Body text styles - desktop
        //=============================================================================================================

        bodyTextLargeD:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "18px", // alternative "1.1rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextLargeDbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "18px", // alternative "1.1rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextLargeDitalic:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "18px", // alternative "1.1rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: -10, // = "italic"
                color: colors.dark,
        },

        bodyTextStandardD:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "16px", // alternative "1.0rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextStandardDbold:
        {
                //font-related part
                //fontFamily: "Inter",
                fontSize: "16px", // alternative "1.0rem"
                fontWeight: 900, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextStandardDitalic:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "16px", // alternative "1.0rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: -10, // = "italic"
                color: colors.dark,
        },

        bodyTextSmallD:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "14px", // alternative "0.9rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextSmallDbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "14px", // alternative "0.9rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextSmallDitalic:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "14px", // alternative "0.9rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: -10, // = "italic"
                color: colors.dark,
        },

        bodyTextVerySmallD:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "12px", // alternative "0.8rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextVerySmallDitalic:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "12px", // alternative "0.8rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: -10, // = "italic"
                color: colors.dark,
        },

        bodyTextTinyD:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "11px", // alternative "0.7rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextTinyDialic:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "11px", // alternative "0.7rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: -10, // = "italic"
                color: colors.dark,
        },

        // Other styles - desktop
        //=============================================================================================================

        linkD:
        {
                fontSize: "1rem",
                //        color: colors.blue, // Assuming you have a 'blue' color defined in your 'colors' object
                //        textDecoration: "underline",
        },

        // Styles mobile

        // Title styles - mobile
        //=============================================================================================================

        title1M:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "24px", // alternative "3rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        title1Mbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "24px", // alternative "3rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        title2M:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "20px", // alternative "2.6rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        title2Mbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "20px", // alternative "2.6rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        title3M:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "28px", // alternative "2.2rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        title3Mbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "28px", // alternative "2.2rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        // Heading styles - mobile
        //=============================================================================================================

        heading1M:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "24px", // alternative "2.0rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading1Mbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "24px", // alternative "2.0rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading2M:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "22px", // alternative "1.8rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading2Mbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "22px", // alternative "1.8rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading3M:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "20px", // alternative "1.6rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading3Mbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "20px", // alternative "1.6rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading4M:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "18px", // alternative "1.4rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading4Mbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "18px", // alternative "1.4rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading5M:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "16px", // alternative "1.2rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        heading5Mbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "16px", // alternative "1.2rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        // Body text styles - Mobile
        //=============================================================================================================

        bodyTextLargeM:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "15px", // alternative "1.1rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextLargeMbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "15px", // alternative "1.1rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextLargeMitalic:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "15px", // alternative "1.1rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: -10, // = "italic"
                color: colors.dark,
        },

        bodyTextStandardM:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "14px", // alternative "1.0rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextStandardMbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "14px", // alternative "1.0rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextStandardMitalic:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "14px", // alternative "1.0rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: -10, // = "italic"
                color: colors.dark,
        },

        bodyTextSmallM:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "12px", // alternative "0.9rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextSmallMbold:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "12px", // alternative "0.9rem"
                fontWeight: 700, // = "bold" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextSmallMitalic:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "12px", // alternative "0.9rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: -10, // = "italic"
                color: colors.dark,
        },

        bodyTextVerySmallM:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "11px", // alternative "0.8rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextVerySmallMitalic:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "11px", // alternative "0.8rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: -10, // = "italic"
                color: colors.dark,
        },

        bodyTextTinyM:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "10px", // alternative "0.7rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: 0, // = "regular"
                color: colors.dark,
        },

        bodyTextTinyMitalic:
        {
                //font-related part
                fontFamily: "Inter",
                fontSize: "10px", // alternative "0.7rem"
                fontWeight: 400, // = "regular" (t.b.d.)
                fontSlant: -10, // = "italic"
                color: colors.dark,
        },

        // Other styles - mobile
        //=============================================================================================================

        linkM:
        {
                fontSize: "1rem",
                //        color: colors.blue, // Assuming you have a 'blue' color defined in your 'colors' object
                //        textDecoration: "underline",
        },

};
