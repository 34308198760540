// Following AWS language id conventions
//
// en: English
// cz: Czech

const idioms = {

    'en': {
        // Language instructions
        language_instructions: {
            word_overview: 'Click on the arrows next to each word to see the definition, synonyms and one example of this word in a sentence. Click on the word to hear it aloud. Click on the sample sentence in italics to hear the whole sentence aloud too. In mobile version, swipe to the right to see Czech translations of the word, its definition, and synonyms, and swipe left to see English again. Sounds are only available in English.',
            learning_cards: 'In the first card there is the word together with the definition and synonyms. The second card shows an image characterizing the word in a sentence that is below. The third card shows sample sentences where the word is used. Click on the word or the image to hear the word aloud. Click on the sentence below the picture to hear the sentence. There are many examples of sentences available so you can see different sentences each time. Navigate between the cards through buttons or swiping (mobile version only).',
            spelling_test: 'Prepare a piece of paper and a pencil. After clicking on "Start", you will hear a series of words from the list. Write down the words you hear. At the end of the exercise, compare your list with the original, check your spelling and mark the correct answers. Before starting, you can adjust the speed and the number of times each word is repeated as needed. In the evaluation, based on your results, click on the individual words on the list. Click once to mark the answer as correct. Click twice to mark the answer as incorrect. Check your result and score below.',
            practice_cards: 'There are three examples of sentences with a word missing. The same word is missing in each sentence. Select the correct word from the list below and see your result immediately. Click the Review button to review your answers. When reviewing, check the original sentences by clicking on the respective row in the table.',
            vocabulary_test: 'Match sentences with missing words with the correct word from the list below. Click on a sentence to select it and fill it in by choosing from the available options. Check your result immediately after filling all gaps on each page. There are multiple sets with three sentences per set. Click Review if you want to see your results in detail. In the review part, click on a row in a table to see the original sentence.',
            vocabulary_exam: 'Match sentences with missing words with the correct word from the list below. Click on a sentence to select it and fill it in by choosing from the available options. Check your result immediately after filling in all the gaps on each page. All words are displayed at the same time. Click review if you want to see your results in detail. In the review part, click on a row in a table to see the original sentence.',
            word_variations: 'There is a sentence with a word missing. Select and fill in the word in the proper form from the list below and see your result immediately. Review all your answers after clicking on the Review button. When reviewing, you can see the original sentences by clicking on the respective row in the table.',
            prepositions_quiz: 'Select and fill in the right preposition from the list below. Select your answer and you will see your result immediately. You can review all your answers after clicking on the Review button. When reviewing, you can see the original sentences by clicking on the respective row in the table.',
        },
        // User management instructions
        user_management_instructions: {
            change_password: '...instructions to be provided',
        },
        // What to do if...?
        what_to_do_if_title: 'What to do if...?',
        what_to_do_if_issues: {
            '1': {
                title: 'I have a problem with logging into the application',
                body: 'We have designed the login experience to be as simple as possible while being secure.~If you are facing problems with login, please make sure that you enter the username and password correctly.Both username and password are case sensitive, and it is important to pay attention for example to your keyboard(EN) or kezboard(CZ), typing O(capital o) or 0(zero) and so on.~When copying the username and/ or password, please also mind potential spaces at the end of the word.~After more than 3 unsuccessful attempts, the system temporarily locks you out.But you can try it again in 15 minutes.'
            },
            '2': {
                title: 'I forgot my login credentials (username and/or password)',
                body: 'You need to contact your teacher who gave you your login credentials (username and password). This person is the only one who knows your identity in the system and who can either give you your original set of credentials or generate/assign a new one. In order to be able to reset the password, we need to know your username – so please share it with us or with the teacher.'
            },
            '3': {
                title: 'I have come across an error or a bug',
                body: 'Our application is in a research phase so these things may happen from time to time - despite all our effort to prevent this.  Please let us know and tell us what went wrong, ideally with some description (telling us what you were doing) and some pictures (screenshots) with the error, including the error message. You can contact us via email info@discito.cz'
            },
            '4': {
                title: 'I have a suggestion for improvement',
                body: 'All your ideas and feedback are always welcome. We are building the application for you and your benefit. Please share your ideas and your thoughts with us and we will do our best to implement them. You can contact us by simply sending us an email to info@discito.cz'
            },
        },
        // FAQ
        faq_title: 'Frequently asked questions',
        faq_issues: {
            '1': {
                title: 'What is the purpose of the application?',
                body: 'The purpose of the application is to facilitate learning and help students learn and practice anytime and anywhere - just with their mobile phone, tablet or laptop-desktop and internet connection. We believe that frequent practice leads to better results by improving speed and accuracy of answers. At the moment, the application is in a research phase where we collect first feedback from real users.'
            },
            '2': {
                title: 'How do I get the login credentials (username and password)?',
                body: 'At the moment, we are in a research phase of the project, and it is accessible by invitation only. Your credentials can be given to you by your teacher who assigns you the username and the password.'
            },
            '3': {
                title: 'How do I log into the application?',
                body: 'You can log into the application at www.discito.cz using your login credentials - username and password. At this point, the access to the application is on invitation only. You should receive your login credentials from your teacher.'
            },
            '4': {
                title: 'How to keep the application up to date?',
                body: 'We are constantly developing and enhancing the application, so it changes over time. To prevent errors and ensure great user experience, you will need to refresh (re-load) your application from time to time - simply to keep it up to date with all the features and the content. Whenever this refresh is needed, you receive a notification on your screen. Many minor errors or bugs can also be solved by simply re-loading the application. But in general, you can keep your application up to date by refreshing (re-loading) the page regularly.'
            },
            '5': {
                title: 'Is my personal (PII) data being tracked and stored?',
                body: 'Since we have no information about your identity, we have no possibility to identify you as a person. This means that we do not track or store any personal identifiable data (PII data).'
            },
            '6': {
                title: 'Are my interactions with the application being tracked?',
                body: 'To be able to provide you with useful insights about your activities and your progress - and also to understand your preferences and optimize user experience and performance of the application - we track your behavior within the application, and we store this information in anonymized and aggregated form.'
            },
            '7': {
                title: 'Does anyone else see my results?',
                body: 'No one else can see your results and know that these results belong to you. All results are only presented in strictly anonymized and aggregated form. The same way as you as you can see the results of others, others can see your results - without the link to the concrete person.'
            },
        },
    },
    'cz': {
        // Language instructions
        language_instructions: {
            word_overview: 'Kliknutím na šipky vedle každého slova zobrazíte jeho definici, synonyma a jeden příklad tohoto slova ve větě. Kliknutím na slovo jej uslyšíte nahlas. Kliknutím na ukázkovou větu uvedenou kurzívou si poslechnete nahlas celou větu. V mobilní verzi přejetím prstem doprava zobrazíte české překlady slova, jeho definice a synonym. Přejetím doleva zobrazíte opět angličtinu. Zvuky jsou k dispozici pouze v angličtině.',
            learning_cards: 'Na první kartě je slovo spolu s definicí a synonymy. Druhá karta zobrazuje obrázek charakterizující slovo ve větě, která je níže. Na třetí kartě jsou ukázky vět, kde je slovo použito. Kliknutím na slovo nebo obrázek uslyšíte slovo nahlas. Kliknutím na větu pod obrázkem si poslechnete celou větu. K dispozici je mnoho příkladů vět, takže pokaždé můžete vidět jiné věty. Mezi kartami můžete procházet pomocí tlačítek nebo (pouze v mobilní verzi) přejetím prstem doprava a doleva.',
            spelling_test: 'Připravte si papír a tužku. Po kliknutí na "Start" uslyšíte řadu slov ze seznamu. Zapište si slova, která slyšíte. Na konci cvičení porovnejte svůj seznam s originálem, zkontrolujte pravopis a označte správné odpovědi. Před spuštěním můžete podle potřeby upravit rychlost a počet opakování každého slova. V hodnocení na základě vašich výsledků klikněte na jednotlivá slova v seznamu. Jedním kliknutím označte odpověď jako správnou. Kliknutím dvakrát označíte odpověď jako nesprávnou. Zkontrolujte svůj výsledek a skóre níže.',
            practice_cards: 'Uvidíte tři příklady vět, ve kterých chybí slovo. Ve všech větách chybí stejné slovo. Vyberte správné slovo ze seznamu níže a okamžitě uvidíte svůj výsledek. Kliknutím na tlačítko Zkontrolovat zkontrolujte své odpovědi. Při kontrole můžete zobrazit původní věty kliknutím na příslušný řádek v tabulce.',
            vocabulary_test: 'Doplňte chybějící slova ve větách slovy ze seznamu níže. Kliknutím na větu ji vyberte a doplňte chybějící slovo kliknutím na jednu z dostupných možností. Zkontrolujte výsledek ihned po vyplnění všech mezer na každé stránce. Existuje více sad se třemi větami na sadu. Chcete-li zobrazit podrobné výsledky, klikněte na tlačítko Zkontrolovat. Při kontrole můžete zobrazit původní věty kliknutím na příslušný řádek v tabulce.',
            vocabulary_exam: 'Doplňte chybějící slova ve větách slovy ze seznamu níže. Kliknutím na větu ji vyberte a doplňte chybějící slovo ji výběrem z dostupných možností. Zkontrolujte svůj výsledek ihned po vyplnění všech chybějících slov na každé stránce. Všechna slova jsou zobrazena najednou. Chcete-li zobrazit podrobné výsledky, klikněte na tlačítko Zkontrolovat. Při kontrole můžete zobrazit původní věty kliknutím na příslušný řádek v tabulce.',
            word_variations: 'Vyberte a doplňte chybějící slovo ve větě ve správném tvaru ze seznamu níže a okamžitě uvidíte svůj výsledek. Zkontrolujte všechny své odpovědi po kliknutí na tlačítko Zkontrolovat. Při kontrole můžete zobrazit původní věty kliknutím na příslušný řádek v tabulce.',
            prepositions_quiz: 'Do věty, kterou vidíte, vyberte a vyplňte správnou předložku ze seznamu níže a okamžitě uvidíte svůj výsledek. Všechny své odpovědi si můžete prohlédnout po kliknutí na tlačítko Zkontrolovat. Při kontrole můžete zobrazit původní věty kliknutím na příslušný řádek v tabulce.',
        },
        // User management instructions
        user_management_instructions: {
            change_password: '...instrukce budou uvedeny',

        },
        // What to do if...?
        what_to_do_if_title: 'Co dělat když...?',
        what_to_do_if_issues: {
            '1': {
                title: 'Mám problém s přihlášením do aplikace',
                body: 'Přihlašování jsme navrhli tak, aby bylo co nejjednodušší a zároveň bezpečné.~Pokud se potýkáte s problémy s přihlášením, ujistěte se, že zadáváte uživatelské jméno a heslo správně.U uživatelského jména i hesla se rozlišují velká a malá písmena a je důležité věnovat pozornost například klávesnici(anglická nebo česká, záměna z a y) nebo také psaní O(velké o) nebo 0(nula) a tak podobně.~Při kopírování uživatelského jména a/ nebo hesla dbejte také na případné mezery na konci slova.~Po více než 3 neúspěšných pokusech vás systém dočasně zablokuje.Ale můžete to zkusit znovu za 15 minut.'
            },
            '2': {
                title: 'Zapomněl(a) jsem své přihlašovací údaje (uživatelské jméno anebo heslo)',
                body: 'Musíte kontaktovat svého učitele, který vám dal vaše přihlašovací údaje (uživatelské jméno a heslo). Tato osoba je jediná, kdo zná vaši identitu v systému a může vám poskytnout vaši původní sadu přihlašovacích údajů nebo vygenerovat/poskytnout nové údaje. Abychom byli schopni vám resetovat heslo, potřebujeme znát vaše uživatelské jméno – prosím, nasdílejte ho z námi nebo s učitelem.'
            },
            '3': {
                title: 'Narazil(a) jsem na problém nebo na chybu',
                body: 'Naše aplikace je ve fázi výzkumu, takže k těmto věcem může čas od času dojít – navzdory veškeré naší snaze tomu zabránit. Dejte nám prosím vědět a řekněte nám, co se pokazilo, ideálně s popisem (řekněte nám, co jste dělali) a několika obrázky (snímky obrazovky) s chybou, včetně chybové zprávy. Kontaktovat nás můžete také prostřednictvím našeho emailu info@discito.cz'
            },
            '4': {
                title: 'Mám nápad na vylepšení',
                body: 'Všechny vaše nápady a zpětná vazba jsou vždy vítány. Aplikaci vytváříme pro vás a váš prospěch. Podělte se s námi o své nápady a myšlenky a my uděláme maximum pro jejich realizaci. Můžete nám jednoduše poslat email na info@discito.cz'
            },
        },
        // FAQ
        faq_title: 'Často kladené otázky',
        faq_issues: {
            '1': {
                title: 'Co je cílem aplikace?',
                body: 'Účelem aplikace je usnadnit učení a pomoci studentům učit se a procvičovat kdykoli a kdekoli – pouze s mobilním telefonem, tabletem nebo notebookem (počítačem) a připojením k internetu. Věříme, že časté procvičování vede k lepším výsledkům prostřednictvím zlepšení rychlosti a přesnosti odpovědí. V tuto chvíli je aplikace ve fázi výzkumu, kdy sbíráme první zpětnou vazbu od reálných uživatelů.'
            },
            '2': {
                title: 'Jak získám přístupové údaje (uživatelské jméno a heslo)?',
                body: 'V tuto chvíli je projet ve fázi výzkumu, resp. ve zkušební fázi a je přístupný pouze na pozvání. Vaše přihlašovací údaje vám může předat váš učitel, který vám přidělí uživatelské jméno a heslo.'
            },
            '3': {
                title: 'Jak se přihlásím do aplikace?',
                body: 'Do aplikace se můžete přihlásit na www.discito.cz pomocí svých přihlašovacích údajů – uživatelského jména a hesla. V tomto okamžiku je přístup do aplikace pouze na pozvání. Své přihlašovací údaje byste měli obdržet od svého učitele.'
            },
            '4': {
                title: 'Jak zajistím, že aplikace bude vždy aktuální?',
                body: 'Aplikaci neustále vyvíjíme a vylepšujeme, takže se časem mění. Abyste předešli chybám a zajistili si skvělou uživatelskou zkušenost, budete muset svou aplikaci čas od času aktualizovat (znovu načíst) – jednoduše, abyste ji udržovali aktuální se všemi funkcemi a obsahem. Kdykoli je toto obnovení potřeba, zobrazí se na obrazovce upozornění. Mnoho drobných chyb nebo chyb lze také vyřešit jednoduchým opětovným načtením aplikace. Obecně však můžete svou aplikaci udržovat aktuální, ale stránku pravidelně obnovovat (znovu načítat).'
            },
            '5': {
                title: 'Jsou moje osobní údaje sledovány a ukládány?',
                body: 'Protože nemáme žádné informace o vaší totožnosti, nemáme možnost vás identifikovat jako osobu. To znamená, že nesledujeme ani neukládáme žádné osobní údaje (PII data).'
            },
            '6': {
                title: 'Je moje interakce a aplikací sledována a ukládána?',
                body: 'Abychom vám mohli poskytnout užitečné informace o vašich aktivitách a vašem pokroku – a také abychom porozuměli vašim preferencím a optimalizovali uživatelskou zkušenost a výkon aplikace – sledujeme vaše chování v rámci aplikace a tyto informace ukládáme v anonymizované a agregované podobě.'
            },
            '7': {
                title: 'Vidí mé výsledky i někdo jiný, než já?',
                body: 'Nikdo jiný nemůže vidět vaše výsledky a vědět, že tyto výsledky patří vám. Všechny výsledky jsou prezentovány pouze v přísně anonymizované a agregované podobě. Stejně jako vy můžete vidět výsledky ostatních, ostatní mohou vidět vaše výsledky – bez odkazu na konkrétní osobu.'
            },
        },
    },
};

export default idioms;
