// Colors, styles etc.
import { colors } from 'components/styles/colors';

const ComponentStyles = (isSmallScreen, isHovered, isDisabled) => {

    return {

        // Styles

        //SectionHeading styles

        sectionHeadingMainDivStyle:
        {
            position: 'relative',
            marginTop: isSmallScreen ? 24 : 32,
            marginBottom: isSmallScreen ? 14 : 18,
            backgroundcolor: colors.gray6,
        },

        sectionHeadingMainFlexStyle:
        {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundcolor: colors.gray6
        },

        sectionHeadingIconStyle:
        {
            maxWidth: 120,
            marginRight: isSmallScreen ? 12 : 18,
            display: 'flex',
            justifyContent: 'center',
            position: 'relative'
        },

        sectionHeadingInputStyle:
        {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'fill',
            borderRadius: 100,
            top: isSmallScreen ? 8 : 12,
            right: isSmallScreen ? 8 : 12,
            padding: isSmallScreen ? 4 : 6,
            border: '1px solid',
            borderColor: colors.gray4,
            backgroundColor: colors.light, // Light gray background

            textAlign: 'center',
            fontSize: isSmallScreen ? 14 : 18,
        },

        //TagList styles

        tagListMainDivStyle:
        {
            position: 'absolute',
            display: 'flex',
            top: isSmallScreen ? 8 : 12,
            right: isSmallScreen ? 8 : 12
        },

        tagListDivStyle:
        {
            marginRight: 0,
            marginLeft: isSmallScreen ? 8 : 12,
            padding: isSmallScreen ? 4 : 6,
            color: colors.white,
            borderRadius: isSmallScreen ? 18 : 24,
            fontSize: isSmallScreen ? 12 : 14,
            fontWeight: 700,
            zIndex: '1',
            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
        },

        //Banner styles

        bannerMainFlexStyle:
        {
            display: 'flex',
            flexDirection: 'row',
            gap: 0,
            padding: isSmallScreen ? 14 : 18,
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            backgroundColor: colors.gray2,
        },

        bannerLeftFlexStyle:
        {
            display: 'flex',
            flexDirection: 'column',
            gap: isSmallScreen ? 12 : 18,
            padding: 0,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            position: 'relative',
            backgroundColor: colors.gray2,
        },

        bannerImageStyle:
        {
            maxHeight: isSmallScreen ? '72px' : '96px',
            maxWidth: isSmallScreen ? '72px' : '96px',
            width: 'auto',
            height: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: isSmallScreen ? '14px' : '18px',
            marginBottom: isSmallScreen ? '14px' : '18px',
        },

        bannerTitleStyle:
        {
            fontSize: isSmallScreen ? 36 : 48,
            fontWeight: 700,
            textAlign: 'left',
            color: colors.amber,
            padding: 0,
            marginTop: isSmallScreen ? 14 : 18,
            marginBottom: 0,
            display: 'block',
            position: 'relative',
            lineHeight: 1,
        },

        bannerSubtitleStyle:
        {
            fontSize: isSmallScreen ? 24 : 32,
            fontWeight: 700,
            textAlign: 'left',
            color: colors.white,
            padding: 0,
            marginTop: 0,
            marginBottom: isSmallScreen ? 14 : 18,
            display: 'block',
            position: 'relative',
            lineHeight: 1,

        },

        //Footer styles

        footerMainFlexStyle:
        {
            display: 'flex',
            flexDirection: 'row',
            gap: 0,
            padding: isSmallScreen ? 8 : 12,
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            backgroundColor: colors.gray2,
        },

        footerLeftFlexStyle:
        {
            display: 'flex',
            flexDirection: 'column',
            gap: isSmallScreen ? 6 : 9,
            padding: 0,
            textAlign: 'left',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            position: 'relative',
            top: '50%',
            backgroundColor: colors.gray2,
        },

        footerRightFlexStyle:
        {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            gap: isSmallScreen ? 9 : 12,
            padding: 0,
            justifyContent: 'left',
            alignItems: 'flex-start',
        },

        footerIconStyle:
        {
            height: isSmallScreen ? 22 : 26,
            width: isSmallScreen ? 22 : 26,
        },

        footerTextStyle:
        {
            color: colors.white,
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            marginLeft: 9,
            cursor: 'pointer',
        },

        //Pagetitle styles

        pageTitleFlexStyle1:
        {
            gap: isSmallScreen ? "12px" : "18px",
            direction: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            position: "relative",
            backgroundColor: colors.gray5,
            margin: 0,
            paddingLeft: isSmallScreen ? 12 : 18,
            paddingRight: isSmallScreen ? 12 : 18,
            paddingTop: isSmallScreen ? 6 : 9,
            paddingBottom: isSmallScreen ? 6 : 9,
        },

        pageTitleFlexStyle2:
        {
            gap: isSmallScreen ? 8 : 12,
            direction: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            position: "relative",
            backgroundColor: colors.gray5,
            margin: 0,
            padding: 0,
        },

        //MsgBox styles
        msgBoxStyle:
        {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: colors.gray6,
            padding: isSmallScreen ? 14 : 18,
            borderRadius: isSmallScreen ? 6 : 9,
            zIndex: 100,
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
        },

        msgBoxCaptionStyle:
            {},

        msgBoxTextStyle:
            {},

        msgBoxButtonStyle:
        {
            textAlign: 'center', // Align text to the left
            fontSize: isSmallScreen ? 14 : 18,
            color: colors.light,
            cursor: 'pointer',
            marginLeft: isSmallScreen ? 6 : 9,
            marginRight: isSmallScreen ? 6 : 9,
            marginTop: isSmallScreen ? 10 : 14,
            marginBottom: isSmallScreen ? 10 : 14,
            padding: isSmallScreen ? '4px 18px' : '6px 24px',
            backgroundColor: colors.primary,
            borderRadius: 100, //needs to be big enough to look like a pill...
            border: 'none'
        },

        //baseButtonStyles - navbar
        baseButtonStyles:
        {
            position: 'relative',
            color: colors.light,
            backgroundColor: colors.amber,
            fontSize: isSmallScreen ? '18px' : '24px',
            fontWeight: '700',
            padding: '4px',
            margin: '0px',
            height: isSmallScreen ? '18px' : '24px',
            cursor: 'pointer',
            //'&:lastChild': { marginRight: '0', },
            //'&:hover': { fontWeight: 'bold', },
        },

        //navbarUserNameText
        navbarUserNameText:
        {
            position: 'relative',
            margin: '0px',
            padding: '4px',
            color: colors.light,
            background: 'transparent',
            textAlign: 'right',
            zIndex: 2, // Keep the z-index of the text banner
            marginRight: isSmallScreen ? '6px' : '12px',
            fontSize: isSmallScreen ? '14px' : '18px',
            fontWeight: '700',
        },

        //navbarDiscitoText
        navbarDiscitoNameText:
        {
            color: colors.light,
            //marginLeft: isSmallScreen ? '12px' : '18px',
            fontSize: isSmallScreen ? '18px' : '24px',
            fontWeight: '700',
            cursor: 'pointer',
        },

        navbarImageButtonStyles:
        {
            height: isSmallScreen ? '28px' : '32px',
            width: isSmallScreen ? '28px' : '32px',
            cursor: 'pointer',
            padding: '0px',
            margin: '0px',
            zIndex: 3, // places image buttons above text  
        },

        navbarStyles:
        {
            backgroundColor: colors.gray1,
            color: colors.light,
            padding: isSmallScreen ? '8px 12px 8px' : '12px 18px 12px',
            margin: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },

        navbarLeftButtonContainerStyles:
        {
            display: 'flex',
            direction: 'row',
            alignItems: 'left', //center
            marginLeft: `calc(max((100vw - 1120px) / 2, 0px))`,
        },

        navbarRightButtonContainerStyles:
        {
            display: 'flex',
            direction: 'row',
            alignItems: 'right', //center
            marginRight: `calc(max((100vw - 1120px) / 2, 0px))`,
        },

        navbarFlexStyle:
        {
            display: 'flex',
            direction: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "relative",
            gap: isSmallScreen ? 8 : 12,
        },

        // Main div for the entire page
        mainDivStyle:
        {
            fontSize: isSmallScreen ? 14 : 18,
            borderRadius: isSmallScreen ? 6 : 9,
            marginLeft: isSmallScreen ? 14 : 18,
            marginRight: isSmallScreen ? 14 : 18,
            marginTop: isSmallScreen ? 10 : 14,
            marginBottom: isSmallScreen ? 24 : 48,
            paddingTop: 10,
            paddingBottom: 10,
        },

        //Grid style for the main tiles
        tileGridStyle:
        {
            display: 'grid',
            gap: isSmallScreen ? 18 : 24,
            margin: isSmallScreen ? 12 : 18,
            borderRadius: isSmallScreen ? 6 : 9,
            justifyContent: 'center',
            alignItems: 'center',
            //This is a problematic part... needs to be repeated for some reason...
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            '@media (minWidth: 769px)': {
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 330px))',
            },
        },

        //Card square style
        squareStyle:
        {
            //this needs to be repeated in the main file and cannot be here...
            //backgroundColor: colors.backgroundColor,
            padding: '0px',
            position: 'relative',
            borderRadius: isSmallScreen ? 6 : 9,
            overflow: 'hidden',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.15)',
            transition: 'transform 0.3s',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
            height: '0',
            paddingBottom: isSmallScreen ? '50%' : '50%',
            fontSize: '200%',
            //this needs to be repeated in the main file...
            cursor: isDisabled ? 'default' : 'pointer',
            opacity: isDisabled ? 0.5 : 1,
        },

        //Tile style
        tileStyle:
        {
            position: 'absolute',
            bottom: '0',  // Align to the bottom
            left: '0',    // Align to the left
            width: '100%', // Take the full width of the container
            textAlign: 'left', // Align text to the left
            padding: '10px',   // Add padding as needed               
        },

        //Tile title style
        tileTitleStyle:
        {
            color: 'black',
            fontSize: isSmallScreen ? 28 : 32,
            fontWeight: 700,
            lineHeight: '1.0',
            marginBottom: isSmallScreen ? 4 : 6,
        },

        //Tile subtitle style
        tileSubtitleStyle:
        {
            color: 'black',
            fontSize: isSmallScreen ? 24 : 28,
            fontWeight: 400,
            lineHeight: '1.0',
            marginBottom: isSmallScreen ? 8 : 12,
        },

        //Headline bar
        captionLabelStyle:
        {
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 700,
            textAlign: 'center',
            backgroundColor: colors.gray6,
            padding: isSmallScreen ? 14 : 18,
            borderRadius: isSmallScreen ? 6 : 9,
            marginTop: isSmallScreen ? 14 : 18,
            marginBottom: isSmallScreen ? 14 : 18,
        },

        // Simple text bar
        simpleBarStyle:
        {
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 700,
            textAlign: 'center',
            //backgroundColor: colors.white,
            padding: isSmallScreen ? 4 : 6,
            marginTop: isSmallScreen ? 4 : 6,
            marginBottom: isSmallScreen ? 4 : 6,
        },

        simpleTextStyle:
        {
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            textAlign: 'center',
            padding: isSmallScreen ? 4 : 6,
            marginTop: 0,
            marginBottom: isSmallScreen ? 14 : 18,
        },

        simpleTextStyleFlex:
        {
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            textAlign: 'center',
            padding: isSmallScreen ? 4 : 6,
            marginTop: 0,
            marginBottom: isSmallScreen ? 4 : 6,
            gap: isSmallScreen ? 0 : 16,
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },

        simpleTextStyleFlexML1MR1:
        {
            //...ComponentStyles.simpleTextStyleFlex,
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            textAlign: 'center',
            padding: isSmallScreen ? 4 : 6,
            marginTop: 0,
            marginBottom: isSmallScreen ? 4 : 6,
            gap: isSmallScreen ? 0 : 16,
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            //...update
            marginRight: "1%",
            marginLeft: "1%",
        },

        simpleTextStyleFlex8020:
        {
            //...ComponentStyles.simpleTextStyleFlex,
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            textAlign: 'center',
            padding: isSmallScreen ? 4 : 6,
            marginTop: 0,
            marginBottom: isSmallScreen ? 4 : 6,
            gap: isSmallScreen ? 0 : 16,
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            //...update
            width: isSmallScreen ? '80%' : '20%',
            textAlign: isSmallScreen ? 'center' : 'left',
            justifyContent: isSmallScreen ? 'center' : 'left',
            alignItems: 'center',
        },

        simpleTextStyleFlexML1MR1Alignment:
        {
            //...ComponentStyles.simpleTextStyleFlex,
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            textAlign: 'center',
            padding: isSmallScreen ? 4 : 6,
            marginTop: 0,
            marginBottom: isSmallScreen ? 4 : 6,
            gap: isSmallScreen ? 0 : 16,
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            //...update
            marginRight: "1%",
            marginLeft: "1%",
            marginTop: isSmallScreen ? 24 : 18,
            justifyContent: 'center',
            verticalAlign: 'center',
        },

        simpleTextStyleML1MR1forTable:
        {
            //...ComponentStyles.simpleTextStyle,
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            textAlign: 'center',
            padding: isSmallScreen ? 4 : 6,
            marginTop: 0,
            marginBottom: isSmallScreen ? 14 : 18,
            //...update
            marginRight: "1%",
            marginLeft: "1%",
            justifyContent: 'center',
            verticalAlign: 'center',
        },

        simpleTextStyleML1MR1forLabel:
        {
            //...ComponentStyles.simpleTextStyle,
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            textAlign: 'center',
            padding: isSmallScreen ? 4 : 6,
            marginTop: 0,
            marginBottom: isSmallScreen ? 6 : 9,
            //...update
            marginRight: "1%",
            marginLeft: "1%",
            textAlign: isSmallScreen ? 'center' : 'left',
            justifyContent: isSmallScreen ? 'center' : 'left',
        },

        supportingTextStyle:
        {
            display: 'flex',
            flexDirection: 'column',
            fontSize: isSmallScreen ? 12 : 14,
            fontWeight: 400,
            fontStyle: 'italic',  // Use fontStyle for italic text
            textAlign: 'center',
            alignItems: 'center', // Center horizontally
            //marginBottom: isSmallScreen ? 9 : 12,
            marginTop: isSmallScreen ? 4 : 6,
            marginBottom: isSmallScreen ? 14 : 18,
        },

        simpleSelectStyle:
        {
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            textAlign: 'center',
            padding: isSmallScreen ? 4 : 6,
            paddingLeft: isSmallScreen ? 18 : 24,
            paddingRight: isSmallScreen ? 18 : 24,
            marginTop: 0,
            border: '1px solid',
            borderColor: colors.gray3,
            backgroundColor: colors.gray6,
            borderRadius: 100,
            width: '80%',
            cursor: 'pointer', // Change cursor on hover
            outline: 'none', // Remove the default focus outline
        },

        simpleSelectStyle80pct:
        {
            //...ComponentStyles.simpleSelectStyle,
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            textAlign: 'center',
            padding: isSmallScreen ? 4 : 6,
            paddingLeft: isSmallScreen ? 18 : 24,
            paddingRight: isSmallScreen ? 18 : 24,
            marginTop: 0,
            border: '1px solid',
            borderColor: colors.gray3,
            backgroundColor: colors.gray6,
            borderRadius: 100,
            width: '80%',
            cursor: 'pointer', // Change cursor on hover
            outline: 'none', // Remove the default focus outline
            //...update
            width: '80%',
            textAlign: isSmallScreen ? 'center' : 'left',
            alignItems: 'center',
        },

        selectedSentenceStyle:
        {
            borderRadius: isSmallScreen ? 6 : 9,
            backgroundColor: colors.gray6,
            marginBottom: isSmallScreen ? 12 : 14,
            marginTop: isSmallScreen ? 12 : 14,
        },

        unselectedSentenceStyle:
        {
            borderRadius: isSmallScreen ? 6 : 9,
            backgroundColor: colors.white,
            marginBottom: isSmallScreen ? 12 : 14,
            marginTop: isSmallScreen ? 12 : 14,
        },

        underscoreReplacementStyle:
        {
            margin: 0,
            padding: 0,
        },

        // Button style basic
        buttonStyleCore:
        {
            textAlign: 'center', // Align text to the left
            fontSize: isSmallScreen ? 14 : 18,
            color: colors.light,
            cursor: 'pointer',
            marginLeft: isSmallScreen ? 14 : 18,
            marginRight: isSmallScreen ? 14 : 18,
            marginTop: isSmallScreen ? 10 : 14,
            marginBottom: isSmallScreen ? 10 : 14,
            padding: isSmallScreen ? '4px 18px' : '6px 24px',
            backgroundColor: colors.primary,
            borderRadius: 100, //needs to be big enough to look like a pill...
            border: `3px solid ${colors.primary}`,
        },

        buttonStyleCore20pct:
        {
            textAlign: 'center', // Align text to the left
            fontSize: isSmallScreen ? 14 : 18,
            color: colors.light,
            cursor: 'pointer',
            marginLeft: isSmallScreen ? 14 : 18,
            marginRight: isSmallScreen ? 14 : 18,
            marginTop: isSmallScreen ? 10 : 14,
            marginBottom: isSmallScreen ? 10 : 14,
            padding: isSmallScreen ? '4px 18px' : '6px 24px',
            backgroundColor: colors.primary,
            borderRadius: 100, //needs to be big enough to look like a pill...
            border: `3px solid ${colors.primary}`,
            ///.update
            width: '20%',
            marginRight: isSmallScreen ? 14 : 0,
        },

        // Button style basic
        buttonStyleInactive:
        {
            textAlign: 'center', // Align text to the left
            fontSize: isSmallScreen ? 14 : 18,
            color: colors.dark,
            cursor: 'pointer',
            marginLeft: isSmallScreen ? 14 : 18,
            marginRight: isSmallScreen ? 14 : 18,
            marginTop: isSmallScreen ? 10 : 14,
            marginBottom: isSmallScreen ? 10 : 14,
            padding: isSmallScreen ? '4px 18px' : '6px 24px',
            backgroundColor: isHovered ? colors.gray6 : colors.light,
            border: `3px solid ${colors.primary}`,
            borderRadius: 100, //needs to be big enough to look like a pill..        
        },

        // Button style basic
        buttonStyleLink:
        {
            textAlign: 'center', // Align text to the left
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: '400',
            color: colors.primary,
            cursor: 'pointer',
            marginLeft: isSmallScreen ? 4 : 8,
            marginRight: isSmallScreen ? 4 : 8,
            marginTop: isSmallScreen ? 4 : 6,
            marginBottom: isSmallScreen ? 4 : 6,
            padding: isSmallScreen ? '4px 8px' : '6px 14px',
            backgroundColor: isHovered ? colors.gray6 : colors.light,
            border: 'none',
            borderRadius: 100, //needs to be big enough to look like a pill..        
        },

        // Button style in navigation bars
        buttonStyleNavigationBar:
        {
            textAlign: 'center', // Align text to the left
            fontSize: isSmallScreen ? 14 : 18,
            color: colors.light,
            cursor: 'pointer',
            //marginLeft: isSmallScreen ? 14 : 18,
            //marginRight: isSmallScreen ? 14 : 18,
            marginTop: isSmallScreen ? 10 : 14,
            marginBottom: isSmallScreen ? 10 : 14,
            padding: isSmallScreen ? '4px 18px' : '6px 24px',
            backgroundColor: colors.primary,
            borderRadius: 100, //needs to be big enough to look like a pill...
            border: 'none'
        },

        // Button style in options selection
        choiceButtonStyle:
        {
            textAlign: 'center', // Align text to the left
            fontSize: isSmallScreen ? 14 : 18,
            color: colors.dark,
            cursor: 'pointer',
            marginLeft: isSmallScreen ? 14 : 18,
            marginRight: isSmallScreen ? 14 : 18,
            marginTop: isSmallScreen ? 10 : 14,
            marginBottom: isSmallScreen ? 10 : 14,
            padding: isSmallScreen ? '4px 18px' : '6px 24px',
            //backgroundColor: colors.gray6,
            backgroundColor: isHovered ? colors.gray5 : colors.gray6,
            borderRadius: 100, //needs to be big enough to look like a pill...
            border: `2px solid ${colors.gray3}`
        },

        sliderBlockStyle:
        {
            display: 'flex',
            flexDirection: 'column',
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            //backgroundColor: colors.gray4,
            textAlign: 'center',
            alignItems: 'center', // Center horizontally
            //marginBottom: isSmallScreen ? 9 : 12,
            //marginTop: isSmallScreen ? 14 : 18,
            //marginBottom: isSmallScreen ? 14 : 18,
            minWidth: 320,
        },

        sliderBlockStyle:
        {
            display: 'flex',
            flexDirection: 'column',
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            //backgroundColor: colors.gray4,
            textAlign: 'center',
            alignItems: 'center', // Center horizontally
            //marginBottom: isSmallScreen ? 9 : 12,
            //marginTop: isSmallScreen ? 14 : 18,
            //marginBottom: isSmallScreen ? 14 : 18,
            minWidth: 320,
        },

        sliderMainTextStyle:
        {
            display: 'flex',
            flexDirection: 'column',
            fontSize: isSmallScreen ? 14 : 18,
            fontWeight: 400,
            //backgroundColor: colors.gray4,
            textAlign: 'center',
            alignItems: 'center', // Center horizontally
            //marginBottom: isSmallScreen ? 9 : 12,
            marginTop: isSmallScreen ? 14 : 18,
            marginBottom: isSmallScreen ? 4 : 6,
            minWidth: 320,
        },

        sliderSupportingTextStyle:
        {
            display: 'flex',
            flexDirection: 'column',
            fontSize: isSmallScreen ? 12 : 14,
            fontWeight: 400,
            fontStyle: 'italic',  // Use fontStyle for italic text
            //backgroundColor: colors.gray4,
            textAlign: 'center',
            alignItems: 'center', // Center horizontally
            //marginBottom: isSmallScreen ? 9 : 12,
            marginTop: isSmallScreen ? 4 : 6,
            marginBottom: isSmallScreen ? 14 : 18,
            minWidth: 320,
        },

        sliderInputStyle:
        {
            width: '40%',
            minWidth: 240,
            backgroundColor: colors.green1,
        },

        // Basic divider style
        dividerStyle:
        {
            height: '1px',
            backgroundColor: colors.gray6,
            marginRight: isSmallScreen ? 8 : 12,
            marginLeft: isSmallScreen ? 8 : 12,
            marginTop: isSmallScreen ? 10 : 14,
            marginBottom: isSmallScreen ? 10 : 14,
        },
        dividerDarkStyle:
        {
            height: '1px',
            backgroundColor: colors.gray4,
            marginRight: isSmallScreen ? 8 : 12,
            marginLeft: isSmallScreen ? 8 : 12,
            marginTop: isSmallScreen ? 10 : 14,
            marginBottom: isSmallScreen ? 10 : 14,
        },
        dashboardDividerStyle:
        {
            height: '1px',
            backgroundColor: colors.gray6,
            marginRight: isSmallScreen ? 14 : 18,
            marginLeft: isSmallScreen ? 14 : 18,
            marginTop: isSmallScreen ? 14 : 18,
            marginBottom: isSmallScreen ? 14 : 18,
        },
        // Main div of the navigaiton bar
        navigationBarStyle:
        {
            display: 'flex',
            flexDirection: 'row', // Set flexDirection to 'row' for horizontal alignment
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 0,
            margin: 0,
        },

        // Flex in the middle of a navigation bar
        navigationBarFlexStyle:
        {
            flex: 1,
            padding: isSmallScreen ? '4px 18px' : '6px 24px',
            textAlign: 'center',
            fontSize: isSmallScreen ? 14 : 18,
        },

        // WordBox single element
        wordBoxContainerStyle:
        {
            position: 'relative',
            marginTop: isSmallScreen ? 0 : 0,
            marginBottom: isSmallScreen ? 4 : 6,
            padding: isSmallScreen ? 12 : 18,
            backgroundColor: isHovered ? colors.gray5 : colors.gray6,
            marginLeft: isSmallScreen ? 12 : 18,
            marginRight: isSmallScreen ? 12 : 18,
            borderRadius: isSmallScreen ? 6 : 9,
        },

        // Dov wrapping the text and the icon in the closed part of the accordion
        wordBoxMainBlockStyle:
        {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },

        // Caption when "closed"
        wordBoxTextWordStyle:
        {
            textAlign: 'left',
            fontSize: isSmallScreen ? 18 : 24,
            fontWeight: 700,
            marginLeft: isSmallScreen ? 9 : 12,
            marginRight: isSmallScreen ? 36 : 50,
            marginTop: isSmallScreen ? 2 : 3,
            marginBottom: isSmallScreen ? 2 : 3,
        },

        // Div wrapping the content of the details
        wordBoxDetailTextStyle:
        {
            textAlign: 'left',
            fontWeight: 400,
            fontSize: isSmallScreen ? 14 : 18,
            margin: 0,
        },

        // Div with individual pieces of details    
        wordBoxDetailTextStyleLine:
        {
            textAlign: 'left',
            fontSize: isSmallScreen ? 14 : 18,
            marginLeft: isSmallScreen ? 9 : 12,
            marginRight: isSmallScreen ? 36 : 50,
            marginTop: isSmallScreen ? 6 : 9,
            marginBottom: isSmallScreen ? 6 : 9,
        },

        // Div wrapping hte icon within the accordion    
        workBoxIconAreaStyle:
        {
            display: 'flex',
            alignItems: 'center',
            marginRight: isSmallScreen ? 9 : 12,
        },

        // Icon within the accordion - up or down
        wordBoxIconStyle:
        {
            cursor: 'pointer',
            marginRight: 0,
            maxWidth: isSmallScreen ? 24 : 32,
            maxHeight: isSmallScreen ? 24 : 32,
        },

    };
};

export const componentStyles = ComponentStyles;

