// Colors, styles etc.
import { componentStyles } from 'components/styles/component_styles';
import { tableStyles } from 'components/styles/table_styles';

// Basic core functions
import React from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // To handle responsiveness

const TableComponent = ({ headers, rows, showGrid = true, filter = {}, hasDeleteButton = false, setDeleteButtonUseState }) => {

    // Icon image file definitions
    const icon_correct = require('img/icons/symbols/colour/icon_correct_green.png');
    const icon_incorrect = require('img/icons/symbols/colour/icon_incorrect_red.png');
    const icon_unknown = require('img/icons/symbols/colour/icon_unknown_black.png');

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Parameters
    const isGridVisible = showGrid;

    //Styles
    const styles = componentStyles(isSmallScreen);
    const tables = tableStyles(isSmallScreen, isGridVisible);
    const extraButtonColumnStyle = {
        // maxWidth: '200px', // Set maximum width to 50px
        // overflow: 'hidden', // Prevent content from overflowing
        // textOverflow: 'ellipsis', // Use ellipsis (...) for overflowed text
        // whiteSpace: 'nowrap' // Ensure content stays on a single line
        // whiteSpace: 'normal' // Allow content to wrap to new lines as needed
    };

    // Adjust table container style to ensure it does not exceed the parent container's width
    const tableContainerStyle = {
        width: '100%', // Ensures the container takes the full width of its parent
        overflowX: 'auto' // Adds horizontal scrolling if the table exceeds the container's width
    };

    // Function to filter rows based on the filter prop
    const filterRows = (rows, filter) => {
        // If filter is empty, return all rows
        if (Object.keys(filter).length === 0) {
            return rows;
        }
        // Extract the filter's key and value. Assuming there's only one key-value pair for filtering.
        const filterKey = Object.keys(filter)[0];
        const filterValue = filter[filterKey];

        // Filter rows based on filter criteria
        return rows.filter(row => row[filterKey] === filterValue);
    };

    // Apply the filter to the rows
    const filteredRows = filterRows(rows, filter);

    // Function to handle delete button click
    const handleDeleteClick = (row) => {
        setDeleteButtonUseState(row);
    };

    return (
        <div style={tableContainerStyle}>
            <table style={tables.tableStyleBasic}>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th style={tables.tableHeaderStyleBasic} key={index}>{header}</th>
                        ))}
                        {/* Delete button column header */}
                        {hasDeleteButton && <th style={{ ...tables.tableHeaderStyleBasic, ...extraButtonColumnStyle }}></th>}
                    </tr>
                </thead>
                <tbody>
                    {filteredRows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {headers.map((header, colIndex) => (
                                <td key={colIndex} style={tables.tableCellStyleBasic}>
                                    {typeof row[header] === 'object' && row[header] !== null
                                        ? JSON.stringify(row[header])
                                        : row[header] === 'Correct' ? (
                                            <img
                                                src={icon_correct}
                                                alt="Correct answer"
                                                style={tables.tableCellIconStyleBasic}
                                            />
                                        ) : row[header] === 'Incorrect' ? (
                                            <img
                                                src={icon_incorrect}
                                                alt="Incorrect answer"
                                                style={tables.tableCellIconStyleBasic}
                                            />
                                        ) : row[header] === 'Skipped' ? (
                                            <img
                                                src={icon_unknown}
                                                alt="Skipped answer"
                                                style={tables.tableCellIconStyleBasic}
                                            />
                                        ) : row[header]
                                    }
                                </td>
                            ))}
                            {/*Delete button itself */}
                            {hasDeleteButton && (
                                <td style={{ ...tables.tableCellStyleBasic, ...extraButtonColumnStyle }}>
                                    <button
                                        style={styles.buttonStyleCore}
                                        onClick={() => handleDeleteClick(row)}>Delete</button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    );
};

export default TableComponent;
