import React, { useState } from 'react';

import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

const LanguageBar = (props) => {
    const { title, setUserInput, hideIcons } = props;

    // Image definitions
    const iconStyle = { cursor: 'pointer', maxWidth: 32, maxHeight: 32, marginRight: 10 };
    const uk_flag = require('img/icons/country_flags/circles/110-united kingdom.png')
    const cz_flag = require('img/icons/country_flags/circles/202-czech republic.png')

    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Event handler to update filterSelection when TextField changes
    const [languageSelection, setLanguageSelection] = useState('');
    const toggleLanguage = (event, imageClicked) => {
        setLanguageSelection(imageClicked);
        setUserInput(imageClicked); // Report the image clicked
    };

    return (
        <div style={{ position: 'relative', marginTop: isSmallScreen ? 6 : 9, marginBottom: isSmallScreen ? 6 : 9 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 style={{
                    marginTop: isSmallScreen ? 6 : 9,
                    marginBottom: isSmallScreen ? 6 : 9,
                    marginLeft: 0,
                    textAlign: 'left',
                    fontSize: isSmallScreen ? '1.70rem' : '2.5rem',
                    fontWeight: 400,
                }}>
                    {title}
                </h2>
                <div style={{ maxWidth: 120, marginRight: 0, display: 'flex', justifyContent: 'center', position: 'relative' }}>
                    {!hideIcons && (
                        <>
                            <img
                                src={uk_flag}
                                alt="ENG"
                                onClick={(event) => toggleLanguage(event, 'en')}
                                style={iconStyle}
                            />
                            <img
                                src={cz_flag}
                                alt="CZ"
                                onClick={(event) => toggleLanguage(event, 'cz')}
                                style={iconStyle}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default LanguageBar;
