// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { textStyles } from 'components/styles/text_styles';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import staticImages from 'components/../img/staticImageAssets';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useEffect, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

// Support functions
import { useTopBarCLipboardContext } from './TopBarCLipboardContext.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize.js';
import { fetchUpdates } from 'components/_common/amplify/graphql_fetch/fetchUpdates.js';
import { Outlet, useNavigate } from 'react-router-dom';

// Version control
const releaseVersion = '80'
console.log('releaseVersion:', releaseVersion)

// Render tob bar
export function Layout() {

  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();
  const { leftButtons, rightButtons, textCredentials } = useTopBarCLipboardContext();

  function logOut() {
    signOut();
    navigate('/login');
  }

  function getButtonStyles(button) {
    return {
      ...components.baseButtonStyles,
      backgroundColor: colors[button.backgroundColor],
      color: colors[button.textColor],
      zIndex: 3, // Set a higher z-index for the buttons
    };
  }

  // Hooks
  const [release, setRelease] = useState({});

  // Read clipboard
  const { clipboard, setClipboard } = useTopBarCLipboardContext();

  // Update clipboard
  useEffect(() => {
    setClipboard((prevClipboard) => ({
      ...prevClipboard,
      localReleaseVersion: {
        value: releaseVersion,
      },
      serverReleaseVersion: {
        value: release.currentRelease,
        message: release.currentReleaseDescription,
      },
    }));
  }, [setClipboard, release]);

  // Runs once upon start to check updates
  useEffect(() => {
    const fetchData = async () => {
      if (route === 'authenticated') {
        const currentReleaseData = await fetchUpdates();
        setRelease(currentReleaseData);
      }
    };
    fetchData();
  }, [route]);

  // Runs each time buttons change to observe the need for update version
  const [leftButtonsChangeCounter, setLeftButtonsChangeCounter] = useState(0);
  const [rightButtonsChangeCounter, setRightButtonsChangeCounter] = useState(0);
  useEffect(() => {
    setLeftButtonsChangeCounter((prevCounter) => prevCounter + 1);
  }, [leftButtons]);
  useEffect(() => {
    setRightButtonsChangeCounter((prevCounter) => prevCounter + 1);
  }, [rightButtons]);
  useEffect(() => {
    if (leftButtonsChangeCounter % 10 === 0) {
      const fetchData = async () => {
        if (route === 'authenticated') {
          const currentReleaseData = await fetchUpdates();
          setRelease(currentReleaseData);
        }
      };
      fetchData();
    }
  }, [leftButtonsChangeCounter, rightButtonsChangeCounter, route]);

  // Slice the textCredentials array to include only the first element
  const firstTextCredential = textCredentials.slice(0, 1);

  // Use useEffect to log the clipboard when it changes
  useEffect(() => {
    // console.log('Layout Clipboard:', clipboard);
  }, [clipboard]);

  // Determine screen dimensions
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= 768;

  // Styles
  const components = componentStyles(isSmallScreen);

  return (
    <>

      <div style={components.navbarStyles}>

        {/* Render leftButtons */}
        <div style={components.navbarLeftButtonContainerStyles}>

          <div style={components.navbarFlexStyle}>

            {leftButtons.map((button, index) => {
              return (
                (button.visibleIfAuthenticated === (route === 'authenticated') ||
                  button.visibleIfNotAuthenticated === (route !== 'authenticated')) && (
                  button.isImage ? (
                    <img
                      src={staticImages[button.imagePath]}
                      key={index}
                      style={components.navbarImageButtonStyles}
                      alt={button.title}
                      onClick={() => navigate(button.to)}
                    />
                  ) : (
                    <div
                      key={index}
                      onClick={() => navigate(button.to)}
                      style={components.navbarDiscitoNameText}
                    >
                      {button.title}
                    </div>
                  )
                )
              );
            })}
          </div>

        </div>

        {/* Render rightButtons */}
        <div style={components.navbarRightButtonContainerStyles}>

          {/* Display only the first element of textCredentials at the very top */}
          <div style={components.navbarUserNameText}>
            {
              firstTextCredential.map((credential, index) => (
                <div
                  key={index} // Use the index as the key
                >
                  {credential.text}
                </div>
              ))
            }
          </div>

          <div style={components.navbarFlexStyle}>

            {rightButtons.map((button, index) => {
              return (
                (button.visibleIfAuthenticated === (route === 'authenticated') ||
                  button.visibleIfNotAuthenticated === (route !== 'authenticated')) && (
                  button.isImage ? (
                    <img
                      src={staticImages[button.imagePath]}
                      key={index}
                      style={components.navbarImageButtonStyles}
                      alt={button.title}
                      onClick={button.title === 'Logout' ? logOut : () => navigate(button.to)}
                    />
                  ) : (
                    <button
                      key={index}
                      onClick={button.title === 'Logout' ? logOut : () => navigate(button.to)}
                      style={getButtonStyles(button)}
                    >
                      {button.title}
                    </button>
                  )
                )
              );
            })}
          </div>

        </div>

      </div >

      {/* Display the snack bar with information about version updates */}
      {
        release.currentRelease !== releaseVersion && (
          <div className="snackbar" style={{ background: colors.amber_a02, fontStyle: textStyles.bodyTextSmallD }}>
            {release.currentReleaseDescription}
          </div>
        )
      }

      <Outlet />
    </>
  );
}
