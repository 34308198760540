import { globalSettings } from 'GlobalSettings';
import { colors } from 'components/styles/colors';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';

import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';

import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';

import { transformSnackBarContent } from 'components/_common/functions/transformSnackBarContent';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

import StateDashboardHead from './utils/StateDashboardHead';
import StateDashboardBody from './utils/StateDashboardBody';
import StateNavigationHead from './utils/StateNavigationHead';
import StateNavigationBody from './utils/StateNavigationBody';
import DashboardFooter from 'components/pages/navigation/DashboardFooter';
import LoadingAnimation from 'components/_common/LoadingAnimation';
import DashboardBanner from 'components/pages/navigation/DashboardBanner';


export function Dashboard() {

	// block most of the renderings while loading the page
	const [isLoading, setIsLoading] = useState(true);

	// extract query parameters from url
	const [searchParams] = useSearchParams();
	const primaryKey = searchParams.get('pk');
	const rangeKey = searchParams.get('sk');
	const currentNavigationPath = `/dashboard?pk=${primaryKey}&sk=${rangeKey || ''}`;

	// Read clipboard
	const { clipboard, setClipboard } = useTopBarCLipboardContext();
	const notifications = transformSnackBarContent(clipboard.notifications); // only dashboard
	const userName = clipboard.userName.value;
	const unitId = clipboard.unitId.value; // previous clicked routes, depends on clipboard (to filter by restrictToUnit)
	const returnNavigationPath = clipboard.returnNavigationPath.value

	// Receive which cards to show
	const [cardsData, setCardsData] = useState([]);
	const [groupedCardsData, setGroupedCardsData] = useState([]);
	const [pageSettings, setPageSettings] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			// request data from backend
			const dataFromBackend = await fetchBackendData({
				task: 'readItems',
				id: primaryKey,
				range: rangeKey,
				unitId: unitId
			});
			setCardsData(dataFromBackend['returnedCards']);
			setGroupedCardsData(dataFromBackend['groupedCards'])
			// extract pageSettings
			let pageSettingsObject = dataFromBackend['pageSettings'];
			setPageSettings(pageSettingsObject);
			// curate pageSettingsObject.leftButtons (replace null in 'to' key by returnNavigationPath)
			if (pageSettingsObject.leftButtons) {
				pageSettingsObject.leftButtons = pageSettingsObject.leftButtons.map(button => {
					button.to = button.to || returnNavigationPath;
					return button;
				});
			}
			setPageSettings(pageSettingsObject);
			// update clipboard data
			setClipboard((prevClipboard) => ({
				...prevClipboard,
				activitiesData: {
					value: dataFromBackend['returnedCards'],
				},
				activityPath: { // only in math (legacy)
					value: pageSettingsObject.activityPath,
				},
			}));
		};
		fetchData();
	}, [primaryKey, rangeKey]);
	useEffect(() => {
		if (Object.keys(cardsData).length > 0) {
			setIsLoading(false)
		}
	}, [cardsData]);

	// Set-up top bar
	const isPageSettingsLoaded = Object.keys(pageSettings).length > 0;
	useNavigationConfig(
		userName, '', pageSettings.leftButtons, pageSettings.rightButtons, isPageSettingsLoaded);

	// Use the useLayoutEffect hook to scroll to the top after the component is rendered
	useScrollToTop([primaryKey, rangeKey], isLoading);
	// useDebugClipboard(clipboard);

	return (
		<div className="app">

			<>
				{primaryKey === 'DASHBOARD' && !isLoading && <DashboardBanner />}

				{primaryKey !== 'DASHBOARD' && !isLoading && <StateNavigationHead pageSettings={pageSettings} />}

				{Object.keys(pageSettings).length > 0 && (
					<>
						<main>
							<div style={virtualFullWidth}>
								<div style={availableWidth}>

									{primaryKey === 'DASHBOARD' && !isLoading && <StateDashboardHead notifications={notifications} />}

									{isLoading && <LoadingAnimation />}

									{/* Here we have enabled grouping by category, both paths nevertheless converge at CardArray.js */}
									{primaryKey === 'DASHBOARD' && !isLoading && <StateDashboardBody groupedCardsData={groupedCardsData} pageSettings={pageSettings} currentNavigationPath={currentNavigationPath} />}

									{/* Here we *DO NOT* have enabled grouping by category, both paths nevertheless converge at CardArray.js */}
									{primaryKey !== 'DASHBOARD' && !isLoading && <StateNavigationBody clipboard={clipboard} cardsData={cardsData} pageSettings={pageSettings} currentNavigationPath={currentNavigationPath} />}

								</div>
							</div>
						</main>

						<DashboardFooter />

					</>
				)}

			</>

		</div>
	);
}
