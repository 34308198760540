import { colors } from 'components/styles/colors'
import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react'
import {
  useAuthenticator,
  Text,
  Flex,
  Button
} from '@aws-amplify/ui-react'
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues'
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext'
import { useNavigate } from 'react-router-dom';

import { fetchBackendData } from 'components/_common/amplify/fetchBackendData'

import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'
import { fetchQuestions } from 'components/_common/amplify/graphql_fetch/fetchQuestions'
import { pickQuestion } from 'components/legacy/exercises/_exercises/pickQuestion'
import { generateRandomString } from 'components/_common/functions/generateRandomString'

import correctSound from 'sounds/sound-1.wav'
import incorrectSound from 'sounds/sound-2.wav'

const correctAudio = new Audio(correctSound)
const incorrectAudio = new Audio(incorrectSound)

export function ExercisesMath() {
  const { user } = useAuthenticator()

  // Read clipboard
  const { clipboard, setClipboard } = useTopBarCLipboardContext();
  const userName = clipboard.userName.value;
  const userClassroom = clipboard.userClassroom.value;
  const unitId = clipboard.unitId.value;
  const activityPath = clipboard.activityPath.value; // required by save exercise

  const navigate = useNavigate();

  // Set-up navigation buttons
  const leftButtons = useMemo(() => [
    {
      title: 'Back',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: `/dashboard?pk=ACTIVITY&sk=CERMAT~MAT~`,
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
    },
  ], []);
  const rightButtons = useMemo(() => [
    {
      title: 'Logout',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/logout',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
    }
  ], []);
  useNavigationConfig(userName, userClassroom, leftButtons, rightButtons);

  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= 768;

  const randomString = generateRandomString(5)
  const currentDate = new Date().toISOString().split('T')[0]
  const exerciseId = `${user.username}~${currentDate}~${randomString}`

  // eslint-disable-next-line
  const [allQuestions, setAllQuestions] = useState([])
  const [question, setQuestion] = useState('')
  const [questionId, setQuestionId] = useState('')
  const [translation, setTranslation] = useState('')
  const [explanation, setExplanation] = useState('')
  const [shuffledAnswers, setShuffledAnswers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [correctAnswer, setCorrectAnswer] = useState(null)

  const [startTime, setStartTime] = useState(null)
  const [timer, setTimer] = useState(0);

  const [isAnswered, setIsAnswered] = useState(false)
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false)
  const [answers, setAnswers] = useState([])
  const [correctCount, setCorrectCount] = useState(0)
  const [questionsCount, setQuestionsCount] = useState(0);
  const [buttonColors, setButtonColors] = useState([]);

  useEffect(() => {
    if (isAnswered) {
      if (isCorrectAnswer) {
        correctAudio.play()
      } else {
        incorrectAudio.play()
      }
    }
  }, [isAnswered, isCorrectAnswer])

  useEffect(() => {
    return () => {
      correctAudio.pause()
      correctAudio.currentTime = 0
      incorrectAudio.pause()
      incorrectAudio.currentTime = 0
    }
  }, [])

  useEffect(() => {

    const fetchData = async () => {
      setIsLoading(true)
      const allQuestionsBuffer = await fetchQuestions();
      setAllQuestions(allQuestionsBuffer)
      pickQuestion(
        allQuestionsBuffer,
        setCorrectAnswer,
        setShuffledAnswers,
        setExplanation,
        setQuestion,
        setQuestionId,
        setTranslation,
        setIsLoading,
        setStartTime,
      );
    };
    fetchData();

  }, []);

  useEffect(() => {
    // console.log(`[Exercises] question.id: ${question.question}`)
  }, [question])

  useEffect(() => {
    let interval;

    if (startTime !== null && !isAnswered) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [startTime, isAnswered]);

  function handleNextClick() {
    if (!isAnswered) {
      setQuestionsCount((prevCount) => prevCount + 1)
    }

    // Reset button colors to white
    const whiteColors = Array(shuffledAnswers.length).fill('white');
    setButtonColors(whiteColors);

    setIsAnswered(false)
    setIsCorrectAnswer(false)
    setStartTime(null)

    pickQuestion(
      allQuestions,
      setCorrectAnswer,
      setShuffledAnswers,
      setExplanation,
      setQuestion,
      setQuestionId,
      setTranslation,
      setIsLoading,
      setStartTime,
    );
  }

  function evaluateResponse(selectedWord) {

    // console.log('selectedWord:', selectedWord, ', correctAnswer:', correctAnswer)

    if (!isAnswered) {
      setQuestionsCount((prevCount) => prevCount + 1);

      const isCorrectAnswer = selectedWord === correctAnswer;
      setIsCorrectAnswer(isCorrectAnswer);
      setIsAnswered(true);

      if (isCorrectAnswer) {
        setCorrectCount((prevCount) => prevCount + 1);
      }

      const colors = shuffledAnswers.map((choice) =>
        choice === correctAnswer
          ? 'lightgreen' // Correct word
          : choice === selectedWord
            ? 'lightcoral' // Incorrect word
            : 'lightcoral' // Other incorrect words
      );
      setButtonColors(colors);

      if (startTime) {
        const endTime = Date.now();
        const elapsedSeconds = (endTime - startTime) / 1000;

        const answer = {
          elapsed: elapsedSeconds.toFixed(2), // Update here
          correct: isCorrectAnswer,
          word: correctAnswer,
          answer: selectedWord,
          questionId: questionId
        };
        setAnswers((prevAnswers) => [...prevAnswers, answer]);

        // console.log("Answer Elapsed Time:", answer.elapsed);
      }
    }
  }

  const handleSaveClick = async () => {
    try {
      const quizResponse = answers.map((answer) => ({
        elapsed: answer.elapsed !== null ? parseFloat(answer.elapsed) : null,
        correct: answer.correct,
        question: answer.word,
        answer: answer.answer,
        questionId: answer.questionId
      }))

      // Update or create the 'quizResponse' key in the clipboard
      setClipboard({
        ...clipboard,
        quizResponse: {
          value: JSON.stringify(quizResponse),
        },
        returnPath: {
          value: '/dashboard?pk=ACTIVITY&sk=CERMAT~MAT~'
        },
      });

      fetchBackendData({
        variables: {
          payload: JSON.stringify(
            {
              task: 'saveExercise',
              range: exerciseId,
              quizResponse: quizResponse,
              userClassroom: userClassroom,
              activityPath: activityPath,
              unitId: unitId
            }
          ),
        },
      }, false, true)

      setAnswers([])
      navigate(`/exercises/review?exerciseId=${exerciseId}`);

    } catch (error) {
      console.error('Mutation error:', error)
    }
  }

  // Use useEffect to log the clipboard when it changes
  useEffect(() => {
    // console.log('Math Exercises Clipboard:', clipboard);
  }, [clipboard]);

  // Use the useLayoutEffect hook to scroll to the top after the component is rendered
  useLayoutEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0); // Scroll to the top instantly
    };
    scrollToTop(); // Call the function after the component is rendered
  }, []);

  return (
    <div className="app" style={{ marginTop: '20px' }} margin={isSmallScreen ? '1rem 0' : '3rem 20%'}>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '50px',
            padding: '10px',
            marginRight: '10px',
            border: '2px solid darkblue',
            minWidth: '120px',
            width: '120px',
            marginTop: '0px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={{ fontSize: '20px', color: 'black', margin: '0' }}>
            {correctCount}/{questionsCount}
          </Text>
        </div>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '50px',
            padding: '10px',
            border: '2px solid darkblue',
            minWidth: '120px',
            width: '120px',
            marginTop: '0px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={{ fontSize: '20px', color: 'black', margin: '0' }}>
            {Math.floor(timer / 60).toString().padStart(2, '0')}:{(timer % 60).toString().padStart(2, '0')}
          </Text>
        </div>
      </div>

      {isLoading && <Text margin='3rem 0'>Generating...</Text>}

      {!isLoading && question && (
        <div style={{ margin: '1rem 20px', fontSize: '130%' }}>
          <Text>{question.question}</Text>
          {translation && (
            <Text style={{ fontStyle: 'italic', marginTop: '20px' }}>{translation}</Text>
          )}
        </div>
      )}

      {!isLoading && correctAnswer !== null && (
        <div
          margin='1rem 0'
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          {shuffledAnswers.map((choice, index) => (
            <Button
              key={index}
              style={{
                marginBottom: '1rem', // Add margin bottom to separate the buttons vertically
                fontSize: '100%',
                backgroundColor: buttonColors[index],
                border: '2px solid darkblue',
              }}
              onClick={() => evaluateResponse(choice)}
            >
              {choice}
            </Button>
          ))}
        </div>
      )}

      {!isLoading && isAnswered && (
        <div
          style={{
            margin: '2rem 20px',
            fontSize: '100%',
            backgroundColor: 'lightyellow',
            padding: '10px',
            border: '1px solid #ccc',
          }}
        >
          {explanation
            .replace(/ \*\* /g, '\n')
            .split('\n')
            .map((paragraph, index) => {

              // Check if the paragraph starts with 'Answer'
              const isAnswer = paragraph.trim().startsWith('####') || paragraph.trim().startsWith('A:');

              // Check if the paragraph ends with '?'
              const isQuestion = paragraph.trim().endsWith('?');

              // Initialize the style object
              const style = {};

              // Add bold and spacing above the line if isAnswer is true
              if (isAnswer) {
                style.fontWeight = 'bold';
                style.marginTop = '20px';
              }

              // Apply bold style if it's a question
              if (isQuestion) {
                style.fontWeight = 'bold';
                style.marginTop = '10px';
              }

              // Replace 'A:' and '####'
              const cleanedParagraph = paragraph
                .replace(/<<.*?>>/g, '')
                .replace(/A:/g, 'Answer: ')
                .replace(/####/g, 'Answer: ');

              return (
                <div key={index} style={style}>
                  {cleanedParagraph}
                </div>
              );
            })}
        </div>
      )}

      {!isLoading && (
        <Flex justifyContent='center' margin='1rem 0'>
          <Button
            onClick={handleSaveClick}
            style={{ width: '10rem', marginRight: '0.15rem' }}
            type='submit'
            variation='primary'
          >
            Review
          </Button>
          <Button
            onClick={handleNextClick}
            style={{ width: '10rem', marginLeft: '0.15rem' }}
            type='submit'
            variation='primary'
          >
            Next
          </Button>
        </Flex>
      )}

    </div>
  )
}
