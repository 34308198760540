const GlobalSettings = () => {

    return {

        // None
        None: null,

        // Small screen width
        smallScreenWidth: 768,

        // Client name
        clientName: "ZŠ Curie",
        // English course name
        courseNameEN: "Vocab & spelling",
        // Math course name
        courseNameMATH: "Exercises",
        // Unit Management
        unitManagementHeading: "Unit Management",

        // Info snackbar text
        showInfoSnackBarText: "Instructions",
        // Settings snackbar text
        showSettingsSnackBarText: "Settings",

    };
};

export const globalSettings = GlobalSettings();
