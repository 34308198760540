// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { componentStyles } from 'components/styles/component_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { staticImages } from 'img/static_images';

// Basic core functions
import React, { useState, useEffect, useCallback } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';

// Support functions
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

// Components and blocks
import WelcomeFooter from 'components/pages/navigation/WelcomeFooter';
import PageTitle from 'components/pages/navigation/PageTitle';
import SimpleTableComponent from 'components/_common/SimpleTableComponent';
import MessageBox from 'components/pages/test/MsgBoxComponent.js';
import SectionHeadingToolbar from 'components/_common/SectionHeadingToolbar';

const default_settings = {
    pageTitle: 'Administration',
    pageSubTitle: 'ZŠ Curie | Content management',
    sectionHeading: 'Unit content',
    headersTableLargeScreen: ['Word', 'Author', 'Classes', 'Date', ' '], // attributes of the received words
    headersTableSmallScreen: ['Word', 'Author', ' '], // buttons are represented by blank space ' '
    leftButtons: [
        {
            title: 'Back',
            textColor: 'light',
            backgroundColor: 'gray1',
            to: '/dashboard?pk=ACTIVITY&sk=Admin~ContentManagement~',
            visibleIfAuthenticated: true,
            visibleIfNotAuthenticated: false,
            isImage: true,
            imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
        },
    ],
    rightButtons: [
        {
            title: 'Logout',
            textColor: 'light',
            backgroundColor: 'gray1',
            to: '/logout',
            visibleIfAuthenticated: true,
            visibleIfNotAuthenticated: false,
            isImage: true,
            imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
        }
    ]
}

export function AddWord({ settings = default_settings }) {

    // Navigation and user identification (to report who reset the password)
    const { user } = useAuthenticator();

    // Set-up top bar
    useNavigationConfig(null, null, settings.leftButtons, settings.rightButtons);

    // Use the useLayoutEffect hook to scroll to the top after the component is rendered
    useScrollToTop();

    // Responsiveness
    const windowSize = useWindowSize(); // requires being inside 'export function'
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const paragraphs = paragraphStyles(isSmallScreen);
    const styles = componentStyles(isSmallScreen); // requires isSmallScreen loaded

    // Handle settings: getters and setters from the parent-most page to each child
    const [userInput, setUserInput] = useState('');

    // Create table shown (headers depends on isSmallScreen)
    const selectedTableHeaders = isSmallScreen ? default_settings.headersTableSmallScreen : default_settings.headersTableLargeScreen;

    // Handle flow
    const [isLoadingDropdown, setIsLoadingDropdown] = useState(true)
    const [word, setWord] = useState(''); // word entered in the input box
    const [allWordsList, setAllWordsList] = useState([]);
    const [unitsOptions, setUnitsOptions] = useState({});
    const [selectedUnit, setSelectedUnit] = useState(0);
    const [coursesOptions, setCoursesOptions] = useState({});
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedCourseText, setSelectedCourseText] = useState('');
    const [tableRows, setTableRows] = useState([]);

    // Fetch dropdown courses and units
    const fetchDropdown = useCallback(async () => {
        const backendResponse = await fetchBackendData({ task: 'readAddWordDropdown' })
        setAllWordsList(backendResponse.words)
        setSelectedCourse(backendResponse.defaultCourse)
        setSelectedCourseText(backendResponse.defaultCourse)
        setCoursesOptions(backendResponse.coursesDropdown)
        setUnitsOptions(backendResponse.unitsDropdownDict)
        setIsLoadingDropdown(false)
    }, []);
    useEffect(() => {
        fetchDropdown();
    }, [fetchDropdown]);

    // Modify the list of units shown depending on the course selected
    useEffect(() => {
        if (unitsOptions && coursesOptions[selectedCourseText]) {
            setSelectedCourse(coursesOptions[selectedCourseText].range)
        }
    }, [selectedCourseText]); // Only execute this when selectCourse changes in the dropdown

    // Modify the list of words shown depending on the unit selected
    useEffect(() => {

        const generateTableRows = (selectedTableHeaders, wordList) => {
            return Array.from({ length: wordList.length }).map((_, index) => {
                let rowData = {};
                selectedTableHeaders.forEach(header => {
                    switch (header) {
                        case 'Word':
                            rowData['Word'] = wordList[index]['word'];
                            break;
                        case 'Author':
                            rowData['Author'] = wordList[index]['author'];
                            break;
                        case 'Classes':
                            rowData['Classes'] = wordList[index]['classes'];
                            break;
                        case 'Date':
                            rowData['Date'] = wordList[index]['date'];
                            break;
                        case ' ':
                            rowData[' '] = (
                                <div style={{ display: 'flex', gap: 6, justifyContent: 'right' }}>
                                    <button
                                        style={styles.buttonStyleLink}
                                        onClick={() => openMessageBox(
                                            'View',
                                            'ShowWordDetails',
                                            wordList[index]['word'],
                                            wordList[index]['range']
                                        )}>
                                        View
                                    </button>
                                    <button
                                        style={styles.buttonStyleLink}
                                        onClick={() => openMessageBox(
                                            'msgBoxYesNo',
                                            'DeleteWord',
                                            wordList[index]['word'],
                                            wordList[index]['range']
                                        )}>
                                        Delete
                                    </button>
                                </div>
                            );
                            break;
                        // Add more cases as necessary for other headers
                    }
                });
                return rowData;
            });
        };

        if (Object.keys(coursesOptions).length > 0 && coursesOptions[selectedCourseText]) { // if coursesOptions is not empty

            const selectedCourseTextLocal = coursesOptions[selectedCourseText].range

            if (unitsOptions && unitsOptions[selectedCourseTextLocal]) {
                const selectedItem = unitsOptions[selectedCourseTextLocal][selectedUnit];
                setTableRows(
                    generateTableRows(
                        selectedTableHeaders,
                        allWordsList[selectedItem.range]))
            }
        }

    }, [selectedUnit, selectedCourseText, isSmallScreen]); // Only execute this when selectedUnit, selectedCourseText, isSmallScreen changes in the dropdown

    // MsgBox definitions
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [messageBoxType, setMessageBoxType] = useState("msgBoxOK");
    const [messageBoxAction, setMessageBoxAction] = useState(null);
    const [messageBoxItemId, setMessageBoxItemId] = useState(null);
    const [messageBoxTitle, setMessageBoxTitle] = useState(null);
    const [messageBoxBody, setMessageBoxBody] = useState(null);
    const openMessageBox = (messageBoxType, messageBoxAction, itemClicked, itemIdClicked) => {
        setMessageBoxType(messageBoxType);
        setMessageBoxAction(messageBoxAction);
        setMessageBoxItemId(itemIdClicked);
        switch (messageBoxAction) {
            case 'ShowWordDetails':
                setMessageBoxTitle('Word Details');
                setMessageBoxBody(itemClicked);
                break;
            case 'DeleteWord':
                setMessageBoxTitle('Delete Word');
                setMessageBoxBody(itemClicked);
                break;
        }
        setShowMessageBox(true);
    };
    // Handle MsgBox buttons
    const handleOK = () => { setShowMessageBox(false) };
    const handleCancel = () => { setShowMessageBox(false) };
    const handleYes = () => {
        console.log('Yes', messageBoxAction, messageBoxItemId)
        setShowMessageBox(false)
    };
    const handleNo = () => {
        console.log('No', messageBoxAction, messageBoxItemId)
        setShowMessageBox(false)
    };

    // Handle Add button click
    const proceedDashboard = () => {
        console.log('Request creation of word:', word);
    }

    return (
        <div className="app">

            <PageTitle
                title={settings.pageTitle}
                subtitle={settings.pageSubTitle} />

            <main>
                <div style={virtualFullWidth}>
                    <div style={availableWidth}>

                        <SectionHeadingToolbar
                            title={settings.sectionHeading}
                            setUserInput={setUserInput}
                            infoText='InfoText'
                        />

                        <div style={styles.mainDivStyle}>

                            {/* Add new word section */}
                            <div style={{ ...styles.captionLabelStyle, marginTop: 0 }}>
                                <div style={paragraphs.sectionHeadingSubtitle}>
                                    Add new word
                                </div>
                            </div >

                            <div>
                                {/* Link to select courses */}
                                <div style={styles.simpleTextStyleFlexML1MR1}>
                                    <div style={styles.simpleTextStyleFlex8020}>
                                        Select course:
                                    </div >
                                    <select
                                        name='coursesDropdown'
                                        style={styles.simpleSelectStyle80pct}
                                        // required
                                        value={selectedCourseText}
                                        onChange={(e) => setSelectedCourseText(e.target.value)}>
                                        {Object.keys(coursesOptions).map((optionValue) => (
                                            <option key={optionValue} value={optionValue}>
                                                {coursesOptions[optionValue].text}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Link to select units */}

                                <div style={styles.simpleTextStyleFlexML1MR1}>
                                    <div style={styles.simpleTextStyleFlex8020}>
                                        Select unit:
                                    </div >
                                    { // only if unitsOptions[selectedCourse] is not empty
                                        unitsOptions[selectedCourse] && Object.keys(unitsOptions[selectedCourse]).length > 0 &&
                                        <select
                                            name='unitsDropdown'
                                            style={styles.simpleSelectStyle80pct}
                                            // required
                                            value={selectedUnit}
                                            onChange={(e) => setSelectedUnit(e.target.value)}
                                        >
                                            {Object.keys(unitsOptions[selectedCourse]).map((optionValue) => (
                                                <option key={optionValue} value={optionValue}>
                                                    {unitsOptions[selectedCourse][optionValue].text}
                                                </option>
                                            ))}
                                        </select>
                                    }
                                </div>

                                {/* Input box and button to add new word */}
                                <div style={styles.simpleTextStyleFlexML1MR1Alignment}>

                                    <input
                                        style={styles.simpleSelectStyle}
                                        name='name'
                                        placeholder='New word'
                                        label='Word'
                                        value={word} // Bind the value of the word textbox to the state
                                        onChange={(e) => setWord(e.target.value)} // Update the state on change
                                    />

                                    <button disabled={!word}
                                        onClick={proceedDashboard}
                                        style={styles.buttonStyleCore20pct}>
                                        Add
                                    </button>

                                </div>

                            </div>

                            {/* Divider */}
                            <div style={styles.dividerStyle}></div>

                            {/* Current words section */}
                            <div style={{ ...styles.captionLabelStyle, marginTop: 0 }}>
                                <div style={paragraphs.sectionHeadingSubtitle}>
                                    Current words
                                </div>
                            </div >

                            {/*  Render only when information required is available */}
                            {coursesOptions && selectedCourse !== undefined && coursesOptions[selectedCourse] ? (
                                <div style={styles.simpleTextStyleML1MR1forLabel}>
                                    Course: {coursesOptions[selectedCourse].text}
                                </div>
                            ) : null}

                            {coursesOptions && selectedCourse !== undefined && unitsOptions[selectedCourse] ? (
                            <div style={styles.simpleTextStyleML1MR1forLabel}>
                                    Unit: {unitsOptions[selectedCourse][selectedUnit]['text']}
                            </div >
                            ) : null}

                            {/* SimpleTableComponent */}
                            <div style={styles.simpleTextStyleML1MR1forTable}>
                                <SimpleTableComponent
                                    headers={selectedTableHeaders}
                                    rows={tableRows}
                                />
                            </div>

                            {/* Comments */}
                            {/* View button - view complete content linked to the word like in the Testing / Validate content component, except just one picture... */}
                            {/* Delete button - delete word - with confirmation through the MsgBox - "Are you sure?" */}
                            {/* If messageBoxType === msgBoxYesNo then title='Confirm deletion', message='Are you sure to delete the item '& theItem */}

                            <MessageBox
                                msgBoxType={messageBoxType}
                                isVisible={showMessageBox}
                                title={messageBoxTitle}
                                message={messageBoxBody}
                                onOK={handleOK}
                                onCancel={handleCancel}
                                onYes={handleYes}
                                onNo={handleNo}
                            />

                        </div>
                    </div>
                </div>
            </main >

            <WelcomeFooter />

        </div >
    );
}