import { colors } from 'components/styles/colors';
import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react'
import {
    useAuthenticator,
    View,
    Text,
} from '@aws-amplify/ui-react'
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';

import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

import { generateQuestions } from 'components/legacy/exercises/_exercises/generators/roman_numerals_up_to_3999';
import NumericKeypad from 'components/legacy/exercises/_exercises/NumericKeypad';

export function ExercisesRomanNumerals3() {
    const { user } = useAuthenticator()

    // Read clipboard
    const { clipboard, setClipboard } = useTopBarCLipboardContext();
    const userName = clipboard.userName.value;
    const userClassroom = clipboard.userClassroom.value;

    // Set-up navigation buttons
    const leftButtons = useMemo(() => [
        {
            title: 'Back',
            textColor: colors.light,
            backgroundColor: colors.gray1,
            to: `/dashboard?pk=ACTIVITY&sk=CERMAT~MAT~`,
            visibleIfAuthenticated: true,
            visibleIfNotAuthenticated: false,
            isImage: true,
            imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
        },
    ], []);
    const rightButtons = useMemo(() => [
        {
            title: 'Logout',
            textColor: colors.light,
            backgroundColor: colors.gray1,
            to: '/logout',
            visibleIfAuthenticated: true,
            visibleIfNotAuthenticated: false,
            isImage: true,
            imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
        }
    ], []);
    useNavigationConfig(userName, userClassroom, leftButtons, rightButtons);

    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(true)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const [userInput, setUserInput] = useState('');
    const [score, setScore] = useState(0);
    const questions = useMemo(() => generateQuestions(), []);

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * questions.length);
        setCurrentQuestionIndex(randomIndex);
        setIsLoading(false)
    }, []);

    useEffect(() => {
        if (userInput) {
            setIsLoading(true)

            const userAnswer = parseInt(userInput, 10); // Convert the input to an integer
            if (!isNaN(userAnswer) && userAnswer === questions[currentQuestionIndex].answer) {
                setScore(score + 1); // Increment score if the answer is correct
            } else {
                setScore(score - 1.5); // Subtract 1.5 from the score if the answer is incorrect
            }

            const randomIndex = Math.floor(Math.random() * questions.length);
            setCurrentQuestionIndex(randomIndex);

            setUserInput('');

            setIsLoading(false)
        }
    }, [userInput]);

    // Use the useLayoutEffect hook to scroll to the top after the component is rendered
    useLayoutEffect(() => {
        const scrollToTop = () => {
            window.scrollTo(0, 0); // Scroll to the top instantly
        };
        scrollToTop(); // Call the function after the component is rendered
    }, []);

    // Render page
    return (
        <View
            className="app" style={{ marginTop: '20px' }}
            margin={isSmallScreen ? '2rem 0' : '3rem 20%'}
        >

            {!isLoading && (
                <View
                    style={{ margin: isSmallScreen ? '0.5rem 20px' : '3rem 20px', fontSize: '130%' }}>
                    <Text>{questions[currentQuestionIndex].question}</Text>
                </View>
            )}
            <Text
                style={{ margin: isSmallScreen ? '0rem 20px' : '3rem 20px', fontSize: '100%' }}>
                Your Score: {score}
            </Text>

            <View style={{
                margin: isSmallScreen ? '-0.5rem 20px' : '0rem 20px',
                display: 'flex', flexDirection: 'column', alignItems: 'center'
            }}>

                <NumericKeypad setUserInput={setUserInput} />

            </View>

        </View>
    );
}