import { colors } from 'components/styles/colors';

import React, { useState } from 'react';

const TextInputComponent = () => {
    // State to store the user input
    const [inputText, setInputText] = useState('');


    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };
    // Event handler to update filterSelection when TextField changes
    const [inputSelection, setInputSelection] = useState('');

    // Event handler to update the state when the input changes
    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    const coreInputStyle = {
        position: 'relative',
        textAlign: 'center',
        padding: 10,
        fontSize: 16,
        fontColor: colors.amber,
        //border: 'none',
        border: `3px solid ${isFocused ? colors.green1 : colors.amber}`, // Change border color when focused
        borderRadius: '50px',
        //margin: '5px 0',
        marginTop: 20,
        marginBottom: 50,
        //color: colors.dark,
        backgroundColor: colors.gray6,
        padding: '20px',
        width: '50%', // Set the width to 50% of the parent container
        height: 'auto', // Automatically adjust the height based on content
    }

    const isTextEqualHiThere = inputText === 'Hi there';

    return (
        <div>
            <label htmlFor="userInput">Enter Text:</label>
            <p></p>
            <input
                className=''
                type="text"
                id="userInput"
                value={inputText}
                onChange={handleInputChange}
                placeholder="Type here..."
                style={coreInputStyle}
            />
            <p>Entered Text: {inputText}</p>
            <p>Is Text Equal to "Hi there": {isTextEqualHiThere ? 'True' : 'False'}</p>
        </div >
    );
};

export default TextInputComponent;
