// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import idioms from 'locales/idioms_static';

// Basic core functions
import React, { useState, useEffect, useMemo, useCallback, useLayoutEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

// Support functions
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

// Components and blocks
import WelcomeBanner from 'components/pages/navigation/WelcomeBanner';
import WelcomeFooter from 'components/pages/navigation/WelcomeFooter';
import SectionHeadingToolbar from 'components/_common/SectionHeadingToolbar';
import PageTitleActivity from 'components/pages/navigation/PageTitleActivity';
import PageTitle from 'components/pages/navigation/PageTitle';
import DashboardBanner from 'components/pages/navigation/DashboardBanner';
import DashboardFooter from 'components/pages/navigation/DashboardFooter';

const default_settings = {
    pageTitle: 'Administration',
    pageSubTitle: 'ZŠ Curie | User management',
    sectionTitle: 'Change password',
    leftButtons: [
        {
            title: 'Back',
            textColor: 'light',
            backgroundColor: 'gray1',
            to: '/dashboard?pk=ACTIVITY&sk=Admin~UserManagement~',
            visibleIfAuthenticated: true,
            visibleIfNotAuthenticated: false,
            isImage: true,
            imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
        },
    ],
    rightButtons: [
        {
            title: 'Logout',
            textColor: 'light',
            backgroundColor: 'gray1',
            to: '/logout',
            visibleIfAuthenticated: true,
            visibleIfNotAuthenticated: false,
            isImage: true,
            imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
        }
    ]
}

export function ResetPassword({ settings = default_settings }) {

    // Language selection for static texts
    const [activeLanguage, setActiveLanguage] = useState('en');
    const currentTexts = idioms[activeLanguage];
    // Static texts aliases
    const instructions = currentTexts.user_management_instructions.change_password;

    // Navigation and user identification (to report who reset the password)
    const { user } = useAuthenticator();

    // Set-up top bar
    useNavigationConfig(null, null, settings.leftButtons, settings.rightButtons);

    // Handle settings
    const [userInput, setUserInput] = useState('');

    // Use the useLayoutEffect hook to scroll to the top after the component is rendered
    useScrollToTop();

    // Responsiveness
    const windowSize = useWindowSize(); // requires being inside 'export function'
    const isSmallScreen = windowSize.width <= 768;

    //Styles
    const styles = componentStyles(isSmallScreen); // requires isSmallScreen loaded

    // Handle flow
    const [textInput, setTextInput] = useState('');
    const [textUsername, setTextUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [serverMessage, setServerMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    // function that connects to backend requesting a user password reset
    const executeResetPassword = useCallback(async (textInput) => {
        const backendRequest = await fetchBackendData({
            task: 'resetPassword',
            requestBy: user.username,
            targetUsername: textInput
        })
        // report to frontend the new random password and the server message
        setNewPassword(backendRequest.response)
        setServerMessage(backendRequest.message)
    }, [user]);

    // click Reset Password button
    const handleResetPasswordClick = () => {
        // The cliking of the reset password button verifies the integrity of information...
        // ... before invoking executeResetPassword
        if (!(textInput.trim() === '')) {
            setIsLoading(true);
            setNewPassword('');
            setServerMessage('');
            setTextUsername(textInput);
            executeResetPassword(textInput);
            setTextInput('');
            setIsLoading(false);
            setIsCopied(false);
        }
    };

    // click Copy to Clipboard
    const copyToClipboard = () => {
        const newPasswordText = newPassword;
        const textArea = document.createElement('textarea');
        textArea.value = newPasswordText;

        // Ensure it's not visible
        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0';

        document.body.appendChild(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
            setIsCopied(true);
        } catch (err) {
            console.error('Unable to copy', err);
        }

        document.body.removeChild(textArea);
    };


    return (
        <div className="app">

            <PageTitle
                title={settings.pageTitle}
                subtitle={settings.pageSubTitle} />

            <main>
                <div style={virtualFullWidth}>
                    <div style={availableWidth}>

                        <SectionHeadingToolbar
                            title={settings.sectionTitle}
                            setUserInput={setUserInput}
                            infoText={instructions}
                            activeLanguage={activeLanguage}
                            setActiveLanguage={setActiveLanguage}
                        />

                        <div style={styles.mainDivStyle}>

                            <div
                                style={styles.captionLabelStyle}>
                                Select user
                            </div >

                            <div style={styles.simpleTextStyle}>
                                This form resets a user password.
                                <br />
                                User data and exercises results are not deleted.
                            </div>

                            <div style={styles.simpleTextStyleFlex}>
                                <div style={styles.simpleTextStyleFlex}>
                                    Type in user name
                                </div>
                                <input
                                    style={{ ...styles.simpleTextStyleFlex, borderRadius: 100 }}
                                    value={textInput}
                                    onChange={(e) => setTextInput(e.target.value)}
                                    autoCapitalize="none"
                                />
                            </div>

                            <div >
                                <button
                                    style={styles.buttonStyleCore}
                                    onClick={handleResetPasswordClick}>
                                    Reset Password
                                </button>
                            </div>

                            {/* Part visible after clicking the button  */}

                            {textUsername && (
                                <div style={styles.simpleTextStyle}>

                                    <div style={styles.dividerStyle} />

                                    <div style={styles.simpleBarStyle}>
                                        <div>
                                            {/* While the request is processing we show this text */}
                                            {isLoading ? (
                                                `Processing request for user ${textUsername}...`
                                            ) : (
                                                <>

                                                    {/* If success, there is a new randomly generated password */}
                                                    {newPassword !== '' && (
                                                        <>
                                                            <div style={styles.simpleBarStyle}>
                                                                New password for user {textUsername}:
                                                            </div>

                                                            <div style={{ ...styles.simpleTextStyle, fontFamily: 'Courier', fontSize: '120%', margin: 0, padding: 0 }}>
                                                                {newPassword}
                                                            </div>

                                                            <div style={styles.simpleTextStyle}>
                                                                Note: this temporary password is valid for 30 days
                                                            </div>

                                                            <div style={styles.simpleTextStyle}>
                                                                <button
                                                                    style={{ ...styles.buttonStyleCore, border: 'none' }}
                                                                    onClick={copyToClipboard}>
                                                                    {isCopied ? 'Copied!' : 'Copy to Clipboard'}
                                                                </button>
                                                            </div>

                                                        </>
                                                    )}

                                                    {/* In all cases, success or fail, we show the server message  */}
                                                    {/* In case of failure, the message contains information about why it failed */}
                                                    <div style={styles.simpleTextStyle}>
                                                        <i><strong>{serverMessage}</strong></i>
                                                    </div>

                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>

                    </div>
                </div>
            </main >

            <DashboardFooter />

        </div >
    );
}