import { colors } from 'components/styles/colors';
import { textStyles } from 'components/styles/text_styles';

const ParagraphStyles = (isSmallScreen) => {

    // Styles used in the app
    //=============================================================================================================
    return {
        pageTitleTitle:
        {
            //font-related part
            fontFamily: "Inter",
            fontSize: isSmallScreen ? 24 : 36, // alternative "3rem"
            fontWeight: 700, // = "bold" (t.b.d.)
            fontSlant: 0, // = "regular"
            color: colors.dark,
            //paragraph-related part
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: isSmallScreen ? 8 : 12, //
            marginLeft: 0,
            marginRight: 0,
        },

        pageTitleSubtitle:
        {
            //font-related part
            fontFamily: "Inter",
            fontSize: isSmallScreen ? 20 : 28, // alternative "2.2rem"
            fontWeight: 400, // = "regular" (t.b.d.)
            fontSlant: 0, // = "regular"
            color: colors.dark,
            //paragraph-related part
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: isSmallScreen ? 8 : 12, //
            marginBottom: 0, //
            marginLeft: 0,
            marginRight: 0,
        },

        pageTitleActivityTitle:
        {
            //font-related part
            fontFamily: "Inter",
            fontSize: isSmallScreen ? 20 : 24, // alternative "3rem"
            fontWeight: 700, // = "bold" (t.b.d.)
            fontSlant: 0, // = "regular"
            color: colors.dark,
            //paragraph-related part
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: isSmallScreen ? 6 : 9, //
            marginLeft: 0,
            marginRight: 0,
        },

        pageTitleActivitySubtitle:
        {
            //font-related part
            fontFamily: "Inter",
            fontSize: isSmallScreen ? 16 : 20, // alternative "3rem"
            fontWeight: 400, // = "bold" (t.b.d.)
            fontSlant: 0, // = "regular"
            color: colors.dark,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: isSmallScreen ? 6 : 9, //
            marginBottom: 0, //
            marginLeft: 0,
            marginRight: 0,
        },

        pageTitleActivityDsubTitle:
        {
            //font-related part
            fontFamily: "Inter",
            fontSize: "36px", // alternative "2.2rem"
            fontWeight: 700, // = "regular" (t.b.d.)
            fontSlant: 0, // = "regular"
            color: colors.dark,
            //paragraph-related part
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 0, //
            marginLeft: 0,
            marginRight: 0,
        },

        pageTitleActivityMsubTitle:
        {
            //font-related part
            fontFamily: "Inter",
            fontSize: "28px", // alternative "2.2rem"
            fontWeight: 700, // = "bold" (t.b.d.)
            fontSlant: 0, // = "regular"
            color: colors.dark,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 0, //
            marginLeft: 0,
            marginRight: 0,
        },

        sectionHeadingTitle:
        {
            //font-related part
            fontFamily: "Inter",
            fontSize: isSmallScreen ? 24 : 32, // alternative "2rem"
            fontWeight: 400, // = "regular" (t.b.d.)
            fontSlant: 0, // = "regular"
            color: colors.dark,
            //paragraph-related part
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 0, //
            marginLeft: isSmallScreen ? 14 : 18,
            marginRight: 0,
        },

        sectionHeadingSubtitle:
        {
            //font-related part
            fontFamily: "Inter",
            fontSize: isSmallScreen ? 18 : 24, // alternative "2rem"
            fontWeight: 400, // = "regular" (t.b.d.)
            fontSlant: 0, // = "regular"
            color: colors.dark,
            //paragraph-related part
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 0, //
            //marginLeft: isSmallScreen ? 14 : 18,
            marginLeft: 0,
            marginRight: 0,
        },

        sectionHeadingMtitle:
        {
            //load the font-related basis
            ...textStyles.title3M,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 0, //
            marginLeft: 12,
            marginRight: 0,
        },

        snackbarDtitle:
        {
            //load the font-related basis
            ...textStyles.bodyTextStandardDbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 9, //
            marginBottom: 6, //
            marginLeft: 0,
            marginRight: 0,
        },

        snackbarMtitle:
        {
            //load the font-related basis
            ...textStyles.bodyTextStandardMbold,
            //paragraph-related part
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 4, //
            marginLeft: 0,
            marginRight: 0,
        },

        snackbarDtext:
        {
            //load the font-related basis
            ...textStyles.bodyTextStandardD,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 9, //
            marginLeft: 0,
            marginRight: 0,
        },

        snackbarMtext:
        {
            //load the font-related basis
            ...textStyles.bodyTextStandardM,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 4, //
            marginBottom: 6, //
            marginLeft: 0,
            marginRight: 0,
        },

        // Generic styles desktop

        // Title styles - desktop - paragraph
        //=============================================================================================================

        title1Dpar:
        {
            //load the font-related basis
            ...textStyles.title1D,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.2, // (t.b.d.)
            lineSpacing: 12, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 12, //
            marginBottom: 12, //
        },

        title1Dparbold:
        {
            ...textStyles.title1Dbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 12, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 12, //
            marginBottom: 0, //
            marginLeft: 18,
        },

        title2Dpar:
        {
            ...textStyles.title2D,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 9, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 9, //
            marginBottom: 9, //
        },

        title2Dparbold:
        {
            ...textStyles.title2Dbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1, // (t.b.d.)
            lineSpacing: 9, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 9, //
            marginBottom: 9, //
        },

        title3Dpar:
        {
            ...textStyles.title3D,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.2, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        title3Dparbold:
        {
            ...textStyles.title3Dbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.2, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        // Heading styles - desktop - paragraph
        //=============================================================================================================

        heading1Dpar:
        {
            ...textStyles.heading1D,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading1Dparbold:
        {
            ...textStyles.heading1Dbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading2Dpar:
        {
            ...textStyles.heading2D,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading2Dparbold:
        {
            ...textStyles.heading2Dbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading3Dpar:
        {
            ...textStyles.heading3D,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading3Dparbold:
        {
            ...textStyles.heading3Dbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading4Dpar:
        {
            ...textStyles.heading4D,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading4Dparbold:
        {
            ...textStyles.heading4Dbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading5Dpar:
        {
            ...textStyles.heading5D,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading5Dparbold:
        {
            ...textStyles.heading5Dbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        // Body text styles - desktop - paragraph
        //=============================================================================================================

        bodyTextLargeDpar:
        {
            ...textStyles.bodyTextLargeD,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 6, //
        },

        bodyTextLargeDparbold:
        {
            ...textStyles.bodyTextLargeDbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextLargeDparitalic:
        {
            ...textStyles.bodyTextLargeDitalic,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextStandardDpar:
        {
            ...textStyles.bodyTextStandardD,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 6, //
        },

        bodyTextStandardDparbold:
        {
            ...textStyles.bodyTextStandardDbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextStandardDparitalic:
        {
            ...textStyles.bodyTextStandardDitalic,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextSmallDpar:
        {
            ...textStyles.bodyTextSmallD,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 6, //
        },

        bodyTextSmallDparbold:
        {
            ...textStyles.bodyTextSmallDbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextSmallDparitalic:
        {
            ...textStyles.bodyTextSmallDitalic,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextVerySmallDpar:
        {
            ...textStyles.bodyTextVerySmallD,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 6, //
        },

        bodyTextVerySmallDparbold:
        {
            ...textStyles.bodyTextVerySmallDbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextVerySmallDparitalic:
        {
            ...textStyles.bodyTextVerySmallDitalic,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextTinyDpar:
        {
            ...textStyles.bodyTextTinyD,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 6, //
        },

        bodyTextTinyDparbold:
        {
            ...textStyles.bodyTextTinyDbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextTinyDparitalic:
        {
            ...textStyles.bodyTextTinyDitalic,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        // Styles mobile

        // Title styles - mobile - paragraph
        //=============================================================================================================

        title1Mpar:
        {
            ...textStyles.title1M,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.2, // (t.b.d.)
            lineSpacing: 12, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 12, //
            marginBottom: 12, //
        },

        title1Mparbold:
        {
            ...textStyles.title1Mbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.2, // (t.b.d.)
            lineSpacing: 12, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 12, //
            marginBottom: 12, //
        },

        title2Mpar:
        {
            ...textStyles.title2M,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.2, // (t.b.d.)
            lineSpacing: 9, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 9, //
            marginBottom: 9, //
        },

        title2Mparbold:
        {
            ...textStyles.title2Mbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.2, // (t.b.d.)
            lineSpacing: 9, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 9, //
            marginBottom: 9, //
        },

        title3Mpar:
        {
            ...textStyles.title3M,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.2, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        title3Mparbold:
        {
            ...textStyles.title3Mbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.2, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        // Heading styles - mobile - paragraph
        //=============================================================================================================

        heading1Mpar:
        {
            ...textStyles.heading1M,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading1Mparbold:
        {
            ...textStyles.heading1Mbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 0, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading2Mpar:
        {
            ...textStyles.heading2M,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading2Mparbold:
        {
            ...textStyles.heading2Mbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading3Mpar:
        {
            ...textStyles.heading3M,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading3Mparbold:
        {
            ...textStyles.heading3Mbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading4Mpar:
        {
            ...textStyles.heading4M,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading4Mparbold:
        {
            ...textStyles.heading4Mbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading5Mpar:
        {
            ...textStyles.heading5M,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        heading5Mparbold:
        {
            ...textStyles.heading5Mbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.3, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        // Body text styles - desktop - paragraph
        //=============================================================================================================

        bodyTextLargeMpar:
        {
            ...textStyles.bodyTextLargeM,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 6, //
        },

        bodyTextLargeMparbold:
        {
            ...textStyles.bodyTextLargeMbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextLargeMparitalic:
        {
            ...textStyles.bodyTextLargeMitalic,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextStandardMpar:
        {
            ...textStyles.bodyTextStandardM,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 6, //
        },

        bodyTextStandardMparbold:
        {
            ...textStyles.bodyTextStandardMbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextStandardMparitalic:
        {
            ...textStyles.bodyTextStandardMitalic,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextSmallMpar:
        {
            ...textStyles.bodyTextSmallM,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 6, //
        },

        bodyTextSmallMparbold:
        {
            ...textStyles.bodyTextSmallMbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextSmallMparitalic:
        {
            ...textStyles.bodyTextSmallMitalic,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextVerySmallMpar:
        {
            ...textStyles.bodyTextVerySmallM,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 6, //
        },

        bodyTextVerySmallMparbold:
        {
            ...textStyles.bodyTextVerySmallMbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextVerySmallMparitalic:
        {
            ...textStyles.bodyTextVerySmallMitalic,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextTinyMpar:
        {
            ...textStyles.bodyTextTinyM,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 0, //
            marginBottom: 6, //
        },

        bodyTextTinyMparbold:
        {
            ...textStyles.bodyTextTinyMbold,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

        bodyTextTinyMparitalic:
        {
            ...textStyles.bodyTextTinyMitalic,
            //paragraph-related part    
            textAlign: "left",
            display: "block",
            position: "relative",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5, // (t.b.d.)
            lineSpacing: 6, // (with bulleted / numbered lists)
            paragraphSpacing: 0, //
            paragraphIndent: 0, //
            marginTop: 6, //
            marginBottom: 6, //
        },

    }
}

export const paragraphStyles = ParagraphStyles;
