import { colors } from 'components/styles/colors';
import { globalSettings } from 'GlobalSettings';
import { componentStyles } from 'components/styles/component_styles';

import React, { useState } from 'react';

import CardLink from 'components/pages/browser/utils/CardLink';
import SectionHeading from 'components/pages/navigation/SectionHeading';

import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';


const CardArray = (props) => {
    const { title, cards, isUnitRoot, currentNavigationPath } = props;

    // Manage window size
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= globalSettings.smallScreenWidth;

    // Styles
    const components = componentStyles(isSmallScreen);

    // Add default values to each element in the list of objects 'card'
    const addDefaultValues = (card) => {
        return {
            ...card,
            backgroundColor: colors[card.color] || colors.gray5,
            tagList: card.tagList || [],
            isDisabled: card.isDisabled || false,
        };
    };
    const updatedCards = cards.map(addDefaultValues);

    // Receives text input from SectionHeading
    const [userInput, setUserInput] = useState('');

    // Compute the custom grid style based on the number of updatedCards and screen size
    let gridTemplateColumns;
    let justifyContent;
    // Case less than 3 cards in either large or small screens
    if (updatedCards.length < 3) {

        if (isSmallScreen) {

            // Case of small screen

            if (updatedCards.length === 1 && windowSize.width < 645) {
                // console.log("Small screen, 1 card exactly, space for 1 card", windowSize.width, '<', globalSettings.smallScreenWidth + 1);
                gridTemplateColumns = 'repeat(1, 1fr)';
                justifyContent = 'start';
            }  else if (updatedCards.length === 1 && windowSize.width < globalSettings.smallScreenWidth + 1) {
                // console.log("Small screen, 1 card exactly, space for 2 card", windowSize.width, '<', globalSettings.smallScreenWidth + 1);
                gridTemplateColumns = 'repeat(2, 1fr)';
                justifyContent = 'start';
            } else if (updatedCards.length === 2 && windowSize.width < 645) {
                // console.log("Small screen, 2 cards exactly, space for 1 card");
                gridTemplateColumns = 'repeat(1, 1fr)';
                justifyContent = 'start';
            } else {
                // console.log("Small screen, in all cases we take one card per row taking all available space");
                gridTemplateColumns = `repeat(${updatedCards.length}, 1fr)`;
                justifyContent = 'center';
            }


        } else {

            // Case of large screen

            if (updatedCards.length === 1 && windowSize.width < 985) {
                // console.log("Not a small screen, 1 card exactly, space for 2 card");
                gridTemplateColumns = 'repeat(2, 1fr)';
                justifyContent = 'start';

            } else if (updatedCards.length === 1) {
                // console.log("Not a small screen, 1 card exactly, space for 3 card");
                gridTemplateColumns = 'repeat(3, 1fr)';
                justifyContent = 'start';

            } else if (updatedCards.length === 2 && windowSize.width < 985) {
                // console.log("Not a small screen, 2 cards excatly, there is space for more than 2 cards but less than three");
                gridTemplateColumns = 'repeat(2, minmax(300px, 1fr))';
                justifyContent = 'start';

            } else if (updatedCards.length === 2) {
                // console.log("Not a small screen, 2 cards excatly, there is plenty of space");
                gridTemplateColumns = 'repeat(3, minmax(300px, 1fr))';
                justifyContent = 'start';
            }
        }
    } else {

        // 3 or more cards in either large or small screens

        // console.log("3 or more cards, using auto-fit for flexible layout");
        gridTemplateColumns = 'repeat(auto-fit, minmax(300px, 1fr))';
        justifyContent = 'center';
    }

    // Update customTileGridStyle
    const customTileGridStyle = {
        ...components.tileGridStyle,
        gridTemplateColumns,
        justifyContent,
    };


    return (
        <div>

            <SectionHeading
                title={title}
                setUserInput={setUserInput} />

            <div style={customTileGridStyle}>
                {updatedCards
                    .filter(square =>
                        square.description.toLowerCase().includes(userInput.toLowerCase()) ||
                        square.title.toLowerCase().includes(userInput.toLowerCase())
                    )
                    .map((square, index) => {
                        return (
                            <CardLink
                                key={index}
                                index={index}
                                to={square.path}
                                title={square.title}
                                description={square.description}
                                isUnitRoot={isUnitRoot}
                                backgroundColor={square.backgroundColor}
                                tagList={square.tagList}
                                isDisabled={square.isDisabled}
                                currentNavigationPath={currentNavigationPath}
                            >
                                {square.title}
                            </CardLink>
                        );
                    })}
            </div>

        </div>
    );
}

export default CardArray;
