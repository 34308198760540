// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import { fontStyles } from 'components/styles/fonts/fonts';

import React, { useState } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'

import { useNavigate } from 'react-router-dom';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';

import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';

import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

import WelcomeBanner from 'components/pages/navigation/WelcomeBanner';
import WelcomeFooter from 'components/pages/navigation/WelcomeFooter';
import SnackBarSingle from 'components/pages/navigation/SnackBarSingle';
import SnackBarArray from 'components/pages/navigation/SnackBarArray';
import CardsCoverPage from 'components/pages/navigation/CardsCoverPage';
import TableComponent from './TableComponent';

import TextInputComponent from './TextInputComponent';

export function Test03() {
    const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);

    // Read clipboard
    const { clipboard, setClipboard } = useTopBarCLipboardContext();
    // const notifications = clipboard.notifications;

    // To navigate to other pages (button Dashboard requires this)
    const navigate = useNavigate();

    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Fixed examples of snack bar arrays
    const snackBars = [
        { title: 'Multiple 1', subtitle: 'Subtitle 1', color: 'lightgreen', canBeClosed: true, symbol: 'check' },
        { title: 'Multiple 2', subtitle: 'Subtitle 2', color: 'lightblue', canBeClosed: true, symbol: 'warning' },
        { title: 'Multiple 3', subtitle: 'Without icon', color: 'lightgray', canBeClosed: true },
        { title: 'Multiple 4', subtitle: 'Without closing, persistent message', color: '#FFDAB9', canBeClosed: false, symbol: 'bell' },
    ];

    const headers = ['Name', 'Age', 'Country', 'Occupation'];

    // Example rows
    const rows = [
        { Name: 'John', Age: 25, Country: 'USA', Occupation: 'Engineer' },
        { Name: 'Alice', Age: 30, Country: 'Canada', Occupation: 'Designer' },
        { Name: 'Bob', Age: 22, Country: 'UK', Occupation: 'Student' },
        { Name: 'Eva', Age: 28, Country: 'Germany', Occupation: 'Developer' },
    ];

    const inputTextStyle = {

    }

    //Styles
    const styles = componentStyles(isSmallScreen);

    return (
        <div className="app">

            <WelcomeBanner />

            <main>
                <div style={virtualFullWidth}>
                    <div style={availableWidth}>

                        <SnackBarSingle
                            title='Single Message'
                            subtitle='Just testing messages'
                            color='lightblue'
                            symbol='bell'
                            canBeClosed={true} />

                        <div>Below is an array of messages</div>

                        <SnackBarArray list={snackBars} />

                        <div>Below is WIP CardsCoverPage</div>

                        <CardsCoverPage title='some text here' />

                        <div>Below WIP TableComponent</div>

                        <TableComponent headers={headers} rows={rows} showGrid={true} />

                        <div style={inputTextStyle}>
                            <TextInputComponent style={inputTextStyle} />
                        </div>

                        <div style={inputTextStyle}>
                            <TextInputComponent style={inputTextStyle} />
                        </div>

                        {/* AMPLIFY In the Input box below, full styles must be provided as we no longer use the Amplify's presets such as 'variation primary', etc. */}
                        <input
                            name='filterSelection'
                            placeholder='Filter'
                            label='Word'
                            // labelHidden
                            variation='primary'
                            required

                            style={{ position: 'absolute', top: '100%', left: 0 }}
                        />

                        {/* AMPLIFY In the button below, full styles must be now provided as we do not depend in Amplify 'variation primary' etc. */}
                        <div style={{ marginTop: 50, marginBottom: 50 }}>
                            <button
                                onClick={
                                    () => {
                                        navigate(route === 'authenticated' ? '/dashboard' : '/login');
                                    }
                                }
                                type='submit'
                                variation='primary'
                                style={{ width: '10rem' }}
                            >
                                {route === 'authenticated' ? 'Dashboard' : 'Login'}
                            </button>
                        </div>

                        <div style={styles.simpleBarStyle}>
                            <div style={{ ...styles.simpleBarStyle, fontWeight: 900 }}>
                                <div>Sample text for fontStyle </div>
                            </div>
                            <div style={{ ...styles.simpleBarStyle, fontWeight: 800 }}>
                                <div>Sample text for fontStyle </div>
                            </div>
                            <div style={{ ...styles.simpleBarStyle, fontWeight: 700 }}>
                                <div>Sample text for fontStyle </div>
                            </div>
                            <div style={{ ...styles.simpleBarStyle, fontWeight: 600 }}>
                                <div>Sample text for fontStyle </div>
                            </div>
                            <div style={{ ...styles.simpleBarStyle, fontWeight: 500 }}>
                                <div>Sample text for fontStyle </div>
                            </div>
                            <div style={{ ...styles.simpleBarStyle, fontWeight: 400 }}>
                                <div>Sample text for fontStyle </div>
                            </div>
                            <div style={{ ...styles.simpleBarStyle, fontWeight: 300 }}>
                                <div>Sample text for fontStyle </div>
                            </div>
                            <div style={{ ...styles.simpleBarStyle, fontWeight: 200 }}>
                                <div>Sample text for fontStyle </div>
                            </div>
                            <div style={{ ...styles.simpleBarStyle, fontWeight: 100 }}>
                                <div>Sample text for fontStyle </div>
                            </div>

                        </div>

                    </div>
                </div>
            </main>

            <WelcomeFooter />

        </div >
    )
}
