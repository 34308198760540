import React, { useState, useEffect } from 'react';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

const logo = require('img/home/home.jpg');

const CoverPageTile = (props) => {
    const { title, order = ['component1', 'component2'], Component1, Component2 } = props;

    const windowSize = useWindowSize();
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        setIsSmallScreen(windowSize.width <= 768);
    }, [windowSize.width]);

    // Prepare components content based on props and isSmallScreen state
    const componentsContent = {
        component1: <Component1 isSmallScreen={isSmallScreen} text={"Image"} width='auto' />,
        component2: <Component2 isSmallScreen={isSmallScreen} text={"Let's make this a rather long text now..."} width='auto' />,
    };

    // Determine the order of components based on the screen size and provided order prop
    const orderedComponents = order.map(type => ({
        type,
        component: componentsContent[type],
    }));

    return (
        <div style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'center', alignItems: 'center', width: 'auto' }}>
            {orderedComponents.map((component, index) => (
                <div width='auto' key={index}>
                    {component.component}
                </div>
            ))}
        </div>
    );
};

export default CoverPageTile;
