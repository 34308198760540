// Colors, styles etc.
import { colors } from 'components/styles/colors';

const TableStyles = (isSmallScreen, isGridVisible) => {

    return {

        //Table styles
        tableStyleBasic:
        {
            borderCollapse: isGridVisible ? 'collapse' : 'separate',
            width: '100%',
        },

        tableHeaderStyleBasic:
        {
            borderBottom: isGridVisible ? `2px solid ${colors.gray4}` : 'none',
            padding: isSmallScreen ? 4 : 6,
            backgroundColor: colors.white,
            textAlign: 'left',
            fontSize: isSmallScreen ? 14 : 18,
        },

        tableCellStyleBasic:
        {
            borderBottom: isGridVisible ? `1px solid ${colors.gray6}` : 'none',
            padding: isSmallScreen ? 4 : 6,
            marginRight: isSmallScreen ? 16 : 20,
            backgroundColor: colors.white,
            textAlign: 'left',
            fontSize: isSmallScreen ? 14 : 18,
            alignItems: 'center', // Vertically center content
        },

        tableCellStyleHighlighted:
        {
            borderBottom: isGridVisible ? `1px solid ${colors.gray6}` : 'none',
            padding: isSmallScreen ? 4 : 6,
            backgroundColor: colors.gray6,
            textAlign: 'left',
            fontSize: isSmallScreen ? 14 : 18,
            alignItems: 'center', // Vertically center content
        },

        tableCellIconStyleBasic:
        {
            height: isSmallScreen ? 16 : 20,
            width: isSmallScreen ? 16 : 20,
            marginRight: isSmallScreen ? 6 : 9,
            verticalAlign: 'middle',
        },

    };
};

export const tableStyles = TableStyles;

