// MessageBox Component

// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { globalSettings } from 'GlobalSettings';

//Main compoment
const MessageBox = ({ msgBoxType, isVisible, title, message, onOK, onCancel, onYes, onNo }) => {

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= globalSettings.smallScreenWidth;

    // Control the hovering effect
    const [isHovered, setIsHovered] = useState(false);

    // Styles
    const styles = componentStyles(isSmallScreen);
    const paragraph = paragraphStyles(isSmallScreen);

    // Handle the actions
    const handleOK = () => { onOK(); };
    const handleCancel = () => { onCancel(); };
    const handleYes = () => { onYes(); };
    const handleNo = () => { onNo(); };

    if (!isVisible) return null;

    const renderButtonSet = () => {
        switch (msgBoxType) {

            case 'msgBoxOK':
                return <div>
                    <button style={styles.msgBoxButtonStyle} onClick={handleOK}>OK</button>
                </div>;

            case 'msgBoxOKCancel':
                return <div>
                    <button style={styles.msgBoxButtonStyle} onClick={handleOK}>OK</button>
                    <button style={styles.msgBoxButtonStyle} onClick={handleCancel}>Cancel</button>
                </div>;

            case 'msgBoxYesNo':
                return <div>
                    <button style={styles.msgBoxButtonStyle} onClick={handleYes}>Yes</button>
                    <button style={styles.msgBoxButtonStyle} onClick={handleNo}>No</button>

                </div >;

            case 'msgBoxYesNoCancel':
                return <div>
                    <button style={styles.msgBoxButtonStyle} onClick={handleYes}>Yes</button>
                    <button style={styles.msgBoxButtonStyle} onClick={handleNo}>No</button>
                    <button style={styles.msgBoxButtonStyle} onClick={handleCancel}>Cancel</button>
                </div >;
            default:
                return <div>
                    <button style={styles.msgBoxButtonStyle} onClick={handleOK}>OK</button>
                </div>;
        }
    }

    return (
        <div style={styles.msgBoxStyle}>
            <div style={styles.simpleBarStyle}            >
                {title}
            </div>
            <div style={styles.simpleTextStyle}>
                {message}
            </div>
            <div>
                {renderButtonSet()}
            </div>
        </div >
    );
};

export default MessageBox;