import { useMemo, useEffect, useState } from 'react'
import { fetchStorage } from 'components/_common/amplify/fetchStorage'

// Global audio player status to track if any audio is playing across the application
const audioPlayerStatus = {
    isAudioPlaying: false
};

const useAudioPlayer = () => {

    // console.log('[] audioPlayerStatus.isAudioPlaying:', audioPlayerStatus.isAudioPlaying)

    const audio = useMemo(() => new Audio(), []);
    const [audioCache, setAudioCache] = useState(new Map());
    const [isCleanupPending, setIsCleanupPending] = useState(false);

    // Handles changes of 'isCleanupPending'
    useEffect(() => {

        // Deals with what to do when audio ends
        const handleAudioEnd = () => {
            // Clear isAudioPlaying and isCleanupPending when audio ends
            setTimeout(() => {
                audioPlayerStatus.isAudioPlaying = false;
                setIsCleanupPending(false);
                // console.log('End of audio request');
            }, 1000); // extra wait of 1 second before declaring isAudioPlaying false
        };
        audio.addEventListener('ended', handleAudioEnd);

        // Clean up timeouts and event listeners on unmount
        return () => { // 'return' executes at the end of the whole component
            audio.removeEventListener('ended', handleAudioEnd);
            if (isCleanupPending) {
                audioPlayerStatus.isAudioPlaying = false;
                // console.log('Clean up timeouts and event listeners on unmount')
            }
        };

    }, [isCleanupPending]); // skipped 'audio' as a dependency

    // Main function
    const playAudio = async (word) => {

        // Do not proceed if audio is playing
        if (audioPlayerStatus.isAudioPlaying) {
            console.warn('Audio is already playing...');
            return;
        } else {
            // Declare that from now on, audio is playing
            audioPlayerStatus.isAudioPlaying = true;
        }

        // Mark component as it requires clean up
        setIsCleanupPending(true);

        // Fetch audio file and insert it to cache
        const audioKey = `mp3/${word}.mp3`;
        if (!audioCache.has(audioKey)) {
            try {
                const url = await fetchStorage(audioKey);
                audioCache.set(audioKey, url);
                setAudioCache(new Map(audioCache));
            } catch (error) {
                console.error('Error fetching audio:', error);
                audioPlayerStatus.isAudioPlaying = false;
                return;
            }
        }

        // Play audio file from cache
        audio.src = audioCache.get(audioKey);
        audio.play().catch((error) => {
            console.error('Error playing audio:', error);
            audioPlayerStatus.isAudioPlaying = false;
        });

    };
    // Execute main function
    return { playAudio, isAudioPlaying: audioPlayerStatus.isAudioPlaying };

};

export default useAudioPlayer;
