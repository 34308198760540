// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { icons } from 'img/icons';
import idioms from 'locales/idioms_static';

// Basic core functions
import React, { useState } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

const SectionHeading = (props) => {
    const { title, setUserInput } = props;

    // Image definitions
    const icon_search = require('img/icons/symbols/black/icon_search.png');
    const iconStyle = { cursor: 'pointer', maxWidth: 32, maxHeight: 32 };

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const paragraphs = paragraphStyles(isSmallScreen);
    const styles = componentStyles(isSmallScreen);

    // Event handler to update filterSelection when TextField changes
    const [filterSelection, setFilterSelection] = useState('');
    const handleFilterSelectionChange = (event) => {
        const newFilterSelection = event.target.value.toLowerCase();
        setFilterSelection(newFilterSelection);
        setUserInput(newFilterSelection); // Use the updated value
    };

    // State to manage the visibility of the TextField
    const [isTextFieldVisible, setIsTextFieldVisible] = useState(false);
    const toggleTextField = () => {
        setIsTextFieldVisible(!isTextFieldVisible);
    };

    return (
        <div style={styles.sectionHeadingMainDivStyle}>
            <div style={styles.sectionHeadingMainFlexStyle}>

                <div style={paragraphs.sectionHeadingTitle}                >
                    {title}
                </div>

                <div style={styles.sectionHeadingIconStyle}>
                    <img
                        src={icon_search}
                        alt="Search Icon"
                        onClick={toggleTextField}
                        style={iconStyle}
                    />
                </div>

            </div>

            <div style={{ padding: isTextFieldVisible ? 18 : 0 }}>
                {isTextFieldVisible && (
                    <input
                        name='filterSelection'
                        placeholder='Search'
                        label='Word'
                        required
                        value={filterSelection}
                        onChange={handleFilterSelectionChange}
                        style={styles.sectionHeadingInputStyle}
                    />
                )}
            </div>

        </div >
    );
}

export default SectionHeading;
