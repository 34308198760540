// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { textStyles } from 'components/styles/text_styles';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import useAudioPlayer from 'components/_common/AudioPlayer';

const WordBox = ({ title, onCorrectChange }) => {

    // Icon image file definitions
    const icon_correct = require('img/icons/symbols/colour/icon_correct_green.png');
    const icon_incorrect = require('img/icons/symbols/colour/icon_incorrect_red.png');
    const icon_unknown = require('img/icons/symbols/colour/icon_unknown_black.png');

    // Audio
    const { playAudio } = useAudioPlayer();

    // Control the hovering effect
    const [isHovered, setIsHovered] = useState(false);

    // Control clicking on the bar
    const [isCorrect, setIsCorrect] = useState('unknown');

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const styles = componentStyles(isSmallScreen, isHovered);

    // Handle clicks
    const toggleState = () => {
        setIsCorrect(prevState => {
            if (prevState === 'unknown') {
                const newState = 'correct';
                onCorrectChange(newState);
                return 'correct';
            }
            const newState = prevState === 'correct' ? 'incorrect' : 'correct';
            onCorrectChange(newState);
            return newState;
        });
    };

    const determineIcon = () => {
        if (isCorrect === 'correct') return icon_correct;
        if (isCorrect === 'incorrect') return icon_incorrect;
        return icon_unknown;
    };

    return (
        <div
            style={styles.wordBoxContainerStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >

            <div
                style={styles.wordBoxMainBlockStyle}
                onClick={toggleState}>

                <div style={{ ...styles.wordBoxTextWordStyle, cursor: 'pointer' }}
                    onClick={() => { playAudio(title); }}>
                    {title}
                </div>

                <div style={styles.workBoxIconAreaStyle}>
                    <img
                        src={determineIcon()}
                        alt="Status Icon"
                        style={styles.wordBoxIconStyle}
                    />
                </div>

            </div>

        </div>
    );
};

export default WordBox;
