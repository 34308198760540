export const generateQuestions = () => {

    const MAX_ANSWER = 10;
    const MIN_ANSWER = 2;
    const MAX_DIVIDEND = 100;
    const MIN_DIVISOR = 2;
    const iterations = 100; // Attempt to generate 100 questions

    const questionSet = new Set(); // Use a Set to ensure question uniqueness

    for (let i = 0; i < iterations; i++) {
        // Generate a random answer between MIN_ANSWER and MAX_ANSWER
        const answer = Math.floor(Math.random() * (MAX_ANSWER - MIN_ANSWER + 1)) + MIN_ANSWER;

        // Generate a random divisor, ensuring it is at least MIN_DIVISOR
        // and adjusting the maximum divisor to stay within bounds
        const maxDivisor = Math.min(MAX_DIVIDEND / answer, MAX_ANSWER);
        const divisor = Math.floor(Math.random() * (maxDivisor - MIN_DIVISOR + 1)) + MIN_DIVISOR;

        // Calculate the dividend
        const dividend = answer * divisor;

        // Format the question as a string to facilitate uniqueness checking
        const question = `${dividend} ÷ ${divisor} = _`;

        // Add the question to the Set if it's not already present
        questionSet.add(question);
    }

    const questionList = Array.from(questionSet).map(questionString => {
        // Split by ' ÷ ' to separate the dividend and divisor, and then parse each as a number
        const parts = questionString.split(' ÷ ');
        const dividend = Number(parts[0]);
        const divisor = Number(parts[1].split(' = ')[0]);

        // Calculate the answer
        const answer = dividend / divisor;

        return { question: questionString, answer };
    });


    return questionList; // The final length might be below 100 due to uniqueness constraint
};
