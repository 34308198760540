// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import idioms from 'locales/idioms_static';

// Basic core functions
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// Basic navigation elements
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';

// Backend connection
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';

// Support functions
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { globalSettings } from 'GlobalSettings';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

// Generic components and blocks
import SectionHeadingToolbar from 'components/_common/SectionHeadingToolbar';
import PageTitleActivity from 'components/pages/navigation/PageTitleActivity';
import DashboardFooter from 'components/pages/navigation/DashboardFooter';

// Page-specific components and clocks
import StateQuiz from 'components/pages/math/_QuizTools/StateQuiz';
import StateReview from 'components/pages/math/_QuizTools/StateReview';

const default_settings = {
  activityTopBarTitle: 'Math',
  activitySubTitle: 'Cermat',
  activityTitle: 'Written arithmetic',
  backendCallTag: 'math_5th_writtenArithmetic',
  instructions: 'Select the right answer from the list below. Select your answer and you will see your result immediately. You can review all your answers after clicking on the Review button. When reviewing, you can see the original questions by clicking on the respective row in the table.',
  leftButtons: [
    {
      title: 'Back',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/dashboard?pk=ACTIVITY&sk=MAT~MC~',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
    },
  ],
  rightButtons: [
    {
      title: 'Logout',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/logout',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
    }
  ],
}


export function ExercisesMathWrittenArithmetic({ settings = default_settings }) {

  // Language selection for static texts
  const [activeLanguage, setActiveLanguage] = useState('en');
  const currentTexts = idioms[activeLanguage];

  // extract query parameters from url
  const [searchParams] = useSearchParams();
  const difficulty = searchParams.get('difficulty');

  // Read clipboard
  const { clipboard } = useTopBarCLipboardContext();
  const userClassroom = clipboard.userClassroom.value;
  const unitId = clipboard.unitId.value;

  const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  };

  // Read backend
  const [backendData, setBackendData] = useState([]);
  const [backendDataExtended, setBackendDataExtended] = useState({});
  useEffect(() => {
    const requestData = {
      task: settings.backendCallTag,
      range: userClassroom + '~' + unitId + '~',
    };
    const fetchData = async () => {
      const data = await fetchBackendData(requestData);
      if (data) {
        const shuffledData = shuffleArray([...data.questions]);
        setBackendData(shuffledData.slice(0, 10));
        setBackendDataExtended(data.extended)
      }
    };
    fetchData();
  }, []);

  // Set-up top bar
  useNavigationConfig(null, null, settings.leftButtons, settings.rightButtons);

  // Use the useLayoutEffect hook to scroll to the top after the component is rendered
  useScrollToTop();
  // useDebugClipboard(clipboard);

  // Responsiveness
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= 768;

  // Control the hovering effect
  // const [isHovered, setIsHovered] = useState(false);

  // Styles
  const styles = componentStyles(isSmallScreen);

  // Handle settings
  const [userInput, setUserInput] = useState('');

  // Navigation settings
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentState, setCurrentState] = useState('QUIZ');
  const [answerList, setAnswerList] = useState([]);
  const [score, setScore] = useState(0);

  return (

    <div className="app">

      <PageTitleActivity
        title={default_settings.activityTopBarTitle + " | " + default_settings.activitySubTitle}
        subtitle={globalSettings.clientName} />

      <main>
        <div style={virtualFullWidth}>
          <div style={availableWidth}>

            <SectionHeadingToolbar
              title={settings.activityTitle}
              setUserInput={setUserInput}
              infoText={settings.instructions}
              activeLanguage={activeLanguage}
              setActiveLanguage={setActiveLanguage}
            />

            {currentState === 'QUIZ' &&
              <StateQuiz currentIndex={currentIndex} backendData={backendData} backendDataExtended={backendDataExtended} setCurrentState={setCurrentState} setCurrentIndex={setCurrentIndex} setAnswerList={setAnswerList} score={score} setScore={setScore} />
            }

            {currentState === 'REVIEW' && (
              <StateReview answerList={answerList} setAnswerList={setAnswerList} setCurrentState={setCurrentState} score={score} setScore={setScore} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} backendData={backendData} setBackendData={setBackendData} />
            )}

          </div>
        </div>
      </main>

      <DashboardFooter />

    </div >
  );
}