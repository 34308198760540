export const getUpdates = `
    query getUpdates($id: String!, $range: String!) {
        getWords(id: $id, range: $range) {
        currentRelease
        description
        }
    }
    `

export const getUserConsent = `
    query getUserConsent($id: String!, $range: String!) {
        getWords(id: $id, range: $range) {
        isConsent,
        dateConsent
        }
    }
    `

export const getSentenceQuery = `
    query getSentenceQuery($id: String!, $range: String!) {
        getWords(id: $id, range: $range) {
        sentence
        definition
        question
        explanation
        synonyms
        }
    }
    `

export const listWordsQueryRanged = `
    query listWordsQueryRanged($id: String!, $range: String!) {
        listWords(id: $id, range: {beginsWith: $range}) {
        items {
            id,
            range,
            name,
            classes
        }
        }
    }
    `

export const listQuestionsQueryRanged = `
    query listQuestionsQueryRanged($id: String!, $range: String!, $limit: Int!) {
        listWords(range: {beginsWith: $range}, id: $id, limit: $limit) {
        items {
            id,
            range,
            question,
            description,
            correctAnswer,
            wrongAnswers,
            difficulty,
            explanation
        }
        }
    }
    `

export const listQuestionsQueryRangedTranslation = `
    query listQuestionsQueryRangedTranslation($id: String!, $range: String!, $limit: Int!) {
        listWords(range: {beginsWith: $range}, id: $id, limit: $limit) {
        items {
            id,
            range,
            question,
        }
        }
    }
    `

export const listWordsQuery = `
    query listWordsQuery($id: String!) {
        listWords(range: {}, id: $id, limit: 500) {
        items {
            id,
            range,
            name,
            classes
        }
        }
    }
    `

export const createWordMutation = `
    mutation createWordMutation($id: String!, $range: String!, $name: String, $classes: String, $author: String, $unitId: String) {
        createWords(input: { id: $id, range: $range, name: $name, classes: $classes, author: $author, unitId: $unitId }) {
        id,
        range
        }
    }
    `

export const updateConsentMutation = `
    mutation updateConsentMutation($id: String!, $range: String!, $isConsent: Boolean) {
        updateWords(input: { id: $id, range: $range, isConsent: $isConsent }) {
        id
        }
    }
    `

export const deleteWordMutation = `
    mutation deleteWordMutation($id: String!, $range: String!) {
        deleteWords(input: { id: $id, range: $range }) {
        id
        }
    }
    `
