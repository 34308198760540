import React from 'react';
import SnackBarSingle from 'components/pages/navigation/SnackBarSingle';

const SnackBarArray = ({ list }) => {
    return (
        <div>
            {list.map((item, index) => (
                <SnackBarSingle
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    color={item.color}
                    canBeClosed={item.canBeClosed}
                    symbol={item.symbol}
                />
            ))}
        </div>
    );
}

export default SnackBarArray;
