export const generateQuestions = () => {
    const numQuestions = 200; // Number of questions to generate
    const seriesLength = 5; // Length of each series
    const maxStartingNumber = 10; // Maximum starting number for the linear series
    const maxSlope = 5; // Maximum slope value for the linear series
    const minSlope = -5; // Minimum slope value for the linear series
    const notAllowedSlopes = [-1, 0, 1]; // Slopes that are not allowed

    // Define notable series
    const notableSeries = [
        { question: '0, 1, 1, 2, 3, ...', answer: 5 }, // Fibonacci series
        { question: '2, 4, 6, 8, 10, ...', answer: 12 }, // Arithmetic Progression (Common difference of 2)
        { question: '1, 2, 4, 8, 16, ...', answer: 32 }, // Geometric Progression (Common ratio of 2)
        { question: '1, 4, 9, 16, 25, ...', answer: 36 }, // Square Numbers
        { question: '1, 8, 27, 64, 125, ...', answer: 216 }, // Cube Numbers
        { question: '2, 3, 5, 7, 11, ...', answer: 13 }, // Prime Numbers
        { question: '1, 3, 6, 10, 15, ...', answer: 21 }, // Triangular Numbers
        { question: '1, 2, 6, 24, 120, ...', answer: 720 }, // Factorial Numbers
        { question: '1, 3, 9, 27, 81, ...', answer: 243 }, // Powers of 3
        { question: '1, 1, 2, 3, 5, ...', answer: 8 }, // Fibonacci Sequence (Starting from 1, 1)
        { question: '2, 4, 6, 8, 10, ...', answer: 12 }, // Even Numbers
        { question: '1, 3, 5, 7, 9, ...', answer: 11 }, // Odd Numbers
        { question: '1, 10, 100, 1000, 10000, ...', answer: 100000 }, // Powers of 10
        { question: '2, 5, 10, 17, 26, ...', answer: 37 }, // Perfect Squares plus one
        { question: '0, 1, 4, 9, 16, ...', answer: 25 }, // The sequence of integers squared
        { question: '0, 1, 8, 27, 64, ...', answer: 125 }, // The sequence of integers cubed
        { question: '1, 1, 2, 5, 14, ...', answer: 42 }, // Pascal's Triangle Diagonal
        { question: '1, -2, 3, -4, 5, ...', answer: -6 }, // Alternating Signs (Starting with positive)
        { question: '7, 14, 21, 28, 35, ...', answer: 42 }, // Multiples of 7
        { question: '0, 1, 3, 7, 15, ...', answer: 31 }, // Powers of 2 minus 1
        { question: '2, 3, 5, 7, 11, ...', answer: 13 }, // Consecutive Prime Numbers
        { question: '1, 4, 9, 16, 25, ...', answer: 36 }, // Square Numbers
        { question: '1, 8, 27, 64, 125, ...', answer: 216 }, // Cube Numbers
        { question: '1, 1, 2, 3, 5, ...', answer: 8 }, // Fibonacci Sequence
        { question: '1, 2, 4, 8, 16, ...', answer: 32 }, // Powers of 2
        { question: '1, 3, 6, 10, 15, ...', answer: 21 }, // Triangular Numbers
        { question: '2, 4, 8, 16, 32, ...', answer: 64 }, // Powers of 2 starting from 2
        { question: '3, 6, 12, 24, 48, ...', answer: 96 }, // Doubling starting from 3
        { question: '1, 10, 100, 1000, 10000, ...', answer: 100000 }, // Powers of 10
        { question: '5, 10, 20, 40, 80, ...', answer: 160 }, // Doubling starting from 5
        { question: '1, 2, 3, 5, 8, ...', answer: 13 }, // Sum of previous two, starting differently
        { question: '2, 6, 18, 54, 162, ...', answer: 486 }, // Triple each time
        { question: '3, 9, 27, 81, 243, ...', answer: 729 }, // Powers of 3
        { question: '4, 16, 64, 256, 1024, ...', answer: 4096 }, // Powers of 4
        { question: '1, 4, 16, 64, 256, ...', answer: 1024 }, // Powers of 4, starting from 1
        { question: '2, 10, 50, 250, 1250, ...', answer: 6250 }, // Multiply by 5 each time
        { question: '3, 12, 48, 192, 768, ...', answer: 3072 }, // Quadruple each time
        { question: '1, 3, 9, 27, 81, ...', answer: 243 }, // Powers of 3, starting from 1
        { question: '5, 25, 125, 625, 3125, ...', answer: 15625 }, // Powers of 5
        { question: '11, 22, 44, 88, 176, ...', answer: 352 }, // Doubling starting from 11
        { question: '4, 8, 16, 32, 64, ...', answer: 128 }  // Doubling starting from 4
    ];


    const questionList = [];

    for (let i = 0; i < numQuestions; i++) {
        // Randomly decide whether to use a notable series or a linear series
        if (Math.random() < 0.25) {
            // Choose a notable series
            const seriesIndex = Math.floor(Math.random() * notableSeries.length);
            questionList.push(notableSeries[seriesIndex]);
        } else {
            // Generate a linear series
            const start = Math.floor(Math.random() * maxStartingNumber) + 1;
            let slope;

            do {
                slope = Math.floor(Math.random() * (maxSlope - minSlope + 1)) + minSlope;
            } while (notAllowedSlopes.includes(slope));

            let series = [];
            let currentNumber = start;
            for (let j = 0; j < seriesLength; j++) {
                series.push(currentNumber);
                currentNumber += slope;
            }

            const answer = series[series.length - 1] + slope;
            let question = `${series.join(", ")}, ...`;
            questionList.push({ question, answer });
        }
    }

    return questionList;
};
