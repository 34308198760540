// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import idioms from 'locales/idioms_static';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { globalSettings } from 'GlobalSettings';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

// Components and blocks
import PageTitleActivity from 'components/pages/navigation/PageTitleActivity';
import SectionHeadingToolbar from 'components/_common/SectionHeadingToolbar';
import WordBox from 'components/pages/languages/_WordsOverview/WordBox';
import DashboardFooter from 'components/pages/navigation/DashboardFooter';

const default_settings = {
  activityTitle: 'Overview',
  leftButtons: [
    {
      title: 'Back',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/dashboard?pk=ACTIVITY&sk=ENG~',
      visibleIfAuthenticated: true,
      visibleIfNotAutheYnticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
    },
  ],
  rightButtons: [
    {
      title: 'Logout',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/logout',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
    }
  ]
}

export function WordsOverview({ settings = default_settings }) {
  // Language selection for static texts
  const [activeLanguage, setActiveLanguage] = useState('en');
  const currentTexts = idioms[activeLanguage];
  // Static texts aliases
  const instructions = currentTexts.language_instructions.word_overview;

  // Read clipboard
  const { clipboard } = useTopBarCLipboardContext();
  const userClassroom = clipboard.userClassroom.value;
  const unitId = clipboard.unitId.value;

  // Read backend
  const [backendData, setBackendData] = useState([]);
  const [backendDataCache, setBackendDataCache] = useState([]);
  const [backendDefaultLanguage, setBackendDefaultLanguage] = useState('en');
  const [backendMotherTongue, setBackendMotherTongue] = useState('cz');
  useEffect(() => {
    const requestData = {
      task: 'languageOverviewWords',
      range: userClassroom + '~' + unitId + '~',
      language: backendDefaultLanguage,
      motherTongue: backendMotherTongue,
    };
    const fetchData = async () => {
      const data = await fetchBackendData(requestData);
      if (data) { setBackendDataCache(data); }
      if (data) { setBackendData(data[backendDefaultLanguage]); }
    };
    fetchData();
  }, [userClassroom, unitId, backendDefaultLanguage, backendMotherTongue]);
  // Change Language (without backend call but altering backendData)
  useEffect(() => {
    if (backendDataCache && backendDataCache[activeLanguage]) {
      setBackendData(backendDataCache[activeLanguage]);
    }
  }, [activeLanguage, backendDataCache]);

  // Set-up top bar
  useNavigationConfig(null, null, settings.leftButtons, settings.rightButtons);

  // Use the useLayoutEffect hook to scroll to the top after the component is rendered
  useScrollToTop();
  // useDebugClipboard(clipboard);

  // Responsiveness
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= globalSettings.smallScreenWidth;

  // Control the hovering effect
  const [isHovered, setIsHovered] = useState(false);

  // Styles
  const styles = componentStyles(isSmallScreen, isHovered);

  // Handle settings
  const [userInput, setUserInput] = useState('');

  // Swipe left right logic
  const [touchStart, setTouchStart] = useState(null);
  // const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 100;
  const handleTouchStart = (event) => {
    // setTouchEnd(null);
    setTouchStart(event.targetTouches[0].clientX);
  };
  const handleTouchEnd = (event) => {
    const touchEndValue = event.changedTouches[0].clientX;
    // setTouchEnd(touchEndValue);
    if (touchStart - touchEndValue > minSwipeDistance) {
      setActiveLanguage('cz')
    }
    if (touchEndValue - touchStart > minSwipeDistance) {
      setActiveLanguage('en')
    }
  };

  return (

    <div className="app">

      <PageTitleActivity
        title={default_settings.activityTitle + " | " + globalSettings.courseNameEN}
        subtitle={globalSettings.clientName + " | " + userClassroom.split('-')[0]} />

      <main>
        <div style={virtualFullWidth}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onDoubleClick={() => setActiveLanguage(activeLanguage === 'en' ? 'cz' : 'en')}>
          <div style={availableWidth}>

            <SectionHeadingToolbar
              title={settings.activityTitle}
              setUserInput={setUserInput}
              infoText={instructions}
              activeLanguage={activeLanguage}
              setActiveLanguage={setActiveLanguage}
            />

            <div style={styles.mainDivStyle}>
              {Object.entries(backendData).map(([key, value], index) => (
                <WordBox key={index} title={key} data={value} />
              ))}
            </div>

          </div>
        </div>
      </main>

      <DashboardFooter />

    </div >
  );
}