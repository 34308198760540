// Following AWS language id conventions
//
// en: English
// cz: Czech

const consents = {

    'en': {
        // User consent
        user_consent_title: 'We need your consent',
        user_consent_privacy_policy: 'Privacy policy',
        user_consent_par1: 'By accepting this policy, you acknowledge that you have read the disclaimer and that you are voluntarily participating in a research and testing phase of the service. Rest assured, your privacy is our top priority. We want to emphasize the following points:',
        user_consent_par2a: 'Strict Anonymity',
        user_consent_par2b: ': Your participation in this project is strictly anonymous. We will not collect or store any personal information that can be used to identify you.',
        user_consent_par3a: 'Data Collection',
        user_consent_par3b: ': We may collect and store data related to your interactions with the service. However, this data is entirely non-personal and will be used solely for research and testing purposes.',
        user_consent_par4a: 'No Access to Personal Information',
        user_consent_par4b: ': We want to reassure you that we do not have access to any of your personal information, and we have no intention and/or interest in collecting it.',
        user_consent_par5: 'Your participation is invaluable to us, and we appreciate your trust in our commitment to safeguarding your privacy. If you have any concerns or questions about data usage, please feel free to contact us at info@discito.cz.',
    },
    'cz': {
        // User consent
        user_consent_title: 'Potřebujeme váš souhlas',
        user_consent_privacy_policy: 'Zásady ochrany osobních údajů',
        user_consent_par1: 'Přijetím těchto zásad potvrzujete, že jste si přečetli prohlášení o vyloučení odpovědnosti a že se dobrovolně účastníte fáze výzkumu a testování služby. Buďte si jisti, že vaše soukromí je naší nejvyšší prioritou. Chceme zdůraznit zejména tyto body:',
        user_consent_par2a: 'Striktní anonymita',
        user_consent_par2b: ': Vaše účast v tomto projektu je přísně anonymní.Nebudeme shromažďovat ani uchovávat žádné osobní údaje, které lze použít k vaší identifikaci.',
        user_consent_par3a: 'Shromažďování dat',
        user_consent_par3b: ': Můžeme shromažďovat a ukládat údaje související s vašimi interakcemi se službou. Tyto údaje jsou však zcela neosobní a budou použity výhradně pro účely výzkumu a testování.',
        user_consent_par4a: 'Žádný přístup k osobním informacím',
        user_consent_par4b: ': Chceme vás ujistit, že nemáme přístup k žádným vašim osobním údajům a nemáme v úmyslu a/nebo zájem je shromažďovat.',
        user_consent_par5: 'Vaše účast je pro nás neocenitelná a oceňujeme vaši důvěru v náš závazek chránit vaše soukromí. Máte-li jakékoli obavy nebo dotazy týkající se využití dat, neváhejte nás kontaktovat na adrese info@discito.cz.',

    },
};

export default consents;