import React from 'react';

const TableComponent = ({ headers, rows, showGrid = true }) => {
    const containerStyle = {
        marginLeft: 40,
        marginRight: 10,

        paddingTop: 10,
        paddingBottom: 10,
    }
    const tableStyle = {
        borderCollapse: showGrid ? 'collapse' : 'separate',
        width: '100%',
    };

    const headerStyle = {
        padding: '30px',
        border: showGrid ? '1px solid #ddd' : 'none',
        backgroundColor: 'lightgray',
        textAlign: 'right'
    }

    const cellStyle = {
        border: showGrid ? '1px solid ' : 'none',
        //padding: '10px',
        marginLeft: '20px',
        marginRight: '20px',
        paddingTop: '8px',
        paddingBottom: '8px',
        textAlign: 'left',
        backgroundcolor: '#fff',
    };

    return (
        <div style={containerStyle}>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th style={headerStyle} key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {headers.map((header, colIndex) => (
                                <td key={colIndex} style={cellStyle}>{row[header]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableComponent;
