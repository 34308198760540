import React, { useState, useEffect } from 'react';
import { fetchStorage } from 'components/_common/amplify/fetchStorage'

import { colors } from 'components/styles/colors';

const ImageComponent = ({
    wordImage,
    cacheImages,
    loadedImages,
    extension = 'png',
    imageStyles = {
        width: 150,
        height: 150,
    },
    onImageLoad,
}) => {

    const [imagePath, setImagePath] = useState('');
    const [imageLoadError, setImageLoadError] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
        const getImageUrl = async () => {
            try {
                if (wordImage && !loadedImages[wordImage]) {
                    // const randomIndex = Math.floor(Math.random() * 2); // locked to randomIndex ~ 0
                    const imageKey = `word_images/${wordImage}/0.${extension}`;
                    const imageUrl = await fetchStorage(imageKey);
                    setImagePath(imageUrl);
                    cacheImages(wordImage, imageUrl);
                } else {
                    setImagePath(loadedImages[wordImage]);
                }
            } catch (error) {
                console.error('Failed to load Image:', error);
                setImageLoadError(true);
            }
        };
        getImageUrl();
    }, [wordImage, loadedImages, cacheImages, extension]);

    const handleImageLoad = () => {
        if (onImageLoad) { // only if the handle onImageLoad is passed
            onImageLoad();
        }
        setIsImageLoaded(true);
    };

    return (
        <div>
            {imageLoadError ? (
                <div>Error loading image for {wordImage}</div>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <div style={{ ...imageStyles, backgroundColor: colors.gray6, }}>
                        <img
                            src={imagePath}
                            alt={wordImage}
                            style={{
                                ...imageStyles,
                                visibility: isImageLoaded ? 'visible' : 'hidden',
                            }}
                            onLoad={handleImageLoad}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageComponent;
