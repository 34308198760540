import React from 'react'
import ReactDOM from 'react-dom/client'

import 'components/styles/index.css'
import '@fontsource/inter';

import App from './App'

import { Amplify } from 'aws-amplify'
import config from './aws-exports'
Amplify.configure(config)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <App />
)
