// Colors, styles etc.
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // To handle responsiveness

const PageNavigationBarReview = ({ goExit, goContinue, goRepeat }) => {

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    //Styles
    const styles = componentStyles(isSmallScreen);

    return (
        <div style={styles.navigationBarStyle}>

            <button
                onClick={goExit}
                style={styles.buttonStyleNavigationBar}
            >Exit</button>

            <button
                onClick={goRepeat}
                style={styles.buttonStyleNavigationBar}
            >Repeat</button>

        </div >
    );
};

export default PageNavigationBarReview;
