import { colors } from 'components/styles/colors';
import React from 'react';

import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

const NavigationBar = ({ goToPrevious, goToNext, currentIndex, keysLength }) => {

    // Styles
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            //alignItems: "center",
            padding: 0,
            margin: 0,
        }}>

            <button
                onClick={goToPrevious}
                disabled={currentIndex === 0}
                style={{
                    textAlign: 'center', // Align text to the left
                    fontSize: isSmallScreen ? 14 : 18,
                    cursor: currentIndex === 0 ? 'default' : 'pointer',
                    color: currentIndex === 0 ? colors.gray4 : colors.light,
                    margin: isSmallScreen ? 12 : 18,
                    marginTop: 0,
                    padding: isSmallScreen ? '4px 18px' : '6px 24px',
                    backgroundColor: currentIndex === 0 ? colors.gray5 : colors.primary,
                    borderRadius: 100, //needs to be big enough to look like a pill...
                    border: 'none'
                }}
            >Previous</button>

            <div
                style={{
                    flex: 1,
                    padding: isSmallScreen ? '4px 18px' : '6px 24px',
                    textAlign: 'center',
                    fontSize: isSmallScreen ? 14 : 18,
                }}>
                {currentIndex + 1}/{keysLength}
            </div>

            <button
                onClick={goToNext}
                disabled={currentIndex === keysLength - 1}
                style={{
                    textAlign: 'center', // Align text to the left
                    fontSize: isSmallScreen ? 14 : 18,
                    cursor: currentIndex === keysLength - 1 ? 'default' : 'pointer',
                    color: currentIndex === keysLength - 1 ? colors.gray4 : colors.light,
                    margin: isSmallScreen ? 12 : 18,
                    marginTop: 0,
                    padding: isSmallScreen ? '4px 18px' : '6px 24px',
                    backgroundColor: currentIndex === keysLength - 1 ? colors.gray5 : colors.primary,
                    borderRadius: 100, //needs to be big enough to look like a pill...
                    border: 'none'
                }}
            >Next</button>

        </div >
    );
};

export default NavigationBar;
