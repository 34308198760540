// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { icons } from 'img/icons';
import idioms from 'locales/idioms_static';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

const TagList = (props) => {
    const { tagList } = props;

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const styles = componentStyles(isSmallScreen);

    return (
        <div style={styles.tagListMainDivStyle}>
            {tagList.map((tag, index) => (
                <div
                    key={index}
                    style={{ ...styles.tagListDivStyle, backgroundColor: tag.color }}
                >
                    &nbsp;{tag.text}&nbsp;
                </div>
            ))}
        </div>
    );
};

export default TagList;
