/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const CreateSentence = /* GraphQL */ `
  query CreateSentence(
    $payload: String
    $task: String
    $author: String
    $userClassroom: String
    $unitId: String
  ) {
    CreateSentence(
      payload: $payload
      task: $task
      author: $author
      userClassroom: $userClassroom
      unitId: $unitId
    ) {
      status
      success
      userName
      response
      payload
      __typename
    }
  }
`;
export const IoIncrement = /* GraphQL */ `
  query IoIncrement($id: String!, $range: String!, $counter: Int) {
    IoIncrement(id: $id, range: $range, counter: $counter) {
      status
      success
      userName
      response
      payload
      __typename
    }
  }
`;
export const Gateway = /* GraphQL */ `
  query Gateway($payload: AWSJSON) {
    Gateway(payload: $payload) {
      status
      success
      userName
      response
      payload
      __typename
    }
  }
`;
export const getWords = /* GraphQL */ `
  query GetWords($id: String!, $range: String!) {
    getWords(id: $id, range: $range) {
      id
      range
      counter
      currentRelease
      version
      name
      description
      author
      sentence
      definition
      synonyms
      ttl
      classes
      sponsors
      credits
      createdAt
      updatedAt
      color
      imagePath
      path
      title
      question
      questionId
      difficulty
      correctAnswer
      wrongAnswers
      explanation
      isConsent
      dateConsent
      unitId
      __typename
    }
  }
`;
export const listWords = /* GraphQL */ `
  query ListWords(
    $id: String
    $range: ModelStringKeyConditionInput
    $filter: ModelWordsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWords(
      id: $id
      range: $range
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        range
        counter
        currentRelease
        version
        name
        description
        author
        sentence
        definition
        synonyms
        ttl
        classes
        sponsors
        credits
        createdAt
        updatedAt
        color
        imagePath
        path
        title
        question
        questionId
        difficulty
        correctAnswer
        wrongAnswers
        explanation
        isConsent
        dateConsent
        unitId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
