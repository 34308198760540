export const formFields = {

    signIn: {
        username: {
            labelHidden: false,
            placeholder: 'Enter your username',
            isRequired: true,
            label: 'Username',
        },
        password: {
            labelHidden: false,
            placeholder: 'Enter your password',
            isRequired: true,
            label: 'Password'
        },
    },

    signUp: {
        password: {
            label: 'Password:',
            placeholder: 'Enter your Password:',
            isRequired: false,
            order: 2,
        },
        confirm_password: {
            label: 'Confirm Password:',
            order: 1,
        },
    },

    forceNewPassword: {
        password: {
            placeholder: 'Enter your Password:',
        },
    },

    resetPassword: {
        username: {
            placeholder: 'Enter your email:',
        },
    },

    confirmResetPassword: {
        confirmation_code: {
            placeholder: 'Enter your Confirmation Code:',
            label: 'New Label',
            isRequired: false,
        },
        confirm_password: {
            placeholder: 'Enter your Password Please:',
        },
    },

    setupTOTP: {
        QR: {
            totpIssuer: 'test issuer',
            totpUsername: 'amplify_qr_test_user',
        },
        confirmation_code: {
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
        },
    },

    confirmSignIn: {
        confirmation_code: {
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
        },
    },
};