// Colors, styles etc.
import { globalSettings } from 'GlobalSettings';
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { icons } from 'img/icons';
import idioms from 'locales/idioms_static';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

// Components and blocks
import CardArray from 'components/pages/browser/utils/CardArray';

const StateDashboardBody = ({ groupedCardsData, pageSettings, currentNavigationPath }) => {

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const paragraphs = paragraphStyles(isSmallScreen);
    const styles = componentStyles(isSmallScreen);

    // Do not render if there is only one key called 'notGrouped'
    if ('notGrouped' in groupedCardsData) {
        return null;
    }

    return (
        <>

            {Object.entries(groupedCardsData).map(([groupKey, cardsInGroup]) => (

                <div>
                    <React.Fragment key={groupKey}>
                        <CardArray
                            title={groupKey}
                            cards={cardsInGroup}
                            isUnitRoot={pageSettings.isUnitRoot}
                            currentNavigationPath={currentNavigationPath}
                        />
                    </React.Fragment>

                    <div style={styles.dashboardDividerStyle}> </div>
                </div>
            ))}

        </>

    );
};

export default StateDashboardBody;
