// Colors, styles etc.
import { globalSettings } from 'GlobalSettings';
import { colors } from 'components/styles/colors';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { componentStyles } from 'components/styles/component_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { staticImages } from 'img/static_images';
import 'components/styles/deprecated/home.css'

// Basic core functions
import React from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'

// Support functions
import { useNavigate } from 'react-router-dom';
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

// Components and blocks
import WelcomeBanner from 'components/pages/navigation/WelcomeBanner';
import WelcomeFooter from 'components/pages/navigation/WelcomeFooter';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

const default_settings = {
  leftButtons: [
    {
      title: 'Discito',
      textColor: 'light',
      backgroundColor: 'logo',
      to: '/',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: true,
      isImage: true,
      imagePath: 'img/icons/discito_logo_small_yellow.png',
    },
    {
      title: 'Discito',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: true,
    },
  ],
  rightButtons: [
    {
      title: 'Logout',
      textColor: 'light',
      backgroundColor: 'blue1',
      to: '/logout',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
    },
    {
      title: '?',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/help',
      visibleIfAuthenticated: false,
      visibleIfNotAuthenticated: true,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_question-circle.png',
    },
    {
      title: 'Login',
      textColor: 'light',
      backgroundColor: 'gold',
      to: '/login',
      visibleIfAuthenticated: false,
      visibleIfNotAuthenticated: true,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_person-circle-fill.png',
    },
  ]
}

export function Home({ settings = default_settings }) {

  // Authentication
  const { user } = useAuthenticator();
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  // Set-up top bar
  useNavigationConfig(
    route === 'authenticated' ? user.username : '', '', settings.leftButtons, settings.rightButtons);

  // Use the useLayoutEffect hook to scroll to the top after the component is rendered
  useScrollToTop();

  // Responsiveness
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= 768;

  //Styles
  const styles = componentStyles(isSmallScreen);
  const paragraphs = paragraphStyles(isSmallScreen);

  //Images
  const images = staticImages;

  return (
    <div className="app">

      <WelcomeBanner />

      <main>
        <div style={virtualFullWidth}>
          <div style={availableWidth}>

            <div style={styles.mainDivStyle}>
              <div style={styles.simpleBarStyle}>
                <img
                  src={require('img/logo.png')}
                  className='logo'
                  alt='Logo' />
                <div style={{
                  ...styles.simpleTextStyle,
                  fontSize: isSmallScreen ? 18 : 24,
                  fontWeight: 700,
                  margin: 0,
                  padding: 0,
                  marginBottom: 10,
                }}>
                  Welcome to&nbsp;Discito
                </div>

                <div style={{ ...styles.simpleBarStyle, fontWeight: '400', marginTop: 10, marginbottom: 0, padding: 0, marginLeft: 20, marginRight: 20 }}>
                  <div style={{ marginBottom: 10 }}>Explore high-quality educational content and learn anything, anytime, anywhere.</div>
                  <div style={{marginBottom:10}}>Whether you're a student, professional, or lifelong learner, enjoy resources and activities to expand your knowledge!
                  </div>
                </div>

                {/* This is the new button component to be linked...*/}
                <div>
                  <button
                    style={styles.buttonStyleCore}
                    onClick={
                      () => {
                        navigate('/login');
                      }
                    }
                  >
                    {/* Leads to Dashboard or Login */}
                    {route === 'authenticated' ? 'Dashboard' : 'Login'}
                  </button>
                </div>

              </div>

              <div style={styles.dividerStyle}></div>

              <div style={styles.simpleBarStyle}>

                {/* First set */}
                <div style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.white }}>

                  <div style={{
                    backgroundColor: colors.white,
                    height: 'auto',
                    flex: isSmallScreen ? '1 0 100%' : '1 0 40%', // 40% of the space on larger screens
                    width: isSmallScreen ? '100%' : 'auto', // Ensure full width on small screens
                    // position: 'relative',
                  }}>
                    <div style={{ ...styles.simpleBarStyle, marginRight: '24', marginLeft: '24' }}>
                      <img
                        src={isSmallScreen ? staticImages.discito_image_003_small : staticImages.discito_image_003_large}
                        alt="discito_image_003"
                        style={{
                          maxWidth: '100%', // Makes sure the image is not bigger than its container
                          height: 'auto', // Keeps the aspect ratio of the image
                          alignItems: 'center',
                          borderRadius: isSmallScreen ? 6 : 9,
                          // Adjust as necessary to control the size of the image relative to the screen width
                        }}
                      />
                    </div>
                  </div>

                  <div style={{
                    backgroundColor: colors.white,
                    height: 'auto',
                    flex: isSmallScreen ? '1 0 100%' : '1 0 60%', // 60% of the space on larger screens
                    width: isSmallScreen ? '100%' : 'auto', // Ensure full width on small screens
                    // position: 'relative',
                  }}>
                    <div style={{ ...styles.simpleBarStyle, marginRight: '24', marginLeft: '24' }}>
                      <div style={{
                        ...styles.simpleTextStyle,
                        fontSize: isSmallScreen ? 18 : 24,
                        fontWeight: 700,
                        margin: 0,
                        padding: 0,
                        marginBottom: 10,
                      }}>
                        Empower Your&nbsp;Learning Journey: Anytime,&nbsp;Anywhere
                      </div>

                      <div style={{ ...styles.simpleTextStyle, marginTop: 10, marginLeft: 20, marginRight: 20 }}>
                        <div style={{ marginBottom: 10 }}>Join a community dedicated to creating, sharing, and experiencing high-quality learning without limits.</div>
                      </div>
                      <div>
                        <button
                          style={styles.buttonStyleCore}
                          onClick={
                            () => {
                              navigate('/login');
                            }
                          }
                        >
                          Start learning&nbsp;now
                          {/* Leads to Dashboard or Login */}
                        </button>
                      </div>
                    </div>
                    {/* Text 1 */}
                  </div>
                </div>

                <div style={styles.dividerStyle}></div>

                <div style={styles.simpleBarStyle}></div>

                {/* Second set */}
                <div style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.white }}>

                  <div style={{
                    display: isSmallScreen ? 'none' : 'flex', // Hide on small screens, show otherwise
                    backgroundColor: colors.white,
                    height: 'auto',
                    flex: isSmallScreen ? '1 0 100%' : '1 0 60%', // 40% of the space on larger screens
                    width: isSmallScreen ? '100%' : 'auto', // Ensure full width on small screens
                  }}>
                    <div style={{ ...styles.simpleBarStyle, marginRight: '24', marginLeft: '24' }}>
                      <div style={{
                        ...styles.simpleTextStyle,
                        fontSize: isSmallScreen ? 18 : 24,
                        fontWeight: 700,
                        margin: 0,
                        padding: 0,
                        marginBotton: 10,
                      }}>
                        Revolutionizing&nbsp;Learning for&nbsp;Everyone
                      </div>

                      <div style={{ ...styles.simpleBarStyle, fontWeight: '400', marginTop: 10, marginbottom: 10, padding: 0}}>
                        <div style={{ marginBottom: 10 }}>Our vision and mission is to dismantle the barriers to education.</div>
                        <div style={{ marginBottom: 10 }}>With our innovative platform, we want bring high-quality learning content to your fingertips.</div>
                        <div style={{ marginBottom: 10 }}>Promoting practical experience and memory-making, our platform is more than just a learning tool.</div>
                      </div>

                      <div>
                        <button
                          style={styles.buttonStyleCore}
                          onClick={
                            () => {
                              navigate('/vision');
                            }
                          }
                        >
                          Find&nbsp;out more
                          {/* Leads to Vision */}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div style={{
                    backgroundColor: colors.white,
                    height: 'auto',
                    flex: isSmallScreen ? '1 0 100%' : '1 0 40%', // 40% of the space on larger screens
                    width: isSmallScreen ? '100%' : 'auto', // Ensure full width on small screens
                    // position: 'relative',
                  }}>
                    <div style={{ ...styles.simpleBarStyle, marginRight: '24', marginLeft: '24' }}>
                      <img
                        src={isSmallScreen ? staticImages.discito_image_002_small : staticImages.discito_image_002_large}
                        alt="discito_image_001"
                        style={{
                          maxWidth: '100%', // Makes sure the image is not bigger than its container
                          height: 'auto', // Keeps the aspect ratio of the image
                          alignItems: 'center',
                          borderRadius: isSmallScreen ? 6 : 9,
                          // Adjust as necessary to control the size of the image relative to the screen width
                        }}
                      />
                    </div>
                  </div>

                  <div style={{
                    display: isSmallScreen ? 'flex' : 'none', // Show on small screens, hide otherwise
                    backgroundColor: colors.white,
                    height: 'auto',
                    flex: isSmallScreen ? '1 0 100%' : '1 0 50%', // 40% of the space on larger screens
                    width: isSmallScreen ? '100%' : 'auto', // Ensure full width on small screens
                    // position: 'relative',
                  }}>
                    <div style={{ ...styles.simpleBarStyle, marginRight: '24', marginLeft: '24' }}>
                      <div style={{
                        ...styles.simpleTextStyle,
                        fontSize: isSmallScreen ? 18 : 24,
                        fontWeight: 700,
                        margin: 0,
                        padding: 0,
                        marginBotton: 10,
                      }}>
                        Revolutionizing&nbsp;Learning for&nbsp;Everyone
                      </div>

                      <div style={{ ...styles.simpleBarStyle, fontWeight: '400', marginTop: 10, marginbottom: 0, padding: 0, marginLeft: 20, marginRight: 20 }}>
                        <div style={{ marginBottom: 10 }}>Our vision and mission is to dismantle the barriers to education.</div>
                        <div style={{ marginBottom: 10 }}>With our innovative platform, we want bring high-quality learning content to your fingertips.</div>
                        <div style={{ marginBottom: 10 }}>Promoting practical experience and memory-making, our platform is more than just a learning tool.</div>
                      </div>

                      <div>
                        <button
                          style={styles.buttonStyleCore}
                          onClick={
                            () => {
                              navigate('/vision');
                            }
                          }
                        >
                          Find out more
                          {/* Leads to Vision */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Add two more elements */}
                {/* Showcase / Content */}
                {/* Same component types as above */}
                {/* Lists few examples of things that are already implemented... */}
                {/* Will be importnat when we enable login from the street to see free content... */}

                {/* Donations */}
                {/* Same component types as above */}
                {/* Shows QR code for donations and a story around this - why people should donate and what we are doing with the money... */}

                {/*  */}

              </div>
            </div>
          </div>
        </div >
      </main >

      <WelcomeFooter />

    </div >
  )
}
