// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { icons } from 'img/icons';
import idioms from 'locales/idioms_static';

// Basic core functions
import React from 'react';

// Support functions
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';

function DashboardFooter() {

    //Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    //Styles
    const styles = componentStyles(isSmallScreen); // requires isSmallScreen loaded

    //Handle page width
    const virtualFullWidthLocal = {
        ...virtualFullWidth,
        backgroundColor: colors.gray2,
        position: 'relative',
        top: isSmallScreen ? 10 : 0,  // in conjunction with App.css, .app, min-height
    }

    //Handle redirections
    const navigate = useNavigate();

    return (
        <div style={virtualFullWidthLocal}>
            <div style={availableWidth}>

                <div
                    style={styles.footerMainFlexStyle}
                >
                    <div // Left side for text
                        style={styles.footerLeftFlexStyle}
                    >
                        <div>
                            <span style={{ ...styles.simpleTextStyle, fontSize: isSmallScreen ? 18 : 24, fontWeight: 700, color: colors.white, cursor: 'pointer' }}>
                               Ready to <span style={{ color: colors.amber }}>learn</span>?
                            </span>
                            {/* Can we add here an Onclick that would scroll up the page? */}
                        </div>

                    </div>

                    <div // Right side for text
                        style={styles.footerRightFlexStyle}
                    >
                        <div
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', cursor: 'pointer' }}
                            onClick={() => navigate('/help')}
                        >
                            <img src={icons.icon_email} alt={'email'} cursor={'pointer'} style={styles.footerIconStyle} />
                            <div style={{ color: colors.white, marginLeft: 9, marginRight: isSmallScreen ? 8 : 12, fontSize: isSmallScreen ? 14 : 16 }}>
                                info@discito.cz
                            </div>
                        </div>

                        <div
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', cursor: 'pointer' }}
                            cursor="pointer"
                            onClick={() => navigate('/help')}
                        >
                            <img src={icons.icon_help} alt={'help'} cursor={'pointer'} style={styles.footerIconStyle} />
                            <div style={{ color: colors.white, marginLeft: 9, marginRight: isSmallScreen ? 8 : 12, fontSize: isSmallScreen ? 14 : 16 }}>
                                Help
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default DashboardFooter;
