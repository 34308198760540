// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { textStyles } from 'components/styles/text_styles';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

const ChapterBox = (props) => {

    const { title, question, rationale, rationaleTranslated, explanation, translation, setUserInput, isInfoVisible, setIsInfoVisible } = props;

    // Image definitions
    const icon_down = require('img/icons/symbols/black/icon_chevron_S_double_down.png');
    const icon_up = require('img/icons/symbols/black/icon_chevron_S_double_up.png');

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const isHovered = false
    const styles = componentStyles(isSmallScreen, isHovered);

    return (
        <div style={styles.wordBoxContainerStyle} >

            <div style={styles.wordBoxMainBlockStyle}>
                <div>
                    <div style={{
                        ...styles.wordBoxDetailTextStyleLine,
                    }}>
                        {question}
                    </div>
                    <div style={{
                        ...styles.wordBoxDetailTextStyleLine,
                        fontStyle: 'italic',
                    }}>
                        {translation}
                    </div>
                </div>

                <div style={styles.workBoxIconAreaStyle}>
                    <img
                        src={isInfoVisible ? icon_up : icon_down}
                        alt="INFO"
                        style={styles.wordBoxIconStyle}
                        onClick={() => setIsInfoVisible(!isInfoVisible)} />
                </div>
            </div>

            {isInfoVisible && (
                <div style={styles.wordBoxDetailTextStyle} >

                    <div style={styles.dividerDarkStyle}></div>

                    <div style={{ ...styles.wordBoxDetailTextStyleLine }}>
                        Hint: {rationale}</div>

                    {rationaleTranslated && (
                        <div style={{
                            ...styles.wordBoxDetailTextStyleLine,
                            fontStyle: 'italic',
                        }}>
                            Nápověda: {rationaleTranslated}</div>
                    )}

                </div>
            )}

        </div>
    );
};

export default ChapterBox;
