// Colors, styles etc.
import { colors } from 'components/styles/colors';
import idioms from 'locales/idioms_static';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Basic navigation elements
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';

// Backend connection
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';
import useAudioPlayer from 'components/_common/AudioPlayer';
import ImageComponent from 'components/_common/ImageComponent'

// Support functions
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { globalSettings } from 'GlobalSettings';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

// Generic components and blocks
import PageTitleActivity from 'components/pages/navigation/PageTitleActivity';
import DashboardFooter from 'components/pages/navigation/DashboardFooter';
import SectionHeadingToolbar from 'components/_common/SectionHeadingToolbar';

// Page-specific components and clocks
import SnackBarSingleFormatted from 'components/pages/languages/_ContentTestingCards/SnackBarSingleFormatted';

const default_settings = {
    activityTitle: 'Validate content',
    leftButtons: [
        {
            title: 'Back',
            textColor: colors.light,
            backgroundColor: colors.gray1,
            to: '/dashboard?pk=ACTIVITY&sk=ENG~',
            visibleIfAuthenticated: true,
            visibleIfNotAuthenticated: false,
            isImage: true,
            imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
        },
    ],
    rightButtons: [
        {
            title: 'Logout',
            textColor: colors.light,
            backgroundColor: colors.gray1,
            to: '/logout',
            visibleIfAuthenticated: true,
            visibleIfNotAuthenticated: false,
            isImage: true,
            imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
        }
    ]
}

export function TestingCards({ settings = default_settings }) {
    // Language selection for static texts
    const [activeLanguage, setActiveLanguage] = useState('en');
    const currentTexts = idioms[activeLanguage];
    // Static texts aliases
    // const instructions = currentTexts.language_instructions. ...;

    // Read clipboard
    const { clipboard } = useTopBarCLipboardContext();
    const userClassroom = clipboard.userClassroom.value;
    const unitId = clipboard.unitId.value;

    // Read backend
    const [backendData, setBackendData] = useState([]);
    const [backendDataCache, setBackendDataCache] = useState([]);
    const [backendDefaultLanguage, setBackendDefaultLanguage] = useState('en');
    const [backendMotherTongue, setBackendMotherTongue] = useState('cz');
    useEffect(() => {
        const requestData = {
            task: 'languageOverviewWords',
            range: `${userClassroom}~${unitId}~`,
            language: backendDefaultLanguage,
            motherTongue: backendMotherTongue,
        };
        const fetchData = async () => {
            const data = await fetchBackendData(requestData);
            if (data) { setBackendDataCache(data); }
            if (data) { setBackendData(data[backendDefaultLanguage]); }
        };
        fetchData();
    }, [userClassroom, unitId, backendDefaultLanguage, backendMotherTongue]);

    // Set-up top bar
    useNavigationConfig(null, null, settings.leftButtons, settings.rightButtons);

    // Use the useLayoutEffect hook to scroll to the top after the component is rendered
    useScrollToTop();
    // useDebugClipboard(clipboard);

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Handle settings
    const [userInput, setUserInput] = useState('');

    // Track images already loaded
    const [loadedImages, setLoadedImages] = useState({});
    const handleCacheImages = (wordImage, imageUrl) => {
        setLoadedImages(prevState => ({
            ...prevState,
            [wordImage]: imageUrl,
        }));
    };

    // Track clicked image
    const [selectedWord, setSelectedWord] = useState(null); // Track selected word
    const handleImageClick = (wordTitle) => {
        setSelectedWord(wordTitle); // Set the selected word
    };

    // Audio
    const { playAudio } = useAudioPlayer();

    return (

        <div className="app">

            <PageTitleActivity
                title={default_settings.activityTitle + " | " + globalSettings.courseNameEN}
                subtitle={globalSettings.clientName + " | " + userClassroom.split('-')[0]} />

            <main>
                <div style={virtualFullWidth}>
                    <div style={availableWidth}>

                        <SectionHeadingToolbar
                            title={settings.activityTitle}
                            setUserInput={setUserInput}
                            infoText='Images, sound and sentences are gathered here for this unit'
                            setActiveLanguage={setActiveLanguage}
                        />

                        {backendData && backendData[selectedWord] && (
                            <div style={{ position: 'sticky', top: 10, zIndex: 1 }}>
                                <SnackBarSingleFormatted
                                    title={selectedWord} // Show selected word in snack bar
                                    definition={backendData[selectedWord].definition}
                                    sentence={backendData[selectedWord].spokenSentence}
                                    synonyms={backendData[selectedWord].synonyms}
                                    sentences={backendData[selectedWord].sentences}
                                    color='lightyellow' />
                            </div>
                        )}

                        <div style={{ marginTop: 0, marginBottom: 100, display: 'flex', flexWrap: 'wrap' }}>
                            {Object.entries(backendData).map(([key, value], index) => (
                                <div
                                    key={index}
                                    onClick={() => {
                                        handleImageClick(key);
                                        playAudio(key)
                                    }}
                                    style={{ margin: 10, flex: '1 1 100px' }}>
                                    <ImageComponent
                                        wordImage={key}
                                        cacheImages={handleCacheImages}
                                        loadedImages={loadedImages}
                                        extension='png.jpg'
                                    />
                                    <div style={{ fontWeight: selectedWord === key ? 'bold' : 'normal' }}>{key}</div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </main>

            <DashboardFooter />

        </div >
    );
}