// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

const StateIdle = ({ setSequenceState, waitInterval, setWaitInterval, repetition, setRepetition }) => {

    // Control the hovering effect
    const [isHovered, setIsHovered] = useState(false);

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const styles = componentStyles(isSmallScreen, isHovered);

    // Handlers for slider changes
    const handleWaitIntervalChange = (event) => {
        setWaitInterval(event.target.value);
    };
    const handleRepetitionChange = (event) => {
        setRepetition(event.target.value);
    };
    
    return (
        <div style={styles.mainDivStyle}>

            <div style={styles.mainDivStyle}>

                <div
                    style={styles.captionLabelStyle}>
                    Settings
                </div >

                <div style={styles.sliderBlockStyle}>
                    <div style={styles.sliderMainTextStyle}>
                        <label htmlFor="waitIntervalSlider">
                            Interval: {waitInterval === "1" ? `${waitInterval} second` : `${waitInterval} seconds`}
                        </label>
                    </div>
                    <input
                        style={styles.sliderInputStyle}
                        type="range"
                        id="waitIntervalSlider"
                        min="4"
                        max="10"
                        step="1"
                        value={waitInterval}
                        onChange={handleWaitIntervalChange}
                    />
                    <div style={styles.sliderSupportingTextStyle}>
                        <label htmlFor="waitIntervalSlider">
                            There is a delay of {waitInterval === "1" ? `${waitInterval} second` : `${waitInterval} seconds`} between the words and repetitions.
                        </label>
                    </div>
                </div>

                <div style={styles.sliderBlockStyle}>
                    <div style={styles.sliderMainTextStyle}>
                        <label htmlFor="repetitionSlider">Repetition: {repetition}x</label>
                    </div>
                    <input
                        style={styles.sliderInputStyle}
                        type="range"
                        id="repetitionSlider"
                        min="1"
                        max="3"
                        value={repetition}
                        onChange={handleRepetitionChange}
                    />
                    <div style={styles.sliderSupportingTextStyle}>
                        <label htmlFor="waitIntervalSlider">
                            Each word is repeated {repetition === "1" ? `only once.` : `${repetition} times.`}
                        </label>
                    </div>
                </div>
            </div>

            <div
                style={{ ...styles.captionLabelStyle, fontWeight: 400 }}>
                Click "Start" to run the spelling test simulator...
            </div >

            <div>
                {/* Clicking Start must set sequenceState to 'RUNNING' */}
                <button
                    style={styles.buttonStyleCore}
                    onClick={() => setSequenceState('RUNNING')}>
                    Start
                </button>
            </div>

        </div>
    );
};

export default StateIdle;
