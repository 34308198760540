import React, { useState, useEffect } from 'react';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // Adjust this import based on your actual path

// Images (should be imported from external file..)
const logo = require('img/home/home.jpg'); // Adjust this path as necessary

// Content (this is an intent to make it parametric from database - therefore tiny text corrections do not require re-deploy)
// Note: each tile has a unique identifier (a single number) which not necessarily reflects order of presentation
// Note: this is a way how to simulate a backend delivery of data to any react page: we place 'const content = {}' with the expecte type of data
const cards_content = {
    '0': {
        type: 'image',
        image: logo,
    },
    '1': {
        type: 'text',
        text: 'random text here 1',
    },
    '3': {
        type: 'image',
        image: logo,
    },
    '4': {
        type: 'text',
        text: 'random text here 4',
    },
    '5': {
        type: 'text',
        text: 'random text here 5',
    },
    '6': {
        type: 'text',
        text: 'random text here 6',
    },
};

// Main function
const CardsCoverPage = (props) => {

    // if we receive variables from a parent function, for example title..
    const { title } = props;

    // Visuals
    const windowSize = useWindowSize();
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    // Styles for image and text cards
    const typeStyles = {
        image: {
            flex: '1 0 100%', // Default for small screens
            backgroundColor: 'transparent',
            margin: '0px',
            boxSizing: 'border-box',
            width: '300px',
            height: '300px',
            padding: '0px',
            position: 'relative',
        },
        text: {
            flex: '1 0 100%', // Default for small screens
            backgroundColor: 'lightgray',
            margin: '0px',
            boxSizing: 'border-box',
            width: '300px',
            height: '300px',
            padding: '0px',
            position: 'relative',
        },
    };
    const imageStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    };
    const cardStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '10px',
        padding: '10px'
    }

    // This is to force a re-render if the screen size changes (not sure why it is necessary)
    useEffect(() => {
        setIsSmallScreen(windowSize.width <= 768);
    }, [windowSize.width]);

    // Ordered cards
    const tile_order_if_isSmallScreen = ['0', '1', '3', '4', '5', '6']; // Why this? On smaller screens, space is limited. You might want to prioritize certain content over others based on user behavior or business needs.
    const orderedCards = isSmallScreen
        ? tile_order_if_isSmallScreen.map(key => cards_content[key])
        : Object.values(cards_content);


    // Web page rendering:
    return (
        <div style={cardStyle}>

            {/* Loop over all cards */}
            {orderedCards.map((card, index) => {

                // Decide if the cards are odd or even to assign the desired width
                const isEvenIndex = index % 2 === 0;
                const flexBasis = isSmallScreen ? '100%' : isEvenIndex ? '60%' : '40%';
                const style = {
                    ...typeStyles[card.type],
                    flex: `1 0 ${flexBasis}`,
                };

                // Render the card
                return (
                    <div key={index} style={style}>
                        {card.type === 'image' ? (
                            // In case card is an image
                            <img src={card.image} alt={`Image ${index}`} style={imageStyle} />
                        ) : (
                            // In case card is Text
                            <div>{card.text}</div>
                        )}
                    </div>
                );

            })}

        </div>
    );
};

export default CardsCoverPage;
