import { Home } from 'components/pages/Home'
import { Help } from 'components/pages/help/Help'
import { Login } from 'components/pages/Login'
import { VisionMission } from 'components/pages/VisionMission'

// Navigation
import { Dashboard } from 'components/pages/browser/Dashboard'

// Content Validation
import { TestingCards } from 'components/pages/languages/ContentTestingCards'

// Current pages for administration
import { ResetPassword } from 'components/pages/management/ResetPassword'
import { NewWords } from 'components/pages/management/NewWords'
import { UserConsent } from 'components/pages/management/UserConsent'

// New pages for administration
import { AddWord } from 'components/pages/management/AddWord'
import { Logging } from 'components/pages/management/Logging'
import { UnitManagement } from 'components/pages/management/units/UnitManagement'
import { Test01 } from 'components/pages/test/Test01'
import { Test02 } from 'components/pages/test/Test02'
import { Test03 } from 'components/pages/test/Test03'
// End of new pages for administration

// Math
import { ExercisesMath } from 'components/legacy/exercises/math/ExercisesMath'
import { ExercisesMathChainedOps } from 'components/pages/math/ChainedOps/ChainedOps'
import { ExercisesMathFillInTheGapsOps } from 'components/pages/math/FillInTheGapsOps/FillInTheGapsOps'
import { ExercisesMathSimpleFillInTheGapsOps } from 'components/pages/math/SimpleFillInTheGapsOps/SimpleFillInTheGapsOps'
import { ExercisesMathWrittenArithmetic } from 'components/pages/math/WrittenArithmetic/WrittenArithmetic'
import { Polygons } from 'components/pages/math/Polygons/Polygons'
import { UnitConversions } from 'components/pages/math/UnitConversions/UnitConversions'
import { ConversionEquation } from 'components/pages/math/ConversionEquation/ConversionEquation'
import { Tridimensional } from 'components/pages/math/Tridimensional/Tridimensional'
import { Review } from 'components/legacy/exercises/_exercises/Review'
import { ExercisesSums2 } from 'components/legacy/exercises/math/ExercisesSums2'
import { ExercisesSums1 } from 'components/legacy/exercises/math/ExercisesSums1'
import { ExercisesMultiply } from 'components/legacy/exercises/math/ExercisesMultiply'
import { ExercisesDivide } from 'components/legacy/exercises/math/ExercisesDivide'
import { ExercisesSeries } from 'components/legacy/exercises/math/ExercisesSeries'
import { ExercisesRomanNumerals2 } from 'components/legacy/exercises/math/ExercisesRomanNumerals2'
import { ExercisesRomanNumerals3 } from 'components/legacy/exercises/math/ExercisesRomanNumerals3'
import { ExercisesMathHejny2zColouredTriples } from 'components/legacy/exercises/math/ExercisesMathHejny2zColouredTriples'

// Languages
import { WordsOverview } from 'components/pages/languages/WordsOverview'
import { LearningCards } from 'components/pages/languages/LearningCards'
import { SpellingTest } from 'components/pages/languages/SpellingTest'
import { VocabularyQuiz } from 'components/pages/languages/VocabularyQuiz'
import { WordVariations } from 'components/pages/languages/WordVariations'
import { VocabularyExam } from 'components/pages/languages/VocabularyExam'
import { VocabularyExamLong } from 'components/pages/languages/VocabularyExamLong'
import { PrepositionsQuiz } from 'components/pages/languages/PrepositionsQuiz'

// Books
import { BookQuiz } from 'components/pages/languages/books/BookQuiz'

export const routes = [
    // public routes
    { path: '/', component: Home, public: true },
    { path: '/help', component: Help, public: true },
    { path: '/login', component: Login, public: true },
    { path: '/vision', component: VisionMission, public: true },

    // private routes: development
    { path: '/user/password-reset', component: ResetPassword, public: false },
    { path: '/user/consent', component: UserConsent, public: false },
    { path: '/content/unit-management', component: UnitManagement, public: false },
    { path: '/content/add-word', component: AddWord, public: false },
    { path: '/admin/logging', component: Logging, public: false },

    // private routes
    { path: '/dashboard', component: Dashboard, public: false },

    { path: '/exercises/new-words', component: NewWords, public: false },

    { path: '/qa/English/validate-content', component: TestingCards, public: false },

    { path: '/learning/English/words-overview', component: WordsOverview, public: false },
    { path: '/learning/English/learning-cards', component: LearningCards, public: false },
    { path: '/learning/English/spelling-test', component: SpellingTest, public: false },
    { path: '/learning/English/vocabulary-quiz', component: VocabularyQuiz, public: false },
    { path: '/learning/English/prepositions-quiz', component: PrepositionsQuiz, public: false },
    { path: '/learning/English/word-variations', component: WordVariations, public: false },
    { path: '/learning/English/vocabulary-exam', component: VocabularyExam, public: false },
    { path: '/learning/English/vocabulary-exam-long', component: VocabularyExamLong, public: false },

    { path: '/learning/English/book/quiz', component: BookQuiz, public: false },

    { path: '/exercises/math', component: ExercisesMath, public: false },
    { path: '/exercises/math/chained-ops', component: ExercisesMathChainedOps, public: false },
    { path: '/exercises/math/fill-in-the-gap-ops', component: ExercisesMathFillInTheGapsOps, public: false },
    { path: '/exercises/math/simple-fill-in-the-gap-ops', component: ExercisesMathSimpleFillInTheGapsOps, public: false },
    { path: '/exercises/math/written-arithmetic', component: ExercisesMathWrittenArithmetic, public: false },
    { path: '/exercises/math/polygons', component: Polygons, public: false },
    { path: '/exercises/math/unit-conversions', component: UnitConversions, public: false },
    { path: 'exercises/math/fill-in-the-gap-conversion', component: ConversionEquation, public: false },
    { path: '/exercises/math/tridimensional', component: Tridimensional, public: false },
    { path: '/exercises/review', component: Review, public: false },

    { path: '/exercises/math-hejny-2z-coloured-triples', component: ExercisesMathHejny2zColouredTriples, public: false },
    { path: '/exercises/math-2-arithmetic', component: ExercisesSums2, public: false },
    { path: '/exercises/math-2-roman-numerals', component: ExercisesRomanNumerals2, public: false },
    { path: '/exercises/math-3-roman-numerals', component: ExercisesRomanNumerals3, public: false },
    { path: '/exercises/math-1-arithmetic', component: ExercisesSums1, public: false },
    { path: '/exercises/math-2-arithmetic-prod', component: ExercisesMultiply, public: false },
    { path: '/exercises/math-2-arithmetic-divide', component: ExercisesDivide, public: false },
    { path: '/exercises/math-2-arithmetic-series', component: ExercisesSeries, public: false },

    { path: '/test-01', component: Test01, public: false },
    { path: '/test-02', component: Test02, public: false },
    { path: '/test-03', component: Test03, public: false },

];