export const generateQuestions = () => {
    const MIN_VALUE = 9;
    const MAX_VALUE = 70;
    const MAX_RESULT = 100;
    const numQuestions = 200; // You can change this as needed.
    const questionList = [];

    for (let i = 0; i < numQuestions; i++) {
        let isAddition = Math.random() < 0.5;
        let operand1 = Math.floor(Math.random() * (MAX_VALUE - MIN_VALUE + 1)) + MIN_VALUE;

        // For addition, allow operand1 to be negative
        if (isAddition) {
            operand1 *= Math.random() < 0.5 ? 1 : -1;

            // Calculate min and max for operand2 to ensure positive result
            let minOperand2 = isAddition && operand1 < 0 ? 1 - operand1 : MIN_VALUE;
            let maxOperand2 = MAX_VALUE;
            let operand2 = Math.floor(Math.random() * (maxOperand2 - minOperand2 + 1)) + minOperand2;

            let answer = operand1 + operand2;
            let question = `${operand1} + ${operand2} = _`;
            questionList.push({ question, answer });
        } else {
            let operand2 = Math.floor(Math.random() * (MAX_VALUE - MIN_VALUE + 1)) + MIN_VALUE;

            // Ensure valid subtraction question (operand1 >= operand2)
            if (operand1 < operand2) {
                const temp = operand1;
                operand1 = operand2;
                operand2 = temp;
            }

            // Ensure the result is not larger than MAX_RESULT
            while (operand1 - operand2 > MAX_RESULT) {
                operand1 = Math.floor(Math.random() * MAX_VALUE);
                operand2 = Math.floor(Math.random() * MAX_VALUE);
            }

            let answer = operand1 - operand2;
            let question = `${operand1} - ${operand2} = _`;
            questionList.push({ question, answer });
        }
    }

    return questionList;
};
