export const virtualFullWidth = {
    maxWidth: '100vw',
    backgroundColor: "rgba(255,255,255,1)"
};

export const availableWidth = {
    maxWidth: '1120px',
    marginLeft: 'auto',
    marginRight: 'auto'
};
