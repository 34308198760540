// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { componentStyles } from 'components/styles/component_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { staticImages } from 'img/static_images';

// Basic core functions
import React, { useState } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'

// Support functions
import { useNavigate } from 'react-router-dom';
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

// Components and blocks
import WelcomeBanner from 'components/pages/navigation/WelcomeBanner';
import WelcomeFooter from 'components/pages/navigation/WelcomeFooter';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

const default_settings = {
    leftButtons: [{
        title: 'Discito',
        textColor: 'light',
        backgroundColor: 'logo',
        to: '/',
        visibleIfAuthenticated: true,
        visibleIfNotAuthenticated: true,
        isImage: true,
        imagePath: 'img/icons/discito_logo_small_yellow.png',
    },
    {
        title: 'Discito',
        textColor: 'light',
        backgroundColor: 'gray1',
        to: '/',
        visibleIfAuthenticated: true,
        visibleIfNotAuthenticated: true,
    }],
    rightButtons: [{
        title: 'Logout',
        textColor: 'light',
        backgroundColor: 'blue1',
        to: '/logout',
        visibleIfAuthenticated: true,
        visibleIfNotAuthenticated: false,
        isImage: true,
        imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
    },
    {
        title: '?',
        textColor: 'light',
        backgroundColor: 'gray1',
        to: '/help',
        visibleIfAuthenticated: false,
        visibleIfNotAuthenticated: true,
        isImage: true,
        imagePath: 'img/icons/symbols/white/icon_w_question-circle.png',
    },
    {
        title: 'Login',
        textColor: 'light',
        backgroundColor: 'gold',
        to: '/login',
        visibleIfAuthenticated: false,
        visibleIfNotAuthenticated: true,
        isImage: true,
        imagePath: 'img/icons/symbols/white/icon_w_person-circle-fill.png',
    }]
}

export function VisionMission() {
    // Authentication
    const { user } = useAuthenticator();
    const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);

    // Read clipboard
    const { clipboard, setClipboard } = useTopBarCLipboardContext();
    // const notifications = clipboard.notifications;

    // Set-up top bar
    useNavigationConfig(
        route === 'authenticated' ? user.username : '', '', default_settings.leftButtons, default_settings.rightButtons);

    // To navigate to other pages (button Dashboard requires this)
    const navigate = useNavigate();

    // Use the useLayoutEffect hook to scroll to the top after the component is rendered
    useScrollToTop();

    //Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    //Styles
    const styles = componentStyles(isSmallScreen);
    const paragraphs = paragraphStyles(isSmallScreen);
    //Images
    const images = staticImages;

    return (
        <div className="app">

            <WelcomeBanner />

            <main>
                <div style={virtualFullWidth}>
                    <div style={availableWidth}>

                        <div style={styles.mainDivStyle}>
                            <div style={styles.simpleBarStyle}>

                                <div style={{ ...styles.simpleBarStyle, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <text style={paragraphs.pageTitleTitle}>
                                        Vision & Mission
                                    </text>
                                </div>

                                <div style={styles.dividerStyle}></div>

                                <div style={{ ...styles.simpleBarStyle, color: colors.amber }}>
                                    Our vision:
                                </div>

                                <div style={styles.simpleBarStyle}>
                                    Anyone learning anything, anytime, anywhere through experience
                                </div>

                                <div style={styles.simpleTextStyle}>
                                    What you learn and experience is something no one can take from you. We aim at anyone being able to learn anything, anytime, anywhere through experience and broaden one's horizons to navigate successfully through today's world.
                                </div>

                                <div style={styles.dividerStyle}></div>

                                <div style={{ ...styles.simpleBarStyle, color: colors.primary }}>
                                    Our Mission:
                                </div>

                                <div style={styles.simpleBarStyle}>
                                    Allow anyone to create, share, access and consume high-quality learning content without boundaries or limitations
                                </div>

                                <div style={styles.simpleTextStyle}>
                                    Through our learning platform, we are helping anyone in creating experience from memory through practice. This happens thanks to generating large number of variations to enrich context and allow for training from repetitions.
                                </div>

                                <div style={styles.simpleTextStyle}>
                                    Our learning platform shall be open to any learner or content creator and should promote and facilitate co-creation, sharing and utilization of content as a part of life-long training and experience building.
                                </div>

                                {/* <div style={styles.dividerStyle}></div> */}

                                <div style={{
                                    ...styles.simpleBarStyle, // Spreads your existing styles
                                    display: 'flex', // Enables Flexbox
                                    justifyContent: 'center', // Centers children horizontally
                                    alignItems: 'center', // Adjust this if you want vertical centering as well
                                    height: '100%', // Adjust based on your needs, could be 100vh for full viewport height
                                    backgroundColor: colors.gray6,
                                    paddingTop: '0',
                                    paddingBottom: '0',
                                    paddingRight: '0',
                                    paddingLeft: '0',

                                }}>
                                    <img
                                        src={images.discito_image_header_large}
                                        alt="discito_image_header_large"
                                        style={{
                                            maxWidth: '100%', // Makes sure the image is not bigger than its container
                                            width: 'auto',
                                            height: 'auto', // Keeps the aspect ratio of the image
                                            borderRadius: isSmallScreen ? 6 : 9,
                                            // Adjust as necessary to control the size of the image relative to the screen width
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main >

            <WelcomeFooter />

        </div >
    )
}
