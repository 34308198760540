import { globalSettings } from 'GlobalSettings';
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React from 'react';

// Components and blocks
import SnackBarArray from 'components/pages/navigation/SnackBarArray';

const StateDashboardHead = ({ notifications }) => {

    return (
        <>

            <SnackBarArray list={notifications} />

        </>

    );
};

export default StateDashboardHead;
