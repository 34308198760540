// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';

// Generic components and blocks
import SimpleTableComponent from 'components/_common/SimpleTableComponent';
import MessageBox from 'components/pages/test/MsgBoxComponent.js';

const UnitManagementStateMain = ({ backendData, setSequenceState, selectedCourse, setSelectedCourse, dataChangesCount, setDataChangesCount, settings }) => {


    // Aliases
    const coursesList = backendData.courses;
    const unitsList = backendData.units;
    const [serverResponse, setServerResponse] = useState('');

    // Control the hovering effect
    const [isHovered, setIsHovered] = useState(false);

    // Handle buttons: createUnit
    const handleCreateClick = () => {
        setSequenceState('CREATE')
    };

    // Handle courses in the dropdown, initial choice
    useEffect(() => {
        setSelectedCourse(coursesList.length > 0 ? coursesList[0]['description'] : '');
    }, [coursesList]);

    // Use the useLayoutEffect hook to scroll to the top after the component is rendered
    useScrollToTop();
    // useDebugClipboard(clipboard);

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Table
    const [tableRows, setTableRows] = useState([]);
    const selectedTableHeaders = isSmallScreen ? settings.headersTableSmallScreen : settings.headersTableLargeScreen;

    useEffect(() => {

        const generateTableRows = (selectedTableHeaders, records) => {
            return Array.from({ length: records.length }).map((_, index) => {

                // Filter by selectedCourse in the dropdown
                if (records[index]['courseDescription'] != selectedCourse) {
                    return {}
                }

                let rowData = {};
                selectedTableHeaders.forEach(header => {
                    switch (header) {
                        case 'Title':
                            rowData['Title'] = records[index]['title'];
                            break;
                        case 'Description':
                            rowData['Description'] = records[index]['description'];
                            break;
                        case 'Course':
                            rowData['Course'] = records[index]['courseDescription'];
                            break;
                        case 'Date':
                            rowData['Date'] = records[index]['dueDate'];
                            break;
                        case ' ':
                            rowData[' '] = (
                                <div style={{ display: 'flex', gap: isSmallScreen ? 4 : 6, justifyContent: 'right' }}>
                                    <button
                                        style={styles.buttonStyleLink}
                                        onClick={() => openMessageBox(
                                            'View',
                                            'ShowUnitDetails',
                                            records[index]['word'],
                                            records[index]['range']
                                        )}>
                                        Detail
                                    </button>
                                    <button
                                        style={styles.buttonStyleLink}
                                        onClick={() => openMessageBox(
                                            'View',
                                            'ShowUnitDetails',
                                            records[index]['word'],
                                            records[index]['range']
                                        )}>
                                        Content
                                    </button>
                                    <button
                                        style={styles.buttonStyleLink}
                                        onClick={() => openMessageBox(
                                            'msgBoxYesNo',
                                            'DeleteUnit',
                                            records[index]['word'],
                                            records[index]['range']
                                        )}>
                                        Delete
                                    </button>
                                </div>
                            );
                            break;
                        // Add more cases as necessary for other headers
                    }
                });
                return rowData;
            });
        };

        if (coursesList && unitsList) {
            setTableRows(
                generateTableRows(
                    selectedTableHeaders,
                    unitsList)
            )
        }


    }, [selectedCourse, isSmallScreen]); // Only execute this when selectedCourse changes in the dropdown

    // MsgBox definitions
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [messageBoxType, setMessageBoxType] = useState("msgBoxOK");
    const [messageBoxAction, setMessageBoxAction] = useState(null);
    const [messageBoxItemId, setMessageBoxItemId] = useState(null);
    const [messageBoxTitle, setMessageBoxTitle] = useState(null);
    const [messageBoxBody, setMessageBoxBody] = useState(null);
    const openMessageBox = (messageBoxType, messageBoxAction, itemClicked, itemIdClicked) => {
        setMessageBoxType(messageBoxType);
        setMessageBoxAction(messageBoxAction);
        setMessageBoxItemId(itemIdClicked);
        switch (messageBoxAction) {
            case 'ShowUnitDetails':
                setMessageBoxTitle('Unit Details');
                setMessageBoxBody(itemClicked);
                break;
            case 'DeleteUnit':
                setMessageBoxTitle('Delete Unit');
                setMessageBoxBody(itemClicked);
                break;
        }
        setShowMessageBox(true);
    };
    // Handle MsgBox buttons
    const handleOK = () => { setShowMessageBox(false) };
    const handleCancel = () => { setShowMessageBox(false) };
    const handleYes = () => {
        console.log('Yes', messageBoxAction, messageBoxItemId)
        setShowMessageBox(false)
    };
    const handleNo = () => {
        console.log('No', messageBoxAction, messageBoxItemId)
        setShowMessageBox(false)
    };

    // Styles
    const styles = componentStyles(isSmallScreen, isHovered);
    const paragraphs = paragraphStyles(isSmallScreen);

    const serverResponseStyle = {
        width: '30%', // Use 30% of the parent's width
        margin: '0 auto', // Center the div within the parent
        textAlign: 'left' // Ensure content is aligned to the left
    }

    return (

        <div style={styles.mainDivStyle}>

            {/* Create unit section */}
            <div style={{ ...styles.captionLabelStyle, marginTop: 0 }}>
                <div style={paragraphs.sectionHeadingSubtitle}>
                    Create unit
                </div>
            </div >
            <div>
                {/* Link to select courses */}
                <div style={styles.simpleTextStyleFlexML1MR1}>

                    <div style={styles.simpleTextStyleFlex8020}>
                        Select course:
                    </div >

                    <select
                        name='coursesDropdown'
                        style={styles.simpleSelectStyle80pct}
                        value={selectedCourse}
                        onChange={(e) => setSelectedCourse(e.target.value)}
                    >
                        {coursesList.map((course, index) => (
                            <option key={index}>
                                {course.description}
                            </option>
                        ))}

                    </select>

                </div>
            </div>

            <div style={styles.simpleTextStyle}>
                <button
                    style={styles.buttonStyleCore}
                    onClick={() => handleCreateClick()}
                >
                    Create unit
                </button>

                <button
                    style={styles.buttonStyleInactive}
                >
                    Course properties
                </button>

            </div>

            {/* Divider */}
            <div style={styles.dividerStyle}></div>

            {/* List of units section */}
            {serverResponse && serverResponse !== '' && (
                <div style={serverResponseStyle}>
                    <pre>{JSON.stringify(serverResponse, null, 2)}</pre>
                </div>
            )}

            <div style={{ ...styles.captionLabelStyle, marginTop: 0 }}>
                <div style={paragraphs.sectionHeadingSubtitle}>
                    List of units
                </div>
            </div >

            <div style={styles.simpleTextStyleML1MR1forLabel}>
                Course: {selectedCourse}
            </div >

            {
                tableRows && tableRows.length > 0 && (
                    <div style={styles.simpleTextStyleML1MR1forTable}>
                        <SimpleTableComponent
                            headers={selectedTableHeaders}
                            rows={tableRows}
                        />
                    </div>
                )
            }

        </div>
    );
};

export default UnitManagementStateMain;
