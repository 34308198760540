import React, { createContext, useContext, useState } from 'react';
const TopBarCLipboardContext = createContext();

export function useTopBarCLipboardContext() {
    return useContext(TopBarCLipboardContext);
}

export function TopBarCLipboardProvider({ children }) {
    const [leftButtons, setLeftButtons] = useState([]);
    const [rightButtons, setRightButtons] = useState([]);
    const [textCredentials, setTextCredentials] = useState([]);
    const [clipboard, setClipboard] = useState({});

    return (
        <TopBarCLipboardContext.Provider value={
            {
                leftButtons, setLeftButtons,
                rightButtons, setRightButtons,
                textCredentials, setTextCredentials,
                clipboard, setClipboard
            }
        }>

            {children}

        </TopBarCLipboardContext.Provider>
    );
}


