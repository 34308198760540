// Colors, styles etc.
import { globalSettings } from 'GlobalSettings';
import { colors } from 'components/styles/colors';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { componentStyles } from 'components/styles/component_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { staticImages } from 'img/static_images';
import idioms from 'locales/idioms_static';

// Basic core functions
import React, { useState, useCallback } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

// Support functions
import { useNavigate } from 'react-router-dom'
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

// Components and blocks
import WelcomeBanner from 'components/pages/navigation/WelcomeBanner';
import WelcomeFooter from 'components/pages/navigation/WelcomeFooter';
import LanguageBar from 'components/pages/navigation/LanguageBar';
import HelpMemo from 'components/pages/help/_Help/HelpMemo';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

const default_settings = {
  leftButtons: [{ // Authenticated
    title: 'Discito',
    textColor: 'light',
    backgroundColor: 'logo',
    to: '/dashboard?pk=DASHBOARD',
    visibleIfAuthenticated: true,
    visibleIfNotAuthenticated: false,
    isImage: true,
    imagePath: 'img/icons/discito_logo_small_yellow.png',
  },
  {
    title: 'Discito',
    textColor: 'light',
    backgroundColor: 'gray1',
    to: '/dashboard?pk=DASHBOARD',
    visibleIfAuthenticated: true,
    visibleIfNotAuthenticated: false,
  },
  { // Non-authenticated
    title: 'Discito',
    textColor: 'light',
    backgroundColor: 'logo',
    to: '/',
    visibleIfAuthenticated: false,
    visibleIfNotAuthenticated: true,
    isImage: true,
    imagePath: 'img/icons/discito_logo_small_yellow.png',
  },
  {
    title: 'Discito',
    textColor: 'light',
    backgroundColor: 'gray1',
    to: '/',
    visibleIfAuthenticated: false,
    visibleIfNotAuthenticated: true,
  }],
  rightButtons: [{
    title: 'Logout',
    textColor: 'light',
    backgroundColor: 'blue1',
    to: '/logout',
    visibleIfAuthenticated: true,
    visibleIfNotAuthenticated: false,
    isImage: true,
    imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
  },
  {
    title: '?',
    textColor: 'light',
    backgroundColor: 'gray1',
    to: '/help',
    visibleIfAuthenticated: false,
    visibleIfNotAuthenticated: true,
    isImage: true,
    imagePath: 'img/icons/symbols/white/icon_w_question-circle.png',
  },
  {
    title: 'Login',
    textColor: 'light',
    backgroundColor: 'gold',
    to: '/login',
    visibleIfAuthenticated: false,
    visibleIfNotAuthenticated: true,
    isImage: true,
    imagePath: 'img/icons/symbols/white/icon_w_person-circle-fill.png',
  }]
}

export function Help() {
  // Language selection for static texts
  const [activeLanguage, setActiveLanguage] = useState('en');
  const currentTexts = idioms[activeLanguage];

  // Static texts aliases
  const what_to_do_if_issues = currentTexts.what_to_do_if_issues;
  const faq_issues = currentTexts.faq_issues;

  // Authentication
  const { user } = useAuthenticator();
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ])

  // Set-up top bar
  useNavigationConfig(
    route === 'authenticated' ? user.username : '', '', default_settings.leftButtons, default_settings.rightButtons);

  // Use the useLayoutEffect hook to scroll to the top after the component is rendered
  useScrollToTop();

  // Responsiveness
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= 768;

  //Styles
  const styles = componentStyles(isSmallScreen);
  const paragraphs = paragraphStyles(isSmallScreen);
  //Images
  const images = staticImages;

  // Styles
  const titleStyle = {
    textAlign: 'left',
    marginTop: isSmallScreen ? 12 : 16,
    //cursor: 'pointer',
    fontSize: isSmallScreen ? 20 : 24,
    fontWeight: 400,
    //color: colors.amber,

  }


  return (
    <div className="app">

      <WelcomeBanner />

      <main>
        <div style={virtualFullWidth}>
          <div style={availableWidth}>

            <div style={styles.mainDivStyle}>
              <div style={{ ...titleStyle, marginLeft: isSmallScreen ? 14 : 18, marginBottom: 0 }}>

                {/* What to do if... */}

                <div style={{ ...titleStyle, marginLeft: 0, marginBottom: isSmallScreen ? 24 : 36 }}>
                  <LanguageBar
                    style={{ ...titleStyle, marginLeft: 0, color: colors.amber }}
                    title={currentTexts.what_to_do_if_title}
                    setUserInput={setActiveLanguage} />

                  {Object.keys(what_to_do_if_issues).map((issueKey) => {
                    const issue = what_to_do_if_issues[issueKey];
                    return (
                      <HelpMemo issueKey={issueKey} issue={issue} />
                    );
                  })}

                </div>

                {/* Frequently asked questions */}

                <div style={{ ...titleStyle, marginBottom: isSmallScreen ? 24 : 36 }}>
                  <LanguageBar
                    style={{ ...titleStyle, marginLeft: 0, color: colors.amber }}
                    title={currentTexts.faq_title}
                    setUserInput={setActiveLanguage}
                    hideIcons={true} />

                  {Object.keys(faq_issues).map((issueKey) => {
                    const issue = faq_issues[issueKey];
                    return (
                      <HelpMemo issueKey={issueKey} issue={issue} />
                    );
                  })}

                </div>

              </div>
            </div>

          </div>
        </div>
      </main>

      <WelcomeFooter />

    </div >
  );
}
