import { colors } from 'components/styles/colors';
import React, { useState, useEffect, useMemo, useCallback, useLayoutEffect } from 'react';
import {
  useAuthenticator,
  Heading,
  Button,
  Flex,
  Text,
  TextField,
  View,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@aws-amplify/ui-react';
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';

import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';

import { CreateSentence } from 'graphql/queries';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import {
  listWordsQueryRanged,
  createWordMutation,
  deleteWordMutation,
} from 'components/queries/queries';
import { generateRandomString } from 'components/_common/functions/generateRandomString';

export function NewWords() {
  const { user } = useAuthenticator()

  // Read clipboard
  // eslint-disable-next-line
  const { clipboard, _setClipboard } = useTopBarCLipboardContext();

  // Set-up navigation buttons
  const leftButtons = useMemo(() => [
    {
      title: 'Back',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/dashboard?pk=DASHBOARD',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
    },
  ], []);
  const rightButtons = useMemo(() => [
    {
      title: 'Logout',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/logout',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
    }
  ], []);
  useNavigationConfig(null, null, leftButtons, rightButtons);

  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= 768;

  const [buffer, setBuffer] = useState([]);
  const [word, setWord] = useState('');
  const [isLoading, setIsLoading] = useState(true)
  const [selectedOption, setSelectedOption] = useState(' ');
  const [classroomOptions, setClassroomOptions] = useState({});

  const fetchDropdown = useCallback(async () => {

    const lambdaData = await fetchBackendData({
      variables: {
        payload: JSON.stringify(
          {
            task: 'readAddWordDropdown'
          }
        ),
      },
    }, false, true)
    const lambdaResponse = lambdaData.data.Gateway

    const defaultDropdownValue = lambdaResponse.response
    setSelectedOption(defaultDropdownValue)
    const bufferFromLambda = JSON.parse(lambdaResponse.payload)['unitsDropdown']
    setClassroomOptions(bufferFromLambda);
    setIsLoading(false);
  }, []);

  const fetchWords = useCallback(async () => {
    if (!isLoading) {
      const apiData = await fetchBackendData({
        query: listWordsQueryRanged,
        variables: {
          id: '_WORD',
          range: selectedOption
        },
      }, false, true)
      const bufferFromAPI = apiData.data.listWords.items;
      setBuffer(bufferFromAPI);
    }
  }, [selectedOption, isLoading]);

  useEffect(() => {
    fetchWords();
  }, [fetchWords]);

  useEffect(() => {
    fetchDropdown();
  }, [fetchDropdown]);

  async function createWords(event) {
    event.preventDefault();
    const randomString = generateRandomString(5);

    // Split the input into words, capitalize the first letter of each word, and then join them back
    const capitalizedWord = word
      .trim() // Remove leading/trailing spaces
      .split(' ') // Split the input into an array of words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word's first letter
      .join(' '); // Join the words back into a single string with spaces

    await fetchBackendData({
      query: createWordMutation,
      variables: {
        id: '_WORD',
        range: `${selectedOption}~${randomString}`,
        name: capitalizedWord,
        classes: selectedOption.split('~')[0],
        unitId: selectedOption.split('~').slice(1).join('~'),
        author: user.username,
      },
    }, false, true)

    // Invoke CreateSentence asynchronously without waiting for the response
    fetchBackendData({
      query: CreateSentence,
      variables: {
        author: user.username,
        payload: capitalizedWord,
        userClassroom: selectedOption.split('~')[0],
        unitId: selectedOption.split('~').slice(1).join('~'),
      }
    }, false, true)
    fetchWords();
    setWord(''); // Clear the text in the textboxes
  }

  async function deleteWords(row) {
    await fetchBackendData({
      query: deleteWordMutation,
      variables: { id: row.id, range: row.range },
    }, false, true)
    fetchWords();
  }

  // Use useEffect to log the clipboard when it changes
  useEffect(() => {
    // console.log('NewWords Clipboard:', clipboard);
  }, [clipboard]);

  // Use the useLayoutEffect hook to scroll to the top after the component is rendered
  useLayoutEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0); // Scroll to the top instantly
    };
    scrollToTop(); // Call the function after the component is rendered
  }, []);

  return (
    <View className="app">

      <Heading level={3} margin='2rem 0'>
        Add new word
      </Heading>

      <View as='form' margin='3rem 20px' onSubmit={createWords}>

        <Flex direction='row' justifyContent='center' margin={isSmallScreen ? '0px 0 10px' : '0px 0 10px'}>
          <TextField
            name='name'
            placeholder='New word'
            label='Word'
            labelHidden
            variation='quiet'
            required
            value={word} // Bind the value of the word textbox to the state
            onChange={(e) => setWord(e.target.value)} // Update the state on change
          />
        </Flex>

        <Flex direction='row' justifyContent='center'>
          <select
            name='classroom'
            required
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            {Object.keys(classroomOptions).map((optionValue) => (
              <option key={optionValue} value={optionValue}>
                {classroomOptions[optionValue].text}
              </option>
            ))}
          </select>

          <Button type='submit' variation='primary' disabled={!word}>
            Add
          </Button>

        </Flex>
      </View>

      <Heading level={3}>Current words</Heading>

      <View margin={isSmallScreen ? '3rem 0' : '3rem 20%'}>
        {buffer.length > 0 && (
          <Table highlightOnHover={true}>
            <TableHead>
              <TableRow>
                <TableCell
                  as='th'
                  style={{ width: '25%', backgroundColor: 'lightblue' }}
                >
                  Word
                </TableCell>
                <TableCell
                  as='th'
                  style={{ width: '50%', backgroundColor: 'lightblue' }}
                >
                  Classroom
                </TableCell>
                <TableCell
                  as='th'
                  style={{ width: '25%', backgroundColor: 'lightblue' }}
                ></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {buffer.map((row, index) => (
                <TableRow
                  key={`${row.id}-${row.range}`}
                  style={
                    index === 0 ? { backgroundColor: 'verylightblue' } : null
                  }
                >
                  <TableCell style={{ width: '25%' }}>
                    <Text>{row.name}</Text>
                  </TableCell>
                  <TableCell style={{ width: '50%' }}>
                    <Text as='span'>{row.classes}</Text>
                  </TableCell>
                  <TableCell style={{ width: '25%' }}>
                    <Button variation='link' onClick={() => deleteWords(row)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </View>
    </View>
  );
}