import { getUpdates } from 'components/queries/queries';
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';

export async function fetchUpdates() {

    const payload = {
        query: getUpdates,
        variables: {
            id: '.',
            range: 'UPDATE',
        },
    }

    const isGraphQL = true; const isGateway = false  // must be passed as default is true in fetchBackendData.js

    const apiData = await fetchBackendData(payload, isGateway, isGraphQL)

    return {
        currentRelease: apiData.data.getWords.currentRelease,
        currentReleaseDescription: apiData.data.getWords.description,
    };

}
