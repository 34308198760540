import { listQuestionsQueryRanged, listQuestionsQueryRangedTranslation } from 'components/queries/queries';
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';

export async function fetchQuestions() {

    const isGraphQL = true; const isGateway = false  // must be passed as default is true in fetchBackendData.js

    // Select a random slice
    const randomInteger = Math.floor(Math.random() * 10); // Generates a random integer between 0 and 9
    const range = `unique0${randomInteger}`;

    // Call gql->ddb to fetch 'Q-MAT-4' data
    const api_call = await fetchBackendData({
        query: listQuestionsQueryRanged,
        variables: {
            id: 'Q-MAT-4',
            range: range, // begins_with
            limit: 100,
        },
    }, isGateway, isGraphQL);
    const questionsQMat4 = api_call.data.listWords.items; // Contains 'Q-MAT-4' data

    // Call gql->ddb to fetch 'Q-MAT-4>CZ' data
    const api_call_cz = await fetchBackendData({
        query: listQuestionsQueryRangedTranslation,
        variables: {
            id: 'Q-MAT-4>CZ',
            range: range, // begins_with
            limit: 100,
        },
    }, isGateway, isGraphQL);
    const questionsQMat4Cz = api_call_cz.data.listWords.items; // Contains 'Q-MAT-4>CZ' data

    // Merge 'question_cz' from 'Q-MAT-4>CZ' into 'Q-MAT-4'
    return questionsQMat4.map(qMat4 => {
        const qMat4Cz = questionsQMat4Cz.find(q => q.range === qMat4.range);
        if (qMat4Cz) {
            return {
                ...qMat4,
                question_cz: qMat4Cz.question,
            };
        }
        return qMat4;
    });

}
