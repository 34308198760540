import { globalSettings } from 'GlobalSettings';
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React from 'react';

// Components and blocks
import PageTitle from 'components/pages/navigation/PageTitle';


const StateNavigationHead = ({ pageSettings }) => {

    // Check if the required data is not available and return null if so
    if (!pageSettings || !pageSettings.activityTitle || !pageSettings.activitySubtitleKey || !globalSettings[pageSettings.activitySubtitleKey]) {
        return null
    }

    return (

        <PageTitle
            title={pageSettings.activityTitle + " | " + globalSettings[pageSettings.activitySubtitleKey]}
            subtitle={globalSettings.clientName} />
        
    );
};

export default StateNavigationHead;
