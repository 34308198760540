// Colors, styles etc.
import { globalSettings } from 'GlobalSettings';
import { colors } from 'components/styles/colors';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { componentStyles } from 'components/styles/component_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { staticImages } from 'img/static_images';

// Basic core functions
import 'components/styles/specific/amplify_auth.css'
import { Auth } from 'aws-amplify'
import { components } from 'components/_amplify_login/AuthComponents';
import { formFields } from 'components/_amplify_login/AuthFormFields';
import React, { useEffect, useCallback, useState } from 'react'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'

// Support functions
import { useNavigate } from 'react-router-dom'
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData'

// Components and blocks
import WelcomeBanner from 'components/pages/navigation/WelcomeBanner';
import WelcomeFooter from 'components/pages/navigation/WelcomeFooter';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';
import LoadingAnimation from 'components/_common/LoadingAnimation';

const default_settings = {
  leftButtons: [{
    title: 'Discito',
    textColor: 'light',
    backgroundColor: 'logo',
    to: '/',
    visibleIfAuthenticated: true,
    visibleIfNotAuthenticated: true,
    isImage: true,
    imagePath: 'img/icons/discito_logo_small_yellow.png',
  },
  {
    title: 'Discito',
    textColor: 'light',
    backgroundColor: 'gray1',
    to: '/',
    visibleIfAuthenticated: true,
    visibleIfNotAuthenticated: true,
  }],
  rightButtons: [{
    title: 'Logout',
    textColor: 'light',
    backgroundColor: 'blue1',
    to: '/logout',
    visibleIfAuthenticated: true,
    visibleIfNotAuthenticated: false,
    isImage: true,
    imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
  },
  {
    title: '?',
    textColor: 'light',
    backgroundColor: 'gray1',
    to: '/help',
    visibleIfAuthenticated: false,
    visibleIfNotAuthenticated: true,
    isImage: true,
    imagePath: 'img/icons/symbols/white/icon_w_question-circle.png',
  },
  {
    title: 'Login',
    textColor: 'light',
    backgroundColor: 'gold',
    to: '/login',
    visibleIfAuthenticated: false,
    visibleIfNotAuthenticated: true,
    isImage: true,
    imagePath: 'img/icons/symbols/white/icon_w_person-circle-fill.png',
  }]
}

export function Login() {

  // Authentication
  const { user } = useAuthenticator();
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ])
  const navigate = useNavigate()
  const logOut = useCallback(() => {
    signOut()
    navigate('/login')
  }, [signOut, navigate])

  // Read clipboard
  const { clipboard, setClipboard } = useTopBarCLipboardContext();
  const localReleaseVersion = clipboard.localReleaseVersion;

  // Set-up top bar
  useNavigationConfig(
    route === 'authenticated' ? user.username : '', '', default_settings.leftButtons, default_settings.rightButtons);

  // Visuals
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= 768;

  //Styles
  const styles = componentStyles(isSmallScreen);
  const paragraphs = paragraphStyles(isSmallScreen);
  //Images
  const images = staticImages;

  // Login procedure: the login screen is shown and we are waiting for route to be updated to 'authenticated'. When it happens this chain of events happen:
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {

    if (route === 'authenticated' && !isLoading) {

      setIsLoading(true);

      const isGraphQL = true; const isGateway = false  // must be passed as default is true in fetchBackendData.js

      // Access user details and console log
      Auth.currentAuthenticatedUser()

        .then((user) => {

          fetchBackendData({
            variables: {
              payload: JSON.stringify(
                {
                  task: 'postAuth',
                  userIdentity: {
                    localReleaseVersion: localReleaseVersion.value,
                    userName: user.username,
                  },
                }
              ),
            },
          }, isGateway, isGraphQL)

            .then((response) => {

              const userInfo = JSON.parse(response.data.Gateway.payload)
              const { success } = response.data.Gateway

              if (success) {
                setClipboard((prevClipboard) => ({
                  ...prevClipboard,
                  userName: {
                    value: user.username,
                  },
                  userClassroom: {
                    value: userInfo.classes,
                  },
                  isConsent: {
                    value: userInfo.isConsent,
                  },
                  unitId: {
                    value: 'dashboard',
                  },
                  returnNavigationPath: {
                    value: '/dashboard?pk=DASHBOARD',
                  },
                  notifications: userInfo.notifications
                }));

                setIsLoading(false);

                if (userInfo.isConsent) {
                  navigate('/dashboard?pk=DASHBOARD') // redirect after successful login
                } else {
                  navigate('/user/consent') // redirect after successful login
                }

              } else {
                console.error('PostAuthentication failed:', response)
                logOut() // sign out the user and redirect to login page
              }

            }) // .then((user) => {

        }) // .then((response) => {
    }
  }, [route, navigate, logOut, isLoading, setClipboard, localReleaseVersion])

  // Use the useLayoutEffect hook to scroll to the top after the component is rendered
  useScrollToTop();
  // useDebugClipboard(clipboard);

  return (
    <div className="app">

      <WelcomeBanner />

      <main>
        <div style={virtualFullWidth}>
          <div style={availableWidth}>

            <div style={styles.mainDivStyle}>

              {/* Is there a way how to make this responsive? */}

              {isLoading ? ( // authenticated state (while loading, after that navigates to consent screen or dashboard)

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // height: '100vh',
                  }}
                >
                  {isLoading && <LoadingAnimation />}
                </div>

              ) : ( // non-authenticated state

                <div>

                  {route !== 'authenticated' && (
                    <div
                      className='auth-wrapper'>
                      <Authenticator
                        components={components}
                        hideSignUp="true"
                        formFields={formFields}
                      />
                    </div>
                  )}

                </div>

              )}

            </div>

          </div>
        </div >
      </main >

      <WelcomeFooter />

    </div >
  );
}





