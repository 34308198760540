import { colors } from 'components/styles/colors';
import { textStyles } from 'components/styles/text_styles';
import { paragraphStyles } from 'components/styles/paragraph_styles';

import React, { useState } from 'react';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

const check_symbol = require('img/icons/symbols/black/icon_check-circle.png');
const bell_symbol = require('img/icons/symbols/black/icon_bell_w_64x64.png');
const warning_symbol = require('img/icons/symbols/black/icon_exclamation-circle.png');
const closing_symbol = require('img/icons/symbols/black/icon_x.png');
const envelope_symbol = require('img/icons/symbols/black/icon_envelope.png');
const question_symbol = require('img/icons/symbols/black/icon_question-circle.png');
const chat_symbol = require('img/icons/symbols/black/icon_chat.png');
const error_symbol = require('img/icons/symbols/black/icon_exclamation-circle-fill.png');
const gear_symbol = require('img/icons/symbols/black/icon_gear.png')
const info_symbol = require('img/icons/symbols/black/icon_info-circle.png')

const SnackBarSingle = (props) => {
    const { title, subtitle, color, canBeClosed, symbol, activeLanguage, setActiveLanguage } = props;

    // Control closing button action
    const [isSnackbarVisible, setSnackbarVisible] = useState(true);
    const handleSnackbarClose = () => {
        setSnackbarVisible(false);
    };

    // Match the symbol to the corresponding image source
    let symbolSource = null; // Default to null symbol
    switch (symbol) {
        case 'check':
            symbolSource = check_symbol;
            break;
        case 'bell':
            symbolSource = bell_symbol;
            break;
        case 'warning':
            symbolSource = warning_symbol;
            break;
        case 'envelope':
            symbolSource = envelope_symbol;
            break;
        case 'question':
            symbolSource = question_symbol;
            break;
        case 'chat':
            symbolSource = chat_symbol;
            break;
        case 'error':
            symbolSource = error_symbol;
            break;
        case 'info':
            symbolSource = info_symbol;
            break;
        case 'gear':
            symbolSource = gear_symbol;
            break;
    }

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles common to all devices
    const containerStyle = {
        backgroundColor: color || colors.gray6, // Set default color to lightgray if not specified
        margin: isSmallScreen ? "12px" : "18px",
        padding: isSmallScreen ? "4px" : "6px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // Align items with space between them
        borderRadius: isSmallScreen ? 6 : 9,
        boxShadow: "2px 2px 5px" + (colors.amber_a06 || colors.gray6)
    }

    const iconStyle = { margin: isSmallScreen ? "8px" : "12px", height: 24, width: 24, };
    const contentStyle = { display: 'flex', alignItems: 'center', marginRight: isSmallScreen ? "8px" : "12px" };
    const textStyle = { marginLeft: isSmallScreen ? "8px" : "12px" }
    const closeButtonArea = { cursor: 'pointer', marginRight: isSmallScreen ? "8px" : "12px" };
    const closeButtonStyle = { cursor: 'pointer', height: 24, width: 24, };

    // Styles depending on device type
    const titleStyle = {
        ...isSmallScreen ? paragraphStyles.snackbarMtitle : paragraphStyles.snackbarDtitle,
        textAlign: "left",
        fontWeight: 700,
    };

    const subtitleStyle = {
        ...isSmallScreen ? paragraphStyles.snackbarMtext : paragraphStyles.snackbarDtext, textAlign: "left"
    };

    return (
        <>
            {isSnackbarVisible && (

                <div style={containerStyle}>

                    <div style={contentStyle}>

                        {symbolSource && (
                            <img src={symbolSource} alt={symbol} style={iconStyle} />
                        )}

                        <div style={textStyle}>
                            <div style={titleStyle}>
                                {title}
                            </div>
                            <div
                                style={subtitleStyle}
                                onDoubleClick={() => setActiveLanguage(activeLanguage === 'en' ? 'cz' : 'en')}>
                                {subtitle}
                            </div>
                        </div>

                    </div>

                    {canBeClosed && (

                        <div style={closeButtonArea} onClick={handleSnackbarClose}>
                            <img src={closing_symbol} alt="Close" style={closeButtonStyle} />
                        </div>

                    )}

                </div>

            )}
        </>
    );
}

export default SnackBarSingle;
