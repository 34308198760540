import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';

import WelcomeBanner from 'components/pages/navigation/WelcomeBanner';
import WelcomeFooter from 'components/pages/navigation/WelcomeFooter';
import CardsCoverPage from 'components/pages/navigation/CardsCoverPage';

export function Test01() {

    return (
        <div className="app">

            <WelcomeBanner />

            <main>
                <div style={virtualFullWidth}>
                    <div style={availableWidth}>

                        <div>Below is WIP CardsCoverPage</div>

                        <CardsCoverPage title='some text here' />

                        <div>End of page</div>


                    </div>
                </div>
            </main>

            <WelcomeFooter />

        </div >
    )
}
