import { colors } from 'components/styles/colors';
import { useMemo, useEffect } from 'react';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';


export function useNavigationConfig(userName = null, userClassroom = null, receivedLeftButtons = [], receivedRightButtons = [], isPageSettingsLoaded = true, isLoading = false) {

    // get setters to modify the buttons
    const { setLeftButtons, setRightButtons, setTextCredentials } = useTopBarCLipboardContext();

    // prepare the modified buttons and text credentials
    const modifiedTextCredentials = useMemo(() => {
        const credentials = [];
        // add username if not previously given
        if (userName !== null) {
            credentials.push({
                text: userName,
                textColor: colors.light
            });
        }
        // add classroom if not previously given
        if (userClassroom !== null) {
            credentials.push({
                text: userClassroom,
                textColor: colors.light
            });
        }
        return credentials;

    }, [userName, userClassroom]);

    // apply changes in the top bar
    useEffect(() => {
        // Apply changes only if isPageSettingsLoaded is true
        if (!isPageSettingsLoaded || isLoading) {
            console.warn('useNavigationConfig: return', isPageSettingsLoaded, isLoading)
            return;
        }

        setLeftButtons(receivedLeftButtons);
        setRightButtons(receivedRightButtons);
        if (modifiedTextCredentials.length > 0) {
            setTextCredentials(modifiedTextCredentials);
        }

    }, [receivedLeftButtons, receivedRightButtons, modifiedTextCredentials, isPageSettingsLoaded]);
}
