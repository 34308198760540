// Migration: import { generateClient } from 'aws-amplify/api';
// Migration: const client = generateClient();
import { API } from 'aws-amplify';
import { Gateway } from 'components/../graphql/queries';

const enableCache = true;
const cache = {};
const cacheDuration = 20 * 1000; // milliseconds
const cachedTasks = [
    'readItems',
    'languageOverviewWords',
    'vocabExam',
    'variationsExam',
    'vocabQuiz',
    'prepositionsQuiz',
];

// Function to generate cache key
const generateCacheKey = (queryParams) => {
    // Check if 'task' is in queryParams and its value is 'readItems'
    if (queryParams.task && cachedTasks.includes(queryParams.task) && queryParams.range) {
        // Construct cache key using 'task', 'id', 'range', and 'unitId'
        const cacheKey = `${queryParams.task}|${queryParams.id}|${queryParams.range}|${queryParams.unitId}`;
        return cacheKey;
    }
    // Otherwise
    return null;
};

// Helper function to get cached data taking into account eviction time
const getCachedData = (key) => {
    const cacheEntry = cache[key];
    if (cacheEntry && (new Date().getTime() - cacheEntry.timestamp) < cacheDuration) {
        // console.log('cache hit')
        return cacheEntry.data;
    } else {
        // console.log('cache miss')
    }
    return null;
};

// Helper function to update cache after each request
const updateCache = (key, data) => {
    if (key !== null && data !== null) {
        cache[key] = {
            data: data,
            timestamp: new Date().getTime() // Current time in milliseconds
        };
    }
};

// Helper function to clean up expired cache entries
const cleanupExpiredCache = () => {
    const now = new Date().getTime();
    Object.keys(cache).forEach(key => {
        if (now - cache[key].timestamp >= cacheDuration) {
            delete cache[key];
            // console.log(`Cleaning up expired cache key: ${key} [${Object.keys(cache).length}]`);
        }
    });
};

// Schedule periodic cache cleanup
setInterval(cleanupExpiredCache, cacheDuration);

export const fetchBackendData = async (queryParams, isGateway = true, isGraphQL = false) => {

    // Use Gateway as the default query
    if (!queryParams.query) {
        queryParams.query = Gateway;
    }

    if (isGateway) {

        // Log
        // console.log(`Cache Entries Count: ${Object.keys(cache).length}${Object.keys(cache).length > 0 ? `, Cache Keys: ${Object.keys(cache).join(', ')}` : ', Cache is empty'}`);

        // Generate the cache hash key
        const cacheKey = generateCacheKey(queryParams);

        // Attempt to get cached data
        const cachedData = getCachedData(cacheKey);
        if (cachedData !== null && enableCache) {
            return cachedData;
        }

        // Submit query
        const apiData = await API.graphql({
            query: Gateway,
            variables: {
                payload: JSON.stringify(queryParams),
            },
        });
        const curatedApiData = JSON.parse(apiData.data.Gateway.payload);

        // Update cache and return
        updateCache(cacheKey, curatedApiData);
        return curatedApiData;
    }

    // Do not cache for graphQL
    if (isGraphQL) {

        return await API.graphql(queryParams); // Migration: replace API -> client

    }
};