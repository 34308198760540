import { useEffect, useLayoutEffect } from 'react';

export const useDebugClipboard = (clipboard) => {
    useEffect(() => {
        console.log('Clipboard:', clipboard);
    }, [clipboard]);
};

export const useScrollToTop = (dependencies = [], isLoading) => {
    useLayoutEffect(() => {
        // Only scroll to top if isLoading is false
        if (!isLoading) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [...dependencies, isLoading]);
};
