// Colors, styles etc.
import { globalSettings } from 'GlobalSettings';
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import { icons } from 'img/icons';
import idioms from 'locales/idioms_static';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';

// Components and blocks
import CardArray from 'components/pages/browser/utils/CardArray';

const StateNavigationBody = ({ clipboard, cardsData, pageSettings, currentNavigationPath }) => {

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const paragraphs = paragraphStyles(isSmallScreen);
    const styles = componentStyles(isSmallScreen);

    // Check if there are no cards to render
    if (cardsData.length === 0) {
        return null; // Return null if there are no cards to render
    }

    // Render CardArray if there are cards
    return (
        <div>
            <CardArray
                title={pageSettings.activityDescription || clipboard.activityRef.value.title}
                cards={cardsData}
                isUnitRoot={pageSettings.isUnitRoot}
                currentNavigationPath={currentNavigationPath}
            />

            <div style={styles.dashboardDividerStyle}> </div>
        </div>
    );
};

export default StateNavigationBody;
