// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { paragraphStyles } from 'components/styles/paragraph_styles';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';

const UnitManagementStateCreate = ({ backendData, setSequenceState, selectedCourse, setSelectedCourse, dataChangesCount, setDataChangesCount }) => {

    // Form contents defaults
    const [unitDescription, setUnitDescription] = useState('Spelling');
    const [serverResponse, setServerResponse] = useState('');
    // Form contents defaults, function to calculate next Wednesday
    const getNextWednesday = () => {
        const today = new Date();
        const nextWednesday = new Date(today);
        nextWednesday.setDate(today.getDate() + ((3 - today.getDay() + 7) % 7 || 7)); // Adjust for next Wednesday
        // Format date as 'YYYY-MM-DD'
        return nextWednesday.toISOString().split('T')[0];
    };
    const [dueDate, setDueDate] = useState(getNextWednesday());

    // Control the hovering effect
    const [isHovered, setIsHovered] = useState(false);

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const styles = componentStyles(isSmallScreen, isHovered);
    const paragraphs = paragraphStyles(isSmallScreen);

    const serverResponseStyle = {
        width: '30%', // Use 30% of the parent's width
        margin: '0 auto', // Center the div within the parent
        textAlign: 'left' // Ensure content is aligned to the left
    }

    // Handle buttons: save a unit
    const handleSaveClick = () => {
        const backendPayload = {
            task: 'administration-units',
            action: 'create',
            classroom: selectedCourse,
            unitDescription: unitDescription,
            dueDate: dueDate,
            backendData: backendData,
        };
        const fetchData = async () => {
            const response = 'Created - dummy message until we connect backend here' // await fetchBackendData(backendPayload);
            setServerResponse(response);
            setDataChangesCount(prevCount => prevCount + 1);
        };
        fetchData();
    };

    // Handle buttons: back to MAIN
    const handleBackClick = () => {
        setSequenceState('MAIN')
    };

    return (
        <div style={styles.mainDivStyle}>

            {/* Create unit section */}
            <div style={{ ...styles.captionLabelStyle, marginTop: 0 }}>
                <div style={paragraphs.sectionHeadingSubtitle}>
                    Create new unit
                </div>
            </div >

            <div>
                {/* Course */}
                <div style={styles.simpleTextStyleFlexML1MR1}>

                    <div style={styles.simpleTextStyleFlex8020}>
                        Course:
                    </div >

                    <input
                        style={{ ...styles.simpleSelectStyle80pct, border: 'none' }}
                        id="classroom"
                        type="pladeholder - course name"
                        value={selectedCourse}
                        readOnly
                    />
                </div>

                {/* Quiz type */}
                <div style={styles.simpleTextStyleFlexML1MR1}>

                    <div style={styles.simpleTextStyleFlex8020}>
                        Quiz type:
                    </div >

                    <input
                        style={styles.simpleSelectStyle80pct}
                        id="unitDescription"
                        value={unitDescription}
                        onChange={(e) => setUnitDescription(e.target.value)}
                    />
                </div>

                {/* Due date */}
                <div style={styles.simpleTextStyleFlexML1MR1}>

                    <div style={styles.simpleTextStyleFlex8020}>
                        Due date:
                    </div >

                    <input
                        style={styles.simpleSelectStyle80pct}
                        id="dueDate"
                        value={dueDate}
                        onChange={(e) => setDueDate(e.target.value)}
                    />
                </div>
            </div>

            <div style={styles.simpleTextStyle}>
                <button
                    style={styles.buttonStyleCore}
                    onClick={() => handleSaveClick()}
                >
                    Create unit
                </button>
                <button
                    style={styles.buttonStyleInactive}
                    onClick={() => handleBackClick()}
                >
                    Back
                </button>
            </div>

            {/* Divider */}
            <div style={styles.dividerStyle}></div>

            {serverResponse && serverResponse !== '' && (
                <div style={serverResponseStyle}>
                    <pre>{JSON.stringify(serverResponse, null, 2)}</pre>
                </div>
            )}


        </div>
    );
};

export default UnitManagementStateCreate;
