// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize'; // To handle responsiveness

// Components and blocks
import PageNavigationBarQuiz from 'components/pages/languages/_VocabularyExamLong/PageNavigationBarQuiz';

const StateQuiz = ({ currentIndex, setCurrentIndex, backendData, setCurrentState, setAnswerList, score, setScore }) => {

    // Navigation controls
    const goToReview = () => {
        setCurrentState('REVIEW');
    };

    // Determine words available
    const [allSentences, setAllSentences] = useState([]);

    const [choicesAvailable, setChoicesAvailable] = useState([]);
    // Function to shuffle an array
    const shuffleArray = (array) => {
        let currentIndex = array.length, randomIndex;
        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
        return array;
    }
    // Effect to process backendData
    useEffect(() => {
        if (backendData) {
            // Flatten the entire dataset to get all sentences
            const aggregatedSentences = backendData.flat();
            //            const aggregatedSentences = backendData.map(item => item.sentence);
            setAllSentences(aggregatedSentences);

            // Collect and shuffle all unique answers
            let allAnswers = aggregatedSentences
                .map(item => item.answer)
                .filter(answer => answer !== undefined);
            allAnswers = Array.from(new Set(allAnswers)); // Remove duplicates

            // Shuffle the answers
            const shuffledAnswers = shuffleArray(allAnswers);
            setChoicesAvailable(shuffledAnswers);
        }
    }, [backendData]);

    // Handle sentences clicked
    const [selectedSentence, setSelectedSentence] = useState(0);
    const [assignedWords, setAssignedWords] = useState({});
    const handleSentenceClick = (index) => {
        setSelectedSentence(index);
    };

    // Handle words clicked
    const handleChoiceClick = (choice) => {
        if (selectedSentence !== null) {
            setAssignedWords({ ...assignedWords, [selectedSentence]: choice });

            // Update answerList and compute the score based on the latest values
            setAnswerList(prevAnswers => {
                const existingAnswerIndex = prevAnswers.findIndex(
                    answer => answer.question === allSentences[selectedSentence].question
                );

                const newAnswer = {
                    question: allSentences[selectedSentence].question,
                    answer: choice,
                    correctAnswer: allSentences[selectedSentence].answer
                };

                let updatedAnswers;
                if (existingAnswerIndex !== -1) {
                    // Update existing answer
                    updatedAnswers = [...prevAnswers];
                    updatedAnswers[existingAnswerIndex] = newAnswer;
                } else {
                    // Add new answer
                    updatedAnswers = [...prevAnswers, newAnswer];
                }

                // Compute the score based on updatedAnswers
                const newScore = updatedAnswers.reduce((score, answer) => {
                    return score + (answer.answer === answer.correctAnswer ? 1 : 0);
                }, 0);

                setScore(newScore);
                return updatedAnswers;
            });

            // Advance to the next sentence or handle the end of sentences
            if (selectedSentence < allSentences.length - 1) {
                setSelectedSentence(selectedSentence + 1);
            }
        }
    };

    const isChoiceCorrect = (choice, sentenceIndex) => {
        return choice === allSentences[sentenceIndex].answer;
    };

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const styles = componentStyles(isSmallScreen);

    return (
        <>
            {
                allSentences && (

                    <div style={styles.mainDivStyle}>

                        {/* Concerns sentences */}
                        <div style={styles.unselectedSentenceStyle}>
                            {allSentences.map((item, index) => {
                                let sentenceWithChoice;
                                if (assignedWords[index]) {
                                    const isCorrect = isChoiceCorrect(assignedWords[index], index);
                                    // const underscoreReplacementStyle = {
                                    //     // color: isCorrect ? colors.success : colors.error,
                                    // };

                                    // Replace the gap with the formatted word
                                    sentenceWithChoice = (
                                        <>
                                            {item.question.split('__________')[0]}
                                            <span style={styles.underscoreReplacementStyle}>
                                                __{assignedWords[index]}__
                                            </span>
                                            {item.question.split('__________')[1]}
                                        </>
                                    );
                                } else {
                                    sentenceWithChoice = item.question;
                                }

                                return (
                                    <div
                                        key={index}
                                        onClick={() => handleSentenceClick(index)}
                                        style={selectedSentence === index ? styles.selectedSentenceStyle : styles.unselectedSentenceStyle}
                                    >
                                        {sentenceWithChoice}
                                    </div>
                                );
                            })}
                        </div>

                        {/* Concerns buttons */}
                        <div style={{ textAlign: 'center', margin: isSmallScreen ? '10px 0' : '12px 0' }}>
                            {choicesAvailable.map((choice, index) => {
                                // Determine if this choice has been assigned to any sentence
                                const sentenceIndex = Object.keys(assignedWords).find(key => assignedWords[key] === choice);
                                let buttonStyle = {
                                    ...styles.choiceButtonStyle,
                                    backgroundColor: colors.gray6,
                                    color: colors.dark,
                                    border: colors.gray3,
                                };

                                // If assigned, check if it's correct and update style accordingly
                                if (sentenceIndex !== undefined) {
                                    const isCorrect = isChoiceCorrect(choice, sentenceIndex);
                                    buttonStyle = {
                                        ...buttonStyle,
                                        // backgroundColor: isCorrect ? colors.success : colors.error,
                                    };
                                }

                                return (
                                    <button
                                        key={index}
                                        onClick={() => handleChoiceClick(choice)}
                                        style={buttonStyle}
                                    >
                                        {choice}
                                    </button>
                                );
                            })}
                        </div>

                        <div style={styles.dividerStyle}></div>

                        <PageNavigationBarQuiz goToReview={goToReview} keysLength={allSentences.length} assignedWordsCount={Object.keys(assignedWords).length}
                        />

                    </div>
                )
            }
        </>
    );
};

export default StateQuiz;