// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import { fontStyles } from 'components/styles/fonts/fonts';
import consents from 'locales/consent_static';

// Basic core functions
import React, { useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react'

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { updateConsentMutation, } from 'components/queries/queries';
import { useNavigate } from 'react-router-dom';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

// Components and blocks
import WelcomeBanner from 'components/pages/navigation/WelcomeBanner';
import WelcomeFooter from 'components/pages/navigation/WelcomeFooter';
import LanguageBar from 'components/pages/navigation/LanguageBar';
import PageTitle from 'components/pages/navigation/PageTitle';
import PageTitleActivity from 'components/pages/navigation/PageTitleActivity';
import SectionHeadingToolbar from 'components/_common/SectionHeadingToolbar';

export function UserConsent() {

    // Language selection for static texts
    const [activeLanguage, setActiveLanguage] = useState('en');
    const currentTexts = consents[activeLanguage];
    const handleLanguageChange = (languageCode) => {
        setActiveLanguage(languageCode);
    };

    // Use the useLayoutEffect hook to scroll to the top after the component is rendered
    useScrollToTop();

    // Responsiveness
    const windowSize = useWindowSize(); // requires being inside 'export function'
    const isSmallScreen = windowSize.width <= 768;

    //Styles
    const styles = componentStyles(isSmallScreen); // requires isSmallScreen loaded

    // Handle settings: getters and setters from the parent-most page to each child
    const [userInput, setUserInput] = useState('');

    // Navigation
    const { user } = useAuthenticator();
    const navigate = useNavigate();

    // Navigation: Sign out current user
    const { signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);

    // Handle buttons: handleReturnClick
    const handleReturnClick = () => {
        signOut(); // signs out current user
        navigate('/login');
    };

    // Handle buttons: handleAcceptClick
    const handleAcceptClick = () => {
        const isGraphQL = true; const isGateway = false  // must be passed as default is true in fetchBackendData.js
        const backendExecute = async () => {
            await fetchBackendData({
                query: updateConsentMutation,
                variables: {
                    id: 'USER',
                    range: user.username,
                    isConsent: true
                }
            }, isGateway, isGraphQL)
        };
        backendExecute();
        navigate('/dashboard?pk=DASHBOARD');
    };

    return (
        <div className="app">

            <WelcomeBanner />

            <main>
                <div style={virtualFullWidth}>
                    <div style={availableWidth}>

                        <SectionHeadingToolbar
                            title={'New user ' + user.username + ','}
                            setUserInput={setUserInput}
                            infoText='InfoText'
                        />

                        <div style={styles.mainDivStyle}>

                            <div
                                style={{ ...styles.captionLabelStyle, marginBottom: 0, marginTop: 0 }}>
                                {currentTexts.user_consent_title}
                            </div >

                            <div
                                style={{ ...styles.simpleBarStyle, marginTop: 0, marginBottom: 0 }}>
                                <LanguageBar
                                    style={{ ...styles.captionLabelStyle, marginTop: 0, marginBottom: 0 }}
                                    setUserInput={setActiveLanguage} />
                            </div >

                            <div
                                style={{ ...styles.simpleBarStyle, marginTop: 0 }}>
                                <img src={require('img/logo.png')} className='logo' alt='Logo' />
                            </div >

                            <div
                                style={styles.simpleBarStyle}>
                                {currentTexts.user_consent_privacy_policy}
                            </div>

                            <div
                                style={styles.dividerStyle}>
                            </div >

                            <div
                                style={styles.simpleTextStyle}>
                                {currentTexts.user_consent_par1}
                            </div >

                            <div
                                style={styles.simpleTextStyle}>
                                <strong>{currentTexts.user_consent_par2a}</strong>{currentTexts.user_consent_par2b}
                            </div >

                            <div
                                style={styles.simpleTextStyle}>
                                <strong>{currentTexts.user_consent_par3a}</strong>{currentTexts.user_consent_par3b}
                            </div >

                            <div
                                style={styles.simpleTextStyle}>
                                <strong>{currentTexts.user_consent_par4a}</strong>{currentTexts.user_consent_par4b}
                            </div >

                            <div
                                style={styles.dividerStyle}>
                            </div >

                            <div
                                style={styles.simpleTextStyle}>
                                {currentTexts.user_consent_par5}
                            </div >

                            <div
                                style={styles.simpleBarStyle}>
                                <button
                                    onClick={() => handleReturnClick()}
                                    style={styles.buttonStyleInactive}
                                //type='submit'
                                //variation='primary'
                                >
                                    Return
                                </button>
                                <button
                                    onClick={() => handleAcceptClick()}
                                    style={styles.buttonStyleCore}
                                //type='submit'
                                //variation='primary'
                                >
                                    Accept
                                </button>
                            </div >

                        </div>

                    </div>
                </div>
            </main >

            <WelcomeFooter />

        </div >
    )
}
