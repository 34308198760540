// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { useNavigate } from 'react-router-dom';

// Components and blocks
import WordBox from 'components/pages/languages/_SpellingTest/WordBox';

const StateFinished = ({ words, setSequenceState }) => {

    // Handle navigation
    const navigate = useNavigate();

    // Handle correct count
    const [correctCount, setCorrectCount] = useState(0);
    const handleCorrectChange = (isCorrect) => {
        setCorrectCount((prevCount) => {
            return isCorrect === 'correct' ? prevCount + 1 : prevCount - 1;
        });
    };

    // Control the hovering effect
    const [isHovered, setIsHovered] = useState(false);

    // Responsiveness
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    // Styles
    const styles = componentStyles(isSmallScreen, isHovered);

    return (
        <div style={styles.mainDivStyle}>

            <div
                style={styles.captionLabelStyle}>
                Correct results and evaluation
            </div >

            <div
                style={styles.supportingTextStyle}>
                Based on your results, click on the individual words. If you click once, the answer is marked as correct. If you click twice, the answer is markets as incorrect. You will see your result below.
            </div >

            <div>
                {words.map((word) => (
                    <div key={word}>
                        <WordBox key={word} title={word} onCorrectChange={handleCorrectChange} />
                    </div>
                ))}
            </div>

            <div style={styles.simpleTextStyle}>
                Your result: {correctCount}/{words.length} words correct.
            </div>

            <div style={styles.navigationBarStyle}>

                <button
                    onClick={() => { navigate('/dashboard?pk=ACTIVITY&sk=ENG~'); }}
                    style={styles.buttonStyleNavigationBar}
                >Back</button>

                <button
                    onClick={() => setSequenceState('IDLE')}
                    style={styles.buttonStyleNavigationBar}
                >Repeat</button>

            </div >

        </div >

    );
};

export default StateFinished;
