// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';
import idioms from 'locales/idioms_static';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Basic navigation elements
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';

// Backend connection
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';

// Support functions
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js';
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { globalSettings } from 'GlobalSettings';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

// Generic components and blocks
import SectionHeadingToolbar from 'components/_common/SectionHeadingToolbar';
import PageTitleActivity from 'components/pages/navigation/PageTitleActivity';
import DashboardFooter from 'components/pages/navigation/DashboardFooter';

// Page-specific components and clocks
import StateQuiz from 'components/pages/languages/_VocabularyExam/StateQuiz';
import StateReview from 'components/pages/languages/_VocabularyExam/StateReview';

const default_settings = {
  activityTitle: 'Vocabulary Test',
  leftButtons: [
    {
      title: 'Back',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/dashboard?pk=ACTIVITY&sk=ENG~',
      visibleIfAuthenticated: true,
      visibleIfNotAutheYnticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
    },
  ],
  rightButtons: [
    {
      title: 'Logout',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/logout',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
    }
  ]
}

export function VocabularyExam({ settings = default_settings }) {
  // Language selection for static texts
  const [activeLanguage, setActiveLanguage] = useState('en');
  const currentTexts = idioms[activeLanguage];
  // Static texts aliases
  const instructions = currentTexts.language_instructions.vocabulary_exam;

  // Read clipboard
  const { clipboard } = useTopBarCLipboardContext();
  const userClassroom = clipboard.userClassroom.value;
  const unitId = clipboard.unitId.value;

  // Read backend
  const [backendData, setBackendData] = useState([]);
  useEffect(() => {
    const requestData = {
      task: 'vocabExam',
      range: userClassroom + '~' + unitId + '~',
      sentencesPerBlock: 3,
    };
    const fetchData = async () => {
      const data = await fetchBackendData(requestData);
      if (data) { setBackendData(data) }
    };
    fetchData();
  }, [userClassroom, unitId]);

  // Set-up top bar
  useNavigationConfig(null, null, settings.leftButtons, settings.rightButtons);

  // Use the useLayoutEffect hook to scroll to the top after the component is rendered
  useScrollToTop();
  // useDebugClipboard(clipboard);

  // Responsiveness
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= globalSettings.smallScreenWidth;

  // Control the hovering effect
  // const [isHovered, setIsHovered] = useState(false);

  // Styles
  const styles = componentStyles(isSmallScreen);

  // Handle settings
  const [userInput, setUserInput] = useState('');

  // Navigation settings
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentState, setCurrentState] = useState('QUIZ');
  const [answerList, setAnswerList] = useState([]);
  const [score, setScore] = useState(0);

  return (

    <div className="app">

      <PageTitleActivity
        title={default_settings.activityTitle + " | " + globalSettings.courseNameEN}
        subtitle={globalSettings.clientName + " | " + userClassroom.split('-')[0]} />

      <main>
        <div style={virtualFullWidth}>
          <div style={availableWidth}>

            <SectionHeadingToolbar
              title={settings.activityTitle}
              setUserInput={setUserInput}
              infoText={instructions}
              activeLanguage={activeLanguage}
              setActiveLanguage={setActiveLanguage}
            />

            {currentState === 'QUIZ' &&
              <StateQuiz currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} backendData={backendData} setCurrentState={setCurrentState} setAnswerList={setAnswerList} score={score} setScore={setScore} />
            }

            {currentState === 'REVIEW' && (
              <StateReview answerList={answerList} setAnswerList={setAnswerList} backendData={backendData} setBackendData={setBackendData} setCurrentState={setCurrentState} score={score} setScore={setScore} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
            )}

          </div>
        </div>
      </main>

      <DashboardFooter />

    </div >
  );
}