import { Storage } from 'aws-amplify'
// import { StorageManager } from '@aws-amplify/ui-react-storage';

export const fetchStorage = async (key) => {

    try {
        const url = await Storage.get(key);
        return url;
    } catch (error) {
        console.error('Error fetching S3:', error);
        throw error;
    }

};

// export const fetchStorage = async (key) => {
//     try {
//         const url = await StorageManager.get(key, { level: 'public' }); // Specify access level
//         return url;
//     } catch (error) {
//         console.error('Error fetching S3:', error);
//         throw error;
//     }
// };