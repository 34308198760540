// Colors, styles etc.
import { colors } from 'components/styles/colors';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useNavigationConfig } from 'components/pages/navigation/ModifyTopBarDefaultValues';
import { useTopBarCLipboardContext } from 'components/pages/navigation/TopBarCLipboardContext';
import { fetchBackendData } from 'components/_common/amplify/fetchBackendData';
import { virtualFullWidth, availableWidth } from 'components/styles/specific/page_width.js'; import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import { globalSettings } from 'GlobalSettings';
import { useScrollToTop, useDebugClipboard } from 'components/_common/responsiveness/navigationTools';

// Components and blocks
import SectionHeadingToolbar from 'components/_common/SectionHeadingToolbar';
import PageTitle from 'components/pages/navigation/PageTitle';
import DashboardFooter from 'components/pages/navigation/DashboardFooter';
import NavigationBar from 'components/legacy/exercises/_exercises/PageNavigationBar';

const default_settings = {
  activityTitle: 'Math',
  leftButtons: [
    {
      title: 'Back',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: `/dashboard?pk=ACTIVITY&sk=CERMAT~MAT~`,
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_chevron_S_double_left.png',
    },
  ],
  rightButtons: [
    {
      title: 'Logout',
      textColor: 'light',
      backgroundColor: 'gray1',
      to: '/logout',
      visibleIfAuthenticated: true,
      visibleIfNotAuthenticated: false,
      isImage: true,
      imagePath: 'img/icons/symbols/white/icon_w_box-arrow-in-right.png',
    }
  ]
}

export function ExercisesMathHejny2zColouredTriples({ settings = default_settings }) {

  // Read clipboard
  const { clipboard } = useTopBarCLipboardContext();
  const userClassroom = clipboard.userClassroom.value;
  const unitId = clipboard.unitId.value;

  // Read backend
  const [backendData, setBackendData] = useState([]);
  useEffect(() => {
    const requestData = {
      task: 'math_heine_2z_generate_array_coloured_triples',
      range: userClassroom + '~' + unitId + '~',
    };
    const fetchData = async () => {
      const data = await fetchBackendData(requestData);
      if (data) { setBackendData(data) }
    };
    fetchData();
  }, [userClassroom, unitId]);

  // Set-up top bar
  useNavigationConfig(null, null, settings.leftButtons, settings.rightButtons);

  // Use the useLayoutEffect hook to scroll to the top after the component is rendered
  useScrollToTop();
  // useDebugClipboard(clipboard);

  // Responsiveness
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width <= 768;

  // Handle settings
  const [userInput, setUserInput] = useState('');

  // Determine data dimensions
  const [exercisesCount, setExercisesCount] = useState(0);
  const [equationsCount, setEquationsCount] = useState(0);
  const [variablesCount, setVariablesCount] = useState(0);
  const [randomizedArray, setRandomizedArray] = useState([]);
  const [bufferData, setBufferData] = useState([]);
  useEffect(() => {
    if (backendData.length > 0) {
      setExercisesCount(backendData.length);
      setEquationsCount(backendData[0].length);
      setVariablesCount(backendData[0][0].length);
      // console.log(`exercisesCount ${exercisesCount}, equationsCount ${equationsCount}, variablesCount ${variablesCount}`);
      const newArray = [];
      for (let k = 0; k < backendData.length; k++) {
        const innerArray = [];
        for (let j = 0; j < variablesCount - 1; j++) {
          const randomIndices = Array.from({ length: equationsCount }, (_, index) => index);
          for (let i = randomIndices.length - 1; i > 0; i--) {
            const m = Math.floor(Math.random() * (i + 1));
            [randomIndices[i], randomIndices[m]] = [randomIndices[m], randomIndices[i]];
          }
          innerArray.push(randomIndices);
        }
        newArray.push(innerArray);
      }
      setRandomizedArray(newArray);
      // console.log('randomizedArray:', newArray);
      const bufferData = backendData.slice().map((exercise) => {
        return exercise.slice().map((variable) => {
          return variable.slice();
        });
      });
      bufferData.forEach((exercise) => {
        exercise.forEach((variable) => {
          for (let i = 0; i < variable.length - 1; i++) {
            variable[i] = null;
          }
        });
      });
      setBufferData(bufferData);
    }
  }, [backendData, exercisesCount, equationsCount, variablesCount]);

  // Handle Navigation Bar (bottom)
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToPrevious = () => {
    if (currentIndex > 0) { setCurrentIndex(currentIndex - 1); }
    checkSums();
  };
  const goToNext = () => {
    if (currentIndex < exercisesCount - 1) { setCurrentIndex(currentIndex + 1); }
    checkSums();
  };

  // Handle clickable boxes
  const boxColors = ['coral', 'lightgreen', 'lightblue', 'lightgray'];
  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '5%',
    marginRight: '5%',
    justifyContent: 'center',
  };
  const boxStyle = {
    border: '1px solid #000',
    padding: '10px',
    margin: '10px',
    cursor: 'pointer',
    width: 50,
  };
  const signBoxStyle = {
    ...boxStyle,
    border: '0',
    margin: '0px',
    width: 10,
    padding: 0,
  }
  const [resultStatusString, setResultStatusString] = useState('Incorrect');
  const [activeBox_i, setActiveBox_i] = useState(0);
  const [activeBox_j, setActiveBox_j] = useState(0);
  const [activeChoice_i, setActiveChoice_i] = useState(null);
  const [activeChoice_j, setActiveChoice_j] = useState(null);
  const handleBoxClick = (i, j, id) => {
    if (id === 0 && j !== variablesCount - 1) {
      setActiveBox_i(i);
      setActiveBox_j(j);
    }
    if (id === 1) {
      setActiveChoice_i(i);
      setActiveChoice_j(j);
    }
  };
  useEffect(() => {
    // console.log('changed activeBox_i:', activeBox_i, activeBox_j)
  }, [activeBox_i, activeBox_j]);
  const checkSums = () => {
    if (!bufferData[currentIndex] || bufferData[currentIndex].length === 0) {
      return false;
    }
    // Iterate over all rows
    for (let j = 0; j < bufferData[currentIndex].length; j++) {
      let row = bufferData[currentIndex][j];
      let sum = 0;
      // Iterate over each element (column) in the row except the last one
      for (let i = 0; i < row.length - 1; i++) {
        sum += Number(row[i]) || 0;
      }
      // Get the value to compare from the last column of the row
      const sumToCompare = Number(row[row.length - 1]) || 0;
      // console.log('Row:', row, 'Sum of columns:', sum, 'Sum to compare:', sumToCompare);
      // Check if the sum does not match the value in the last column
      if (sum !== sumToCompare) {
        setResultStatusString('Incorrect')
        return false; // Return false immediately if any row does not match
      }
    }
    setResultStatusString('Correct')
    return true; // Return true if all rows match
  };
  useEffect(() => {
    if (randomizedArray[currentIndex] && randomizedArray[currentIndex].length > 0 && backendData[currentIndex] && backendData[currentIndex].length) {
      const valueTaken = backendData[currentIndex][randomizedArray[currentIndex][activeChoice_j][activeChoice_i]][activeChoice_j]
      // console.log('changed activeChoice_i:', activeChoice_i, activeChoice_j, 'value:', valueTaken, '-->', activeBox_i, activeBox_j)
      if (activeBox_j == activeChoice_j) {
        let updatedBufferData = [...bufferData];
        updatedBufferData[currentIndex][activeBox_i][activeBox_j] = valueTaken;
        setBufferData(updatedBufferData);
        checkSums();
      }
    }
  }, [activeChoice_i, activeChoice_j]);

  // Handle rendering boxes: top exercise
  const renderBoxes = (currentIndexVar) => {
    if (!randomizedArray[currentIndexVar] || randomizedArray[currentIndexVar].length == 0) {
      return null
    }
    const boxes = [];
    for (let i = 0; i < equationsCount; i++) {
      const equationBoxes = [];
      for (let j = 0; j < variablesCount; j++) {
        equationBoxes.push(
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <div
              key={`equation${i}_variable${j}`}
              onClick={() => handleBoxClick(i, j, 0)}
              style={{
                ...boxStyle,
                backgroundColor: boxColors[j],
                border: activeBox_i === i && activeBox_j === j ? '1px solid #000' : (j === variablesCount - 1 ? '1px solid #000' : '2px dotted #000'),
              }}
            >
              {bufferData[currentIndexVar][i][j] !== null ? bufferData[currentIndexVar][i][j] : '\u00A0'}
            </div>
            <div
              key={`signs-equation${i}_variable${j}`}
              style={signBoxStyle} >
              <span>{j < variablesCount - 1 && (j < variablesCount - 2 ? '+' : '=')}</span>
            </div>
          </div >
        );
      }
      boxes.push(
        <div key={`equation${i}`} style={containerStyle}>
          {equationBoxes}
        </div>
      );
    }
    return boxes;
  };
  // Only re-render renderBoxes when currentIndex or randomizedArray is ready
  const [renderedBoxes, setRenderedBoxes] = useState([]);
  useEffect(() => {
    setRenderedBoxes(renderBoxes(currentIndex));
    checkSums();
  }, [currentIndex, randomizedArray, activeBox_i, activeBox_j, bufferData]);

  // Handle rendering boxes: bottom choices
  const renderChoices = (currentIndexVar) => {
    if (!randomizedArray[currentIndexVar] || randomizedArray[currentIndexVar].length == 0) {
      return null
    }
    const choices = [];
    for (let j = 0; j < variablesCount - 1; j++) {
      const equationBoxes = [];
      for (let i = 0; i < equationsCount; i++) {
        equationBoxes.push(
          <div
            key={`equation${i}_variable0_color${j}_index${i}`}
            onClick={() => handleBoxClick(i, j, 1)}
            style={{ ...boxStyle, backgroundColor: boxColors[j] }}
          >
            {backendData[currentIndexVar][randomizedArray[currentIndexVar][j][i]][j]}
          </div>
        );
      }
      choices.push(
        <div key={`color${j}`} style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'center'
        }}>
          {equationBoxes}
        </div>
      );
    }
    return choices;
  };
  // Only re-render renderChoices when currentIndex or randomizedArray is ready
  const [renderedChoices, setRenderedChoices] = useState([]);
  useEffect(() => {
    setRenderedChoices(renderChoices(currentIndex));
  }, [currentIndex, randomizedArray]);

  return (

    <div className="app">

      <PageTitle
        title={default_settings.activityTitle}
        subtitle={globalSettings.clientName + " | " + userClassroom.split('-')[0]} />

      <main>
        <div style={virtualFullWidth}>
          <div style={availableWidth}>

            <SectionHeadingToolbar
              title='Sets of sums'
              setUserInput={setUserInput}
              infoText='Description of the card' />

            <div key={currentIndex} style={{ marginBottom: 10 }}>

              <div key={currentIndex}>
                {resultStatusString}
                {renderedBoxes}
                <hr></hr>
                {renderedChoices}
              </div>

            </div>

            <NavigationBar goToPrevious={goToPrevious} goToNext={goToNext} currentIndex={currentIndex} keysLength={exercisesCount} />

          </div>
        </div>
      </main>

      <DashboardFooter />

    </div >
  );
}