// Colors, styles etc.
import { colors } from 'components/styles/colors';
import { componentStyles } from 'components/styles/component_styles';

// Basic core functions
import React, { useState, useEffect } from 'react';

// Support functions
import { useWindowSize } from 'components/_common/responsiveness/useWindowSize';
import useAudioPlayer from 'components/_common/AudioPlayer';

// Components and blocks
import ImageComponent from 'components/_common/ImageComponent'

const InformationDisplay = ({ keys, currentIndex, backendData }) => {

    // Audio
    const { playAudio } = useAudioPlayer();

    // Styles
    const windowSize = useWindowSize();
    const isSmallScreen = windowSize.width <= 768;

    //const shadowColor = colors.gray6;

    const cardStyleTop = {
        flex: windowSize.width <= 768 ? '1 0 100%' : '1 0 40%', // slightly less than 50 and more than 33
        margin: 0,
        padding: isSmallScreen ? 6 : 9,
        boxSizing: 'border-box',
        width: '300px',
        position: 'relative',
        backgroundColor: colors.gray6,
        borderRadius: isSmallScreen ? 6 : 9,
    };

    const cardStyleBottom = {
        margin: 0,
        padding: isSmallScreen ? 6 : 9,
        boxSizing: 'border-box',
        width: '100%',
        backgroundColor: colors.gray6,
        fontSize: isSmallScreen ? 14 : 18,
        textAlign: 'center', // Align text to the left
        borderRadius: isSmallScreen ? 6 : 9,
    };

    // Shuffle sentences only once when component mounts or backendData changes
    const [shuffledSentences, setShuffledSentences] = useState([]);
    useEffect(() => {
        if (backendData[keys[currentIndex]] && backendData[keys[currentIndex]].sentences) {
            const sentences = Array.from(backendData[keys[currentIndex]].sentences.values());
            const shuffled = sentences.sort(() => Math.random() - 0.5);
            setShuffledSentences(shuffled);
        }
    }, [backendData, keys, currentIndex]);

    // Curate data
    const renderSentences = () => {
        // Limit to 3 or 5 sentences
        const limitedSentences = shuffledSentences.slice(0, isSmallScreen ? 3 : 5);
        // Render the limited sentences
        return limitedSentences.map((sentence, index) => (
            <div key={index} style={{ marginBottom: isSmallScreen ? 6 : 9 }}>
                {sentence}
            </div>
        ));
    };

    // Process image data
    const [loadedImages, setLoadedImages] = useState({});
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
        setImageLoaded(false);
    }, [currentIndex]);
    const handleCacheImages = (wordImage, imageUrl) => {
        setLoadedImages(prevState => ({
            ...prevState,
            [wordImage]: imageUrl,
        }));
    };
    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        keys.length > 0 && (
            <div>

                {/* Top Cards */}
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    //height: isSmallScreen ? '100px' : '300px',
                    margin: isSmallScreen ? "12px" : "18px",
                    padding: 0,
                    gap: isSmallScreen ? "12px" : "18px",
                }}>

                    <div style={{
                        ...cardStyleTop,
                        // display: 'flex',
                        // flexDirection: 'column',
                        // alignItems: 'center',
                        // justifyContent: 'center',
                    }}>
                        <div style={{
                            cursor: 'pointer',
                            margin: 0,
                            fontSize: isSmallScreen ? 18 : 24,
                            fontWeight: 700,
                            textAlign: 'center',
                            marginBottom: isSmallScreen ? 9 : 12,
                        }}
                            onClick={() => { playAudio(keys[currentIndex]) }}>
                            {keys[currentIndex]}
                        </div>
                        <div style={{
                            fontSize: isSmallScreen ? 14 : 18,
                            fontWeight: 400,
                            textAlign: 'center',
                            marginBottom: isSmallScreen ? 4 : 6,
                        }}>
                            Definition:
                        </div>
                        <div style={{
                            fontSize: isSmallScreen ? 14 : 18,
                            fontWeight: 400,
                            textAlign: 'center',
                            marginBottom: isSmallScreen ? 9 : 12,
                        }}>
                            {backendData[keys[currentIndex]].definition}
                        </div>
                        <div style={{
                            fontSize: isSmallScreen ? 14 : 18,
                            fontWeight: 400,
                            textAlign: 'center',
                            fontStyle: 'italic',
                            marginBottom: isSmallScreen ? 4 : 6,
                        }}>
                            Synonyms:
                        </div>
                        <div style={{
                            fontSize: isSmallScreen ? 14 : 18,
                            fontWeight: 400,
                            textAlign: 'center',
                            fontStyle: 'italic',
                            marginBottom: isSmallScreen ? 9 : 12,
                        }}>
                            {backendData[keys[currentIndex]].synonyms}
                        </div>

                    </div>

                    <div style={{
                        ...cardStyleTop,
                    }}
                    >
                        <div
                            onClick={() => { playAudio(keys[currentIndex]) }}>
                            {/* Gray box - shown initially and hidden after image loads */}
                            <div style={{
                                display: imageLoaded ? 'none' : 'block',
                                width: isSmallScreen ? 240 : 280,
                                height: isSmallScreen ? 240 : 280,
                                backgroundColor: colors.gray6,
                                borderRadius: isSmallScreen ? 6 : 9,
                            }} />

                            {/* ImageComponent - hidden initially and shown after image loads */}
                            <div style={{ display: imageLoaded ? 'block' : 'none' }}>
                                <ImageComponent
                                    wordImage={keys[currentIndex]}
                                    cacheImages={handleCacheImages}
                                    loadedImages={loadedImages}
                                    extension='png.jpg'
                                    imageStyles={{
                                        cursor: 'pointer',
                                        width: isSmallScreen ? 240 : 280,
                                        height: isSmallScreen ? 240 : 280,
                                        borderRadius: isSmallScreen ? 6 : 9,
                                    }}
                                    onImageLoad={handleImageLoad}
                                />
                            </div>
                        </div>

                        <div style={{
                            cursor: 'pointer',
                            fontSize: isSmallScreen ? 14 : 18,
                            fontWeight: 400,
                            textAlign: 'center',
                            fontStyle: 'italic',
                            marginBottom: isSmallScreen ? 4 : 6,
                        }}
                            onClick={() => { playAudio(keys[currentIndex] + '-sentence') }}>
                            {backendData[keys[currentIndex]].illustratedSentence}
                        </div>
                    </div>

                </div>

                {/* Bottom Card */}
                <div style={{
                    margin: isSmallScreen ? "12px" : "18px",
                    padding: 0,
                }}>
                    <div style={cardStyleBottom}>
                        {renderSentences(backendData[keys[currentIndex]].sentences)}
                    </div>
                </div>

            </div >
        )
    );
};

export default InformationDisplay;
