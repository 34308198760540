export const drawOnCanvas = (canvas, shapes) => {

    if (!canvas) return;

    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    const cellSize = 30;

    shapes.forEach(shape => {

        context.lineWidth = 1
        context.globalAlpha = 1

        switch (shape.type) {
            case 'grid':
                drawGrid(context, shape, cellSize, canvas.width, canvas.height);
                break;
            case 'circle':
                drawCircle(context, shape, cellSize);
                break;
            case 'polygon':
                drawPolygon(context, shape, cellSize);
                break;
            case 'label':
                drawLabel(context, shape, cellSize);
                break;
            case 'line':
            case 'arrow':
            case 'bidirectionalArrow':
                drawLine(context, shape, cellSize, shape.type);
                break;
            default:
                console.log("Unknown shape type:", shape.type);
        }
    });
};

// Grid drawing function
const drawGrid = (context, shape, cellSize, width, height) => {
    for (let i = 0; i <= shape.gridSize; i++) {
        context.beginPath();
        context.moveTo(i * cellSize, 0);
        context.lineTo(i * cellSize, height);
        context.moveTo(0, i * cellSize);
        context.lineTo(width, i * cellSize);
        context.strokeStyle = '#ddd';
        context.globalAlpha = 0.5;
        context.stroke();
    }
};

// Circle drawing function
const drawCircle = (context, shape, cellSize) => {
    const centerX = shape.position[0] * cellSize;
    const centerY = shape.position[1] * cellSize;
    const radius = shape.radius * cellSize;
    context.beginPath();
    context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    context.globalAlpha = shape.alpha; // Set transparency
    context.strokeStyle = shape.color; // Outline color
    if (shape.isFilled) {
        context.fillStyle = shape.color; // Fill color
        context.fill();
    }
    context.stroke();
};

// Polygon drawing function
const drawPolygon = (context, shape, cellSize) => {
    context.beginPath();
    context.moveTo(shape.vertices[0][0] * cellSize, shape.vertices[0][1] * cellSize);
    shape.vertices.slice(1).forEach(vertex => {
        context.lineTo(vertex[0] * cellSize, vertex[1] * cellSize);
    });
    context.closePath();
    context.globalAlpha = shape.alpha;
    context.fillStyle = shape.color;
    context.fill();
    context.stroke();
};

// Label drawing function
const drawLabel = (context, shape, cellSize) => {
    context.font = `${shape.fontSize || 16}px Arial`;
    context.textAlign = 'center';
    context.fillStyle = shape.color || '#000';
    const posX = shape.position[0] * cellSize;
    const posY = shape.position[1] * cellSize;
    context.fillText(shape.text, posX, posY);
};

// Line drawing function including arrow and bidirectional arrow
const drawLine = (context, shape, cellSize, type) => {
    const fromx = shape.start[0] * cellSize;
    const fromy = shape.start[1] * cellSize;
    const tox = shape.end[0] * cellSize;
    const toy = shape.end[1] * cellSize;
    const lineWidth = shape.lineWidth * cellSize;
    const color = shape.color;

    const angle = Math.atan2(toy - fromy, tox - fromx);

    context.beginPath();
    context.moveTo(fromx, fromy);
    context.lineTo(tox, toy);
    context.strokeStyle = color;
    context.lineWidth = lineWidth;
    context.stroke();

    const headLength = 10; // Length of the arrow head in pixels

    if (type === 'arrow' || type === 'bidirectionalArrow') {
        drawArrowhead(context, tox, toy, angle, color, lineWidth, headLength);
    }
    if (type === 'bidirectionalArrow') {
        drawArrowhead(context, fromx, fromy, angle + Math.PI, color, lineWidth, headLength);
    }
};

// Helper function to draw arrowheads
const drawArrowhead = (context, x, y, angle, color, lineWidth, headLength) => {
    context.beginPath();
    context.moveTo(x, y);
    context.lineTo(x - headLength * Math.cos(angle - Math.PI / 6), y - headLength * Math.sin(angle - Math.PI / 6));
    context.lineTo(x - headLength * Math.cos(angle + Math.PI / 6), y - headLength * Math.sin(angle + Math.PI / 6));
    context.lineTo(x, y);
    context.lineTo(x - headLength * Math.cos(angle - Math.PI / 6), y - headLength * Math.sin(angle - Math.PI / 6));
    context.strokeStyle = color;
    context.fillStyle = color;
    context.lineWidth = lineWidth;
    context.stroke();
    context.fill();
};

export default drawOnCanvas;
